// Customizable Area Start
import { createRef } from 'react';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { business } from '../assets';
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import { ApiCallInterface } from './DiscoverController';
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleCommonPath: (path: string) => void;
    handlePodcastId: (id:string) => void;
    // Customizable Area End
};

// Customizable Area Start
export interface TrendingPodcastDetail {
    id: number;
    src: any;
    artist: string;
    name: string;
    is_follow: boolean;
};

interface PodcastState {
    id: string;
    following: boolean;
    follow_id: null | string;
    cover_image: string;
    name: string;
    first_name: string;
    last_name: string;
}
  

interface S {
    trendingPodcast: PodcastState[],
};
// Customizable Area End

interface SS {
    id: any;
};

export default class TrendingPodcastController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTrendingPodcastApiCallId: any;
    followApiCallId: string = ""
    unFollowApiCallId: string = ""
    scrollReff: any = createRef();
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            trendingPodcast: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.getTrendingPodcastApiCallId) {
        this.handleResTrendingPodcast(from, message)
    }

    if (apiRequestCallId == this.followApiCallId) {
        this.handleFollowResponse(from, message)
    }

    if (apiRequestCallId == this.unFollowApiCallId) {
        this.handleUnFollowResponse(from, message)
    }
  }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.fetchTrendingPodcast();
        setTimeout(() => {
            if (this.scrollReff.current) {
              this.scrollReff.current.scrollTo({ top: 0, behavior: "smooth" });
            }
        }, 0);
    }

    fetchTrendingPodcast = async() => {
        let token = await getStorageData("token");
        let subCatId = await getStorageData("subCatId");
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        )  

        this.getTrendingPodcastApiCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getTrendingPodcastApiEndPoint}${subCatId}&per_page=20&page=1`
        )

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            token: JSON.parse(token),
          })
        )  

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleResTrendingPodcast = async (from: string, message: Message) => {
        let apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
           
        if (apiResponse.data.length > 0) {
            const filteredData: PodcastState[] = apiResponse.data.map((podcast: any) => ({
                id: podcast.id,
                following: podcast.attributes.follower.following, 
                follow_id: podcast.attributes.follower.follow_id,
                cover_image: podcast.attributes.cover_image,
                name: podcast.attributes.name,
                first_name: podcast.attributes.hosts?.[0]?.first_name,
                last_name: podcast.attributes.hosts?.[0]?.last_name
              }));
            this.setState({trendingPodcast: filteredData})
        } else {
            this.setState({trendingPodcast: []})
        }
      };

      apiCall = async (data: ApiCallInterface) => {
        let token = await getStorageData("token");

        const { method, endPoint, body, contentType } = data;
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            'Content-Type': contentType,
            token: JSON.parse(token),
          })
        );

        body && requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      }


      handleFollow = async(podcastId: string, isFollow: boolean, follow_id: string | null) => {

        const body = {
          data: {
            followable_type: "BxBlockLogin::Podcast",
            followable_id: podcastId
          }
        };

        if(isFollow) {
            this.unFollowApiCallId = await this.apiCall({
                contentType: configJSON.categoryApiContentType,
                method: configJSON.httpDeleteType,
                endPoint: `${configJSON.followPodcastEndPoint}/${follow_id}`
            });
        }
        else {
            this.followApiCallId = await this.apiCall({
                contentType: configJSON.categoryApiContentType,
                endPoint: configJSON.followPodcastEndPoint,
                method: configJSON.httpPostType,
                body: body
              });
        }
      }

      handleFollowResponse = async (from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(apiResponse.meta.message === "Successfully Followed") {
            this.fetchTrendingPodcast();
        }
      }

      handleUnFollowResponse = async (from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(apiResponse.message === "Successfully Unfollowed") {
            this.fetchTrendingPodcast();
        }
      }
    
    // Customizable Area End
}
