import React, { Component } from "react";
// Customizable Area Start
import { logo, podcastImage, ad1, ad2, ad3 } from '../assets'
import AudioPlayerComponent from "../AudioPlayerComponent.web";

const WIDE_SCREENS = window.innerWidth > 1500
// Customizable Area End

interface AudioPlayerFullScreenProps {
    audioTracks: {
        podcast_id: string,
        title: string,
        artist: string,
        src: string,
        episode_number: string,
        liked_count: string,
        liked: boolean,
        cover: string,
        liked_id: string,
        episode_id: string
        bio: string;
    };
}


export default class AudioPlayerFullScreen extends Component<AudioPlayerFullScreenProps> {
    constructor(props: AudioPlayerFullScreenProps) {
        super(props);
    }

    render() {
        const { cover, artist, title, bio } = this.props.audioTracks;
    return (
      // Customizable Area Start
        <div style={webStyle.wrapper}>
            <div style={webStyle.logo}>
                <img src={logo} alt="Logo" />
            </div>
            <div style={webStyle.content}>
                <div style={webStyle.details}>
                    <div>
                        <img src={cover} width="500px" height="auto" alt="Podcast" style={{borderRadius: "30px"}} />
                    </div>
                    <div style={{width: WIDE_SCREENS ? "620px" : "430px", marginTop:"24px"}}>
                        <div 
                            style={{ 
                                fontFamily:"Nexa-Heavy", 
                                fontWeight: 900, 
                                fontSize: WIDE_SCREENS ? "50px" : "45px", 
                                color: "#f8fafc", 
                                lineHeight: WIDE_SCREENS ? "22px" : "40px"
                            }}>
                            {title}
                        </div>
                        <br />
                        <div style={{ fontFamily:"Nexa-Regular", fontWeight: 100, fontSize: "30px", color: "#f8fafc", lineHeight: "22px" }}>
                            {artist}
                        </div>
                        <br />
                        {WIDE_SCREENS && 
                            <>
                                <div data-test-id="about" style={{ fontFamily:"Nexa-Heavy", fontWeight: 900, fontSize: "18px", color: "#f5f5f5" }}>
                                About
                                </div>
                                <div style={{ fontFamily:"Nexa-Regular", fontWeight: 100, fontSize: "14px", color: "#fff"}}>
                                    {bio}
                                </div>
                                <br />
                                {/* <div style={{ fontFamily:"Nexa-Heavy", fontWeight: 900, fontSize: "14px", color: "#fff" }}>
                                    ... Show more
                                </div> */}
                            </>
                        }
                        
                    </div>
                </div>
                <div style={webStyle.ads}>
                    <img src={ad1} />
                    <img src={ad2} />
                    <img src={ad3} />
                </div>
            </div>
            {/* <div style={{ paddingLeft: "calc(6% + 135px)"}}>
          <AudioPlayerComponent navigation={this.props.navigation} id="" />
        </div>      */}
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    wrapper: {
        padding:"20px 50px",
        overflowX: "clip",
        overflowY: "auto",
        maxWidth: "100%",
    } as const,
    logo: {
        paddingTop: "2%",
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: "10rem",
    },
    content: {
        display: "flex",
        justifyContent: "center",
        // flex: 1,
        alignItems: "center",
        gap:20,
    },
    details: {
        display: "flex",
        gap: 20,
        // alignItems: "center",
    },
    ads: {
        display: "flex",
        flexDirection: "column" as const,
        gap: 20
    },
    audioPlayer: {
        height: "auto",
    
    },
  };
  // Customizable Area End