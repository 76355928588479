// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Button,
    TextField,
    Select,
    FormControl,
    Typography, Dialog, DialogContent
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HostPaymentDetailsController, {
    Props,
} from "./HostPaymentDetailsController";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { cart, goBack, group_applepay, group_googlepay, group_klarna, group_maestro, group_paypal, group_visa, star } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";

export default class HostPaymentDetails extends HostPaymentDetailsController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object({
        cardNumber: Yup.string().required("Card Number is required"),
        cardName: Yup.string().required("Name on card is required"),
        expireDate: Yup.string().required("Expire Date is required"),
        secureCode: Yup.string().required("Secure Code is required"),
        streetAddress: Yup.string().required("Street Address is required"),
        suite: Yup.string().notRequired(),
        country: Yup.string().notRequired(),
        state: Yup.string().notRequired(),
        city: Yup.string().required("City is required"),
        zipCode: Yup.string().required("Zip Code is required"),
        promoCode: Yup.string().notRequired()
    });

    render() {
        const partner = this.props.loginType === "partner";
        return (
            <>
                <Box ref={this.scrollRef}>
                    <StyledWrapperHostPaymentDetails>
                        <Box className="mainTitle"><img src={goBack} alt="goBack" style={{ marginRight: "20px" }} data-test-id="navigate" onClick={() => { this.props.handleCommonPath("HostPaymentPlan") }} />Back</Box>
                        <Box id="paymentDetails">
                            <Box className="container">
                                <Grid className="upper">
                                    <Box className="title">Payment Details</Box>
                                    <Box className="desc" data-test-id="subHeading">Please input your payment details below to get access to the {partner ?"Partner":"Hosting"} features within PodcastTownSquare</Box>
                                </Grid>
                                <Formik
                                    initialValues={{ cardNumber: '', cardName: '', expireDate: '', secureCode: '', streetAddress: '', suite: '', country: '', state: '', city: '', zipCode: '', }}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => {
                                        console.log("values", values)
                                        this.handleOpenModal()
                                    }}
                                >
                                    {({ values, handleChange, handleBlur, handleSubmit,isValid,dirty }) => (
                                       <Form onSubmit={handleSubmit}>
                                            <Grid>
                                                <Box className="lowerContainer">
                                                    <Box className="boxS">
                                                        <Box className="boxTitle">Card Details</Box>
                                                        <Box className="fieldWrapper">
                                                            <Box className="formLabel" style={{ marginLeft: "3%" }}>Card Number</Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Field
                                                                    as={TextField}
                                                                    id="cardNumber"
                                                                    name="cardNumber"
                                                                    data-test-id="txtEmail"
                                                                    sx={{
                                                                        width: "95%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            color: "#CBD5E1",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                              backgroundColor: "black !important", 
                                                                              WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                              WebkitTextFillColor: "#CBD5E1 !important" 
                                                                            }
                                                                          }
                                                                    }}

                                                                    value={values.cardNumber}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Box>

                                                        </Box>

                                                        <Box className="fieldWrapper">
                                                            <Box className="formLabel" style={{ marginLeft: "3%" }}>Name on card</Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Field
                                                                    as={TextField}
                                                                    id="cardName"
                                                                    name="cardName"
                                                                    sx={{
                                                                        width: "95%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderRadius: "8px",
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderRadius: "8px",
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderRadius: "8px",
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            color: "#CBD5E1",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                              backgroundColor: "black !important", 
                                                                              WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                              WebkitTextFillColor: "#CBD5E1 !important" 
                                                                            }
                                                                          }
                                                                    }}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Box>

                                                        </Box>

                                                        <Box className="fieldWrapper" style={{ marginLeft: "5%" }}>
                                                            <Box className="formLabel">Expire Date</Box>
                                                            <Box
                                                            >
                                                                <Field
                                                                    as={TextField}
                                                                    id="expireDate"
                                                                    name="expireDate"
                                                                    sx={{
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                        },
                                                                        width: "70%",
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            color: "#CBD5E1",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                backgroundColor: "black !important", 
                                                                              WebkitTextFillColor: "#CBD5E1 !important" 
                                                                            }
                                                                          }
                                                                    }}

                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Box>
                                                        </Box>

                                                        <Box className="fieldWrapper" style={{ marginLeft: "5%" }}>
                                                            <Box className="formLabel">Secure Code</Box>
                                                            <Box
                                                            >
                                                                <Field
                                                                    as={TextField}
                                                                    id="secureCode"
                                                                    name="secureCode"
                                                                    sx={{
                                                                        width: "70%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            color: "#CBD5E1",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                                WebkitTextFillColor: "#CBD5E1 !important" ,
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                backgroundColor: "black !important", 
                                                                            }
                                                                          }
                                                                    }}

                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Box>


                                                        </Box>

                                                        <Box className="billingDetails">&nbsp;Edit <span style={{ color: "blue" }}>&nbsp;Billing Details</span> </Box>
                                                    </Box>

                                                    <Box className="boxS">
                                                        <Box className="boxTitle">Billing Address Information</Box>
                                                        <Box className="fieldWrapper">
                                                            <Box className="formLabel" style={{ marginLeft: "3%" }}>Street Address</Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Field
                                                                    as={TextField}
                                                                    id="streetAddress"
                                                                    name="streetAddress"
                                                                    sx={{
                                                                        width: "95%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            "&:-webkit-autofill": {
                                                                                backgroundColor: "black !important", 
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                WebkitTextFillColor: "#CBD5E1 !important" 
                                                                            },
                                                                            backgroundColor: "black",
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                    }}

                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Box>

                                                        </Box>

                                                        <Grid container spacing={2} sx={{ marginTop: "2%" }}>
                                                            <Box style={{ display: "flex", flexDirection: "row", width: "89%", margin: "auto", marginLeft: "36px", justifyContent: "space-between" }}>
                                                                <Grid item xs={6}>
                                                                    <Box sx={{ marginBottom: "8px" }}>
                                                                        <Typography variant="body2" className="formLabel" style={{ fontSize: "70%" }}>Apt #, Suite, Etc (Optional)</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            width: "100%"
                                                                        }}
                                                                    >
                                                                        <Field
                                                                            as={TextField}
                                                                            id="suite"
                                                                            name="suite"
                                                                            sx={{
                                                                                width: "95%",
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    "& fieldset": {
                                                                                        borderRadius: "8px",
                                                                                        borderColor: "#CBD5E1",
                                                                                    },
                                                                                    "&:hover fieldset": {
                                                                                        borderRadius: "8px",
                                                                                        borderColor: "#CBD5E1",
                                                                                    },
                                                                                    "&.Mui-focused fieldset": {
                                                                                        borderColor: "#CBD5E1",
                                                                                        borderRadius: "8px"
                                                                                    },
                                                                                },
                                                                                "& .MuiInputLabel-root": {
                                                                                    color: "#CBD5E1",
                                                                                },
                                                                                "& .MuiInputBase-input": {
                                                                                    "&:-webkit-autofill": {
                                                                                        WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                        backgroundColor: "black !important", 
                                                                                      WebkitTextFillColor: "#CBD5E1 !important" 
                                                                                    },
                                                                                    color: "#CBD5E1",
                                                                                    backgroundColor: "black",
                                                                                  }
                                                                            }}

                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </Box>
                                                                </Grid>

                                                                <Grid item xs={6} >
                                                                    <Box sx={{ marginBottom: "8px" }}>
                                                                        <Typography variant="body2" className="formLabel" style={{ fontSize: "70%", marginLeft: "5%" }}>Country</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            justifyContent: "center",
                                                                            display: "flex",
                                                                            width: "100%"
                                                                        }}
                                                                    >
                                                                        <FormControl 
                                                                        fullWidth 
                                                                        variant="outlined" 
                                                                        id="countryName"
                                                                        sx={{
                                                                            width: "95%",
                                                                            "& .MuiOutlinedInput-root": {
                                                                                "& fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                                "&:hover fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                                "& .MuiSelect-select": {
                                                                                    color: "#CBD5E1",
                                                                                    "&::placeholder": {
                                                                                        color: "#B0B0B0",
                                                                                    },
                                                                                },
                                                                                "&.Mui-focused fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                            },
                                                                            "& .MuiSvgIcon-root": {
                                                                                color: "#CBD5E1",
                                                                            },
                                                                        }}>
                                                                            <Select
                                                                                IconComponent={(props) => (
                                                                                    <ArrowDropDownIcon {...props} sx={{ color: "#CBD5E1" }} />
                                                                                )}
                                                                                onChange={handleChange}
                                                                                name="country"
                                                                                data-test-id="country"
                                                                                id="country"
                                                                                displayEmpty
                                                                                sx={{ color: "#CBD5E1" }}
                                                                                MenuProps={{
                                                                                    PaperProps: {
                                                                                        sx: {
                                                                                            maxHeight: 250,
                                                                                            overflowY: 'auto',
                                                                                            backgroundColor: '#FFFFFF',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>


                                                        <Grid container spacing={2} sx={{ marginTop: "2%" }}>
                                                            <Box style={{ display: "flex", flexDirection: "row", width: "89%", margin: "auto", marginLeft: "36px", justifyContent: "space-between" }}>

                                                                <Grid item xs={6} style={{ width: "100%" }}>
                                                                    <Box sx={{ marginBottom: "8px" }}>
                                                                        <Typography variant="body2" className="formLabel" style={{ fontSize: "70%" }}>State</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            width: "100%"
                                                                        }}
                                                                    >
                                                                        <FormControl 
                                                                        variant="outlined" 
                                                                        fullWidth 
                                                                        sx={{
                                                                            width: "95%",
                                                                            "& .MuiOutlinedInput-root": {
                                                                                "&:hover fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                                "& fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                                "& .MuiSelect-select": {
                                                                                    color: "#CBD5E1",
                                                                                    "&::placeholder": {
                                                                                        color: "#B0B0B0",
                                                                                    },
                                                                                },
                                                                                "&.Mui-focused fieldset": {
                                                                                    borderRadius: "8px",
                                                                                    borderColor: "#CBD5E1",
                                                                                },
                                                                            },
                                                                            "& .MuiSvgIcon-root": {
                                                                                color: "#CBD5E1",
                                                                            },
                                                                        }}>
                                                                            <Select
                                                                                onChange={handleChange}
                                                                                name="country"                                                                                
                                                                                data-test-id="country"
                                                                                displayEmpty
                                                                                id="countryName"
                                                                                IconComponent={(props) => (
                                                                                    <ArrowDropDownIcon {...props} sx={{ color: "#CBD5E1" }} />
                                                                                )}
                                                                                MenuProps={{
                                                                                    PaperProps: {
                                                                                        sx: {
                                                                                            overflowY: 'auto',
                                                                                            maxHeight: 250,
                                                                                            backgroundColor: '#FFFFFF',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                sx={{ color: "#CBD5E1" }}>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid 
                                                                item 
                                                                xs={6}>
                                                                    <Box sx={{ marginBottom: "8px" }}>
                                                                        <Typography variant="body2" className="formLabel" style={{ fontSize: "70%", marginLeft: "3%" }}>City</Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            width: "100%"
                                                                        }}
                                                                    >
                                                                        <Field
                                                                            as={TextField}
                                                                            id="city"
                                                                            name="city"
                                                                            sx={{
                                                                                width: "95%",
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    "& fieldset": {
                                                                                        borderColor: "#CBD5E1",
                                                                                        borderRadius: "8px"
                                                                                    },
                                                                                    "&:hover fieldset": {
                                                                                        borderColor: "#CBD5E1",
                                                                                        borderRadius: "8px"
                                                                                    },
                                                                                    "&.Mui-focused fieldset": {
                                                                                        borderColor: "#CBD5E1",
                                                                                        borderRadius: "8px"
                                                                                    },
                                                                                },
                                                                                "& .MuiInputBase-input": {
                                                                                    color: "#CBD5E1",
                                                                                    backgroundColor: "black",
                                                                                    "&:-webkit-autofill": {
                                                                                        WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                        backgroundColor: "black !important", 
                                                                                      WebkitTextFillColor: "#CBD5E1 !important" 
                                                                                    }
                                                                                  },
                                                                                "& .MuiInputLabel-root": {
                                                                                    color: "#CBD5E1",
                                                                                },                                                                              
                                                                            }}

                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} className="fieldWrapper" >
                                                            <Box sx={{ marginBottom: "8px" }}>
                                                                <Typography variant="body2" className="formLabel" style={{ fontSize: "13px", marginLeft: "3%" }}>Zip Code</Typography>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Field
                                                                    as={TextField}
                                                                    id="zipCode"
                                                                    name="zipCode"
                                                                    sx={{
                                                                        width: "95%",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                                borderRadius: "8px"
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            backgroundColor: "black",
                                                                            color: "#CBD5E1",
                                                                            "&:-webkit-autofill": {
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                backgroundColor: "black !important", 
                                                                              WebkitTextFillColor: "#CBD5E1 !important" 
                                                                            }
                                                                          }
                                                                    }}

                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Box>

                                                    <Box className="boxS">
                                                        <Box className="boxTitle">Summary</Box>
                                                        <Box className="subWrapper">
                                                            <Box className="summaryPrice">
                                                                <Box className="summaryTitle">Subtotal</Box>
                                                                <Box className="summaryTitle">$97.35</Box>
                                                            </Box>
                                                            <Box className="summaryPrice" style={{ marginTop: "6px", marginBottom: "38px" }}>
                                                                <Box className="summaryTitle">Taxes</Box>
                                                                <Box className="summaryTitle">$3.65</Box>
                                                            </Box>
                                                            <hr style={{ width: "92%", color: "#E2E8F0", marginBottom: "17px" }} />
                                                            <Box className="summaryPrice">
                                                                <Box className="summaryTitle" style={{ fontSize: "16px" }}>Total</Box>
                                                                <Box className="summaryTitle" style={{ fontSize: "24px", fontWeight: 900, fontFamily: "Nexa-Heavy" }}>$99</Box>
                                                            </Box>

                                                            <Box className="fieldWrapper" style={{ margin: "5% 3%" }}>
                                                                <Box className="formLabel">Add Promo code</Box>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Field
                                                                        as={TextField}
                                                                        id="promoCode"
                                                                        name="promoCode"
                                                                        sx={{
                                                                            width: "100%",
                                                                            "& .MuiOutlinedInput-root": {
                                                                                "& fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                                "&:hover fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                                "&.Mui-focused fieldset": {
                                                                                    borderColor: "#CBD5E1",
                                                                                    borderRadius: "8px"
                                                                                },
                                                                            },
                                                                            "& .MuiInputLabel-root": {
                                                                                color: "#CBD5E1",
                                                                            },
                                                                            "& .MuiInputBase-input": {
                                                                                color: "#CBD5E1",
                                                                                backgroundColor: "black",
                                                                                "&:-webkit-autofill": {
                                                                                    WebkitBoxShadow: "0 0 0 1000px black inset", 
                                                                                    backgroundColor: "black !important", 
                                                                                  WebkitTextFillColor: "#CBD5E1 !important" 
                                                                                }
                                                                              }
                                                                        }}

                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                            <Button className={`checkoutBtn ${isValid && dirty ? 'valid' : 'invalid'}`} data-test-id="openModal" type="submit" onClick={() => { this.handleOpenModal() }} style={{ marginTop: "10%" }}  disabled={!isValid || !dirty}>
                                                                <img src={cart} alt="cart" style={{ marginRight: "10px" }} />
                                                                Checkout securely</Button>

                                                            <Box className="imagePayment">
                                                                <img src={group_visa} alt="group_visa" />
                                                                <img src={group_klarna} alt="group_klarna" />
                                                                <img src={group_maestro} alt="group_maestro" />
                                                                <img src={group_paypal} alt="group_paypal" />
                                                                <img src={group_googlepay} alt="group_googlepay" />
                                                                <img src={group_applepay} alt="group_applepay" />
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </StyledWrapperHostPaymentDetails>
                    <Footer navigation={this.props.navigation} id={"1"} />
                </Box>
               <Dialog
                    open={this.state.isModalOpen}
                    PaperProps={{
                        sx: {
                            borderRadius: "8px 8px 32px 8px",
                            backgroundColor: "transparent",
                            height: "337px",
                            width: "600px",
                        },
                    }}
                    componentsProps={{
                        backdrop: {
                          style: { backgroundColor: 'rgba(0, 0, 0, 1)' },
                        },
                      }}
                >
                    <DialogContent
                        style={{ backgroundColor: "#44403C", color: "white", padding: 0 }}

                    >
                        <Box>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8%" }}>
                                <img src={star} alt="star" />
                            </Box>

                            <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy" }}>Success</h2>
                            <p style={{ textAlign: "center", fontFamily: "Nexa-Regular" }}>Your Payment Had Been Received
                            </p>
                        </Box>
                        <Box style={{
                            justifyContent: "center",
                            width: "100%", height: "104px", borderTop: "1px solid white", display: "flex",
                            alignItems: "center", marginTop: "50px"
                        }} id="loginModal">
                                <Button 
                                data-test-id="buttonClick"
                                onClick={async()=>{
                                    this.getRole()                                  
                    }
                            }
                                style={{
                                    height: "56px",
                                    width: "auto",
                                    borderRadius: "8px",
                                    padding:"0 5%",
                                    backgroundColor: "#FF0807",
                                    textTransform: "capitalize",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "14px",
                                    margin: "auto",
                                    marginTop: "24px",
                                    marginBottom : "30px"
                                }}>Start To Setup My {partner ? "Advertising Profile" :"Podcast"}</Button>
                        </Box>

                    </DialogContent>

                </Dialog> 
            </>
        );
    }

}
const StyledWrapperHostPaymentDetails = styled("div")({
    height: "auto",
    padding:"20px 50px",
    marginBottom: "6%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .scroll": {
        position: "relative",
        paddingRight: "40px",
        height: "47rem",
        scrollbarWidth: "thin",
        overflow: "hidden",
        scrollbarColor: "#FF0807 #4D4D4D",
        overflowY: "scroll",
    },
    "& .mainTitle": {
        margin: "auto",
        height: "40px",
        marginBottom: "2%",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "30px",
        display: "flex",
        alignItems: "center"
    },
    "& .imagePayment": {
        display: "flex",
        justifyContent: "center",
        marginTop: "33px"
    },
    "& .summaryTitle": {
        fontWeight: 100,
        fontSize: "14px",
        fontFamily: "Nexa-Regular"
    },
    "& .subWrapper": {
        width: "95%",
        height: "426px",
        margin: "3%",
    },
    "& .checkoutBtn": {
        height: "56px",
        width: "95%",
        borderRadius: "8px",
        color: "white",
        margin: "auto",
        fontFamily: "Nexa-Heavy",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "capitalize",
        fontWeight: 900,
        cursor: "pointer",
        fontSize: "16px",
        "&:hover": {
            color: "white"
        }

    },
    "& .checkoutBtn.valid": {
        backgroundColor: "#FF0807",
      },
      "& .checkoutBtn.invalid": {
        backgroundColor: "#B2B3B3",
      },
    "& .summaryPrice": {
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        margin: "auto",
    },
    "& .fieldWrapper": {
        width: "95%",
        margin: "auto"
    },
    "& .billingDetails": {
        height: "50px",
        width: "360px",
        borderLeft: "4px solid #FF0807",
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        marginLeft: "5%",
        marginTop: "5%",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "14px"
    },
    "& .formLabel": {
        padding: "2% 1%",
        fontFamily: "Nexa-Heavy",
        fontWeight: 500,
        fontSize: "14px",
        width: "100%"
    },
    "& .boxTitle": {
        width: "100%",
        height: "96px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "8%",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "24px",
        borderBottom: "1px solid #E2E8F0"
    },
    "& .lowerContainer": {
        width: "95%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        margin: "auto",
        marginTop: "1%",
        alignItems: "stretch",
        marginBottom: "2%",
        paddingBottom: "5%",
        "@media (max-width: 1200px)": {
            flexDirection: "column",
            height: "auto",
            alignItems:"center",
            justifyContent: "center",
            gap:"10px",
            width: "100%",
            marginLeft: "auto"
        },
    },
    "& .boxS": {
        height: "auto",
        width: "31%",
        borderRadius: "26px",
        background: "black",
        "@media (max-width: 1200px)": {
            width: "450px",
        },
    },
    "& .container": {
        color: "white",
        margin: "auto",
        background: "#44403C",
        marginBottom: "3%",
    },
    "& .title": {
        color: "white",
        fontWeight: 900,
        fontSize: "30px",
        fontFamily: "Nexa-Heavy",
    },
    "& .upper": {
        height: "auto",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "3%",
        margin: "auto",
        textAlign: "center",
    },
    "& .desc": {
        margin: "auto",
        fontWeight: 100,
        marginBottom: "2%",
        fontSize: "16px",
        fontFamily: "Nexa-Regular",
        width: "92%"
    },
})
// Customizable Area End
