// Customizable Area Start
import React from "react";
import { Box, Typography, Button, TextField, FormControl, Select, MenuItem } from "@mui/material";
import HostSetupRssFeedController, {
    Props,
} from "./HostSetupRssFeedController";
import { upload, goBack } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export default class HostSetupRssFeedInfo extends HostSetupRssFeedController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
        podcastCover: Yup.string().required('Required'),
        podcastName: Yup.string().required('Required'),
        podcastCreator: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        website: Yup.string().required('Required'),
        podcastLanguage: Yup.string().required('Required'),
        podcastSubtitle: Yup.string().required('Required'),
        podcastOwnerInfo: Yup.string().required('Required'),
        podcastKeywords: Yup.string().required('Required'),
        podcastContentRating: Yup.string().required('Required'),
        podcastFeedRedirection: Yup.string(),
        podcastCategory: Yup.string().required('Required'),
        podcastSubCategory: Yup.string().required('Required'),
    });

    render() {
        return (
            <Box ref={this.scrollRef}>
                <Box style={styles.mainContainer}>
                    <Box style={styles.pageTitle}>
                        <img onClick={this.handleGoBack} src={goBack} width={24} height={24} />
                        <span>Back</span>
                    </Box>
                    <Box>
                        <Box style={styles.container}>
                            <Box sx={styles.smContainer}>
                                <Typography variant="h3" sx={styles.pageHeading}>Podcast Info</Typography>
                                <Formik
                                    initialValues={this.state.podcastDetails}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => {
                                        this.handleFeedSubmit(values);
                                    }}
                                >
                                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Cover art*</Typography>
                                                <Typography style={{ marginTop: "2%", fontFamily: "Nexa-Regular", color: "#fff", fontSize: "14px" }}>
                                                    (Image size must be 3000x3000)
                                                </Typography>
                                                <Box
                                                    data-test-id="drop"
                                                    onDrop={(e) => this.handleDrop(e, setFieldValue)}
                                                    onDragOver={(e: any) => { e.preventDefault() }}
                                                    sx={{
                                                        height: "230px",
                                                        margin: "auto",
                                                        marginTop: "2%",
                                                        marginBottom: "3%",
                                                        padding: '20px',
                                                        borderRadius: '10px',
                                                        textAlign: 'center',
                                                        backgroundColor: 'transparent',
                                                        backgroundImage: this.state.imageUrl ? `url(${this.state.imageUrl})` : 'none',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        border: this.state.invalidFileMessage.length > 0 || Boolean(errors?.podcastCover) ? '2px dashed #FF0807' : '2px dashed #ccc',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        id="file-input"
                                                        data-test-id="selectFile"
                                                        onChange={(e) => this.handleFileSelect(e, setFieldValue)}
                                                    />
                                                    <label htmlFor="file-input">
                                                        <Box sx={{ marginTop: "5%", lineHeight: 2 }}>
                                                            <img src={upload} alt="upload" />
                                                            <Typography sx={styles.uploadImgText}>
                                                                Drap & Drop your file here
                                                                <br />
                                                                OR
                                                            </Typography>
                                                            <Button
                                                                sx={{
                                                                    ...styles.button,
                                                                    color: "white",
                                                                    background: "#FF0807",
                                                                    width: "auto",
                                                                    height: "35px",
                                                                    padding: "10px 16px",
                                                                    margin: "10px 0",
                                                                    "&: hover": {
                                                                        background: "#FF0807",
                                                                    }
                                                                }}
                                                            >
                                                                Browse file
                                                            </Button>
                                                            <Typography sx={styles.imgValidateText}>
                                                                Max. File Size: 200mb
                                                                <br />
                                                                Supports JPG and PNG files
                                                            </Typography>
                                                        </Box>
                                                    </label>
                                                </Box>
                                                {this.state.invalidFileMessage !== "" && (
                                                    <Typography variant="body2" style={{ color: "red", fontFamily: 'Nexa-Heavy', width: "80%", marginBottom: "2%" }}>
                                                        {this.state.invalidFileMessage}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Name of podcast*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastName"
                                                    value={values.podcastName}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastName)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Creator*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastCreator"
                                                    value={values.podcastCreator}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastCreator)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Description*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="description"
                                                    value={this.stripHtml(values.description)}
                                                    onChange={handleChange}
                                                    multiline
                                                    rows={7}
                                                    sx={{
                                                        width: "100%",
                                                        margin: "3% 0",
                                                        "& .MuiOutlinedInput-root": {
                                                            padding: 0,
                                                            fontSize: "18px",
                                                            "& fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "#CBD5E1",
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            color: "#CBD5E1",
                                                            padding: "16.5px 14px",
                                                            backgroundColor: "black",
                                                            "&:-webkit-autofill": {
                                                                backgroundColor: "black !important",
                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                            },
                                                        },
                                                    }}
                                                    error={Boolean(errors?.description)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Website*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="website"
                                                    value={values.website}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.website)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Language*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastLanguage"
                                                    value={values.podcastLanguage}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastLanguage)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Category*</Typography>
                                                <FormControl fullWidth variant="outlined"
                                                    sx={styles.sxStyle}>
                                                    <Select
                                                        IconComponent={(props) => (
                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }}/>
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: styles.PaperPropsSx,
                                                            },
                                                        }}
                                                        sx={{ backgroundColor: 'black', color: "#FFFFFF", margin: "3% 0"}}
                                                        name="podcastCategory"
                                                        value={values.podcastCategory}
                                                        onChange={handleChange}
                                                    >
                                                        {this.state.catagoryData.map((item: any) => {
                                                            return (
                                                                <MenuItem value={item.attributes.name} key={item.id}>{item.attributes.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Sub Category*</Typography>
                                                <FormControl fullWidth variant="outlined"
                                                    sx={styles.sxStyle}>
                                                    <Select
                                                        IconComponent={(props) => (
                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }}/>
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: styles.PaperPropsSx,
                                                            },
                                                        }}
                                                        sx={{ backgroundColor: 'black', color: "#FFFFFF", margin: "3% 0"}}
                                                        name="podcastSubCategory"
                                                        value={values.podcastSubCategory}
                                                        onChange={handleChange}
                                                    >
                                                        {this.state.subCatagoryData.map((item: any) => <MenuItem value={item.attributes.name} key={item.id}>{item.attributes.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Podcast Subtitle*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastSubtitle"
                                                    value={values.podcastSubtitle}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastSubtitle)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Owner Information*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastOwnerInfo"
                                                    value={values.podcastOwnerInfo}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastOwnerInfo)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Keywords*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastKeywords"
                                                    value={values.podcastKeywords}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastKeywords)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Content rating*</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastContentRating"
                                                    value={values.podcastContentRating}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastContentRating)}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" sx={styles.fieldHeading}>Feed redirection</Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    name="podcastFeedRedirection"
                                                    value={values.podcastFeedRedirection}
                                                    onChange={handleChange}
                                                    sx={styles.inputField}
                                                    error={Boolean(errors?.podcastFeedRedirection)}
                                                />
                                            </Box>
                                            <Box sx={styles.buttonWrapper}>
                                                <Button
                                                    type="submit"
                                                    sx={{ ...styles.button, color: "white !important", background: Object.keys(errors || {}).length > 0 || this.state.invalidFileMessage.length > 0 ? "#B2B3B3" : "#FF0807" }}
                                                    data-test-id="continueBtn"
                                                    disabled={Object.keys(errors || {}).length > 0 || this.state.invalidFileMessage.length > 0}
                                                >
                                                    Continue
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Footer navigation={this.props.navigation} id={""} />
            </Box>
        );
    }
};

const styles = {
    mainContainer: {
        padding: "20px 50px",
    },
    pageTitle: {
        paddingBottom: "1%",
        display: "flex",
        alignItems: "center",
        gap: 10,
        color: 'white',
        fontWeight: 900,
        fontSize: "30px",
        fontFamily: "Nexa-Heavy",
    },
    pageHeading: {
        marginBottom: "50px",
        fontFamily: "Nexa-Heavy",
        fontSize: "40px",
        fontWeight: 900,
        lineHeight: "40px",
        color: "#FFFFFF",
    },
    fieldHeading: {
        fontFamily: "Nexa-Heavy",
        fontSize: "24px",
        fontWeight: 900,
        color: "#FFFFFF",
        lineHeight: "32px",
    },
    uploadImgText: {
        marginTop: "2px",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        fontWeight: 900,
        color: "#FFFFFF",
        lineHeight: "20px",
    },
    imgValidateText: {
        fontFamily: "Nexa-Heavy",
        fontSize: "12px",
        fontWeight: 400,
        color: "#FFFFFF",
        lineHeight: "18px",
    },
    inputField: {
        width: "100%",
        margin: "3% 0",
        "& .MuiOutlinedInput-root": {
            fontSize: "18px",
            "& fieldset": {
                borderColor: "#CBD5E1",
            },
            "&:hover fieldset": {
                borderColor: "#CBD5E1",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#CBD5E1",
            },
        },
        "& .MuiInputLabel-root": {
            color: "#CBD5E1",
        },
        "& .MuiInputBase-input": {
            backgroundColor: "black",
            color: "#CBD5E1",
            "&:-webkit-autofill": {
                WebkitTextFillColor: "#CBD5E1 !important",
                WebkitBoxShadow: "0 0 0 1000px black inset",
                backgroundColor: "black !important",
            },
        },
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        marginTop: "3%",
        padding: "16px",
        width: "380px",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 900,
        textTransform: "capitalize" as const,
        border: "none",
        textAlign: "center" as const,
        fontFamily: "Nexa-Heavy",
        backgroundColor: "#FF0807",
        "&:hover": {
            backgroundColor: "#FF0807",
        },
    },
    container: {
        paddingBottom: "20px",
        height: "auto",
        width: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        background: "#312F2D",
        borderRadius: "16px 16px 8px 8px",
        borderTop: "114px solid #FF0807",
    },
    smContainer: {
        margin: "40px 150px",
        width: "100%",
    },
    sxStyle: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#FFFFFF",
            },
            "&:hover fieldset": {
                borderColor: "#FFFFFF",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#FFFFFF",
            },
            "& .MuiSelect-select": {
                color: "#FFFFFF",
            },
        },
        "& .MuiInputLabel-root": {
            color: "transparent",
        },
        "& .MuiSvgIcon-root": {
            color: "#FFFFFF",
        },
    },
    PaperPropsSx: {
        overflowY: 'auto',
        maxHeight: 250,
        backgroundColor: 'black',
        "& .Mui-selected": {
            backgroundColor: "#333333 !important",
        },
        "& .MuiMenuItem-root": {
            color: "#FFFFFF",
        },
    }
}
// Customizable Area End
