// Customizable Area Start
import { createRef } from 'react';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { suggested1, suggested2 } from '../../../dashboard/src/assets';
import { getStorageData } from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleCommonPath: (path: string) => void;
    handleAddAudio: (value: string, id: string) => void;
    // Customizable Area End
};

// Customizable Area Start

interface Episode {
    id: string;
    cover: string;
    firstName: string;
    lastName: string;
    saved: boolean;
    savedId: string | null;
    name: string;
    episode_content: string;
    added: boolean;
}
  
  interface ApiResponse {
    id: string;
    type: string;
    attributes: {
    episode_content: string
      cover: string;
      name: string;
      account: {
        first_name: string;
        last_name: string;
      };
      save_episode: {
        saved: boolean;
        saved_id: string | null;
      };
      added: {
        song_added: boolean
      }
    };
  }

interface S {
    trendingEpisode: Episode[],
};
// Customizable Area End

interface SS {
    id: any;
};

export default class TrendingEpisodeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTrendingEpisodeApiCallId: any;
    addToPlayListAPICallId: string = "";
    removeFromPlayListAPICallId: string = "";
    saveEpisodeCallId: string = "";
    unsaveEpisodeCallId: string = "";
    scrollRef: any = createRef();
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            trendingEpisode: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        if (apiRequestCallId === this.getTrendingEpisodeApiCallId) {
            this.handleResTrendingEpisode(from, message)
        }

        if (apiRequestCallId === this.addToPlayListAPICallId) {
            this.handleResForAddToPlayList(from, message)
        }

        if(apiRequestCallId === this.removeFromPlayListAPICallId) {
            this.handleResForRemoveFromPlayList(from, message)
        }

        if(apiRequestCallId === this.saveEpisodeCallId) {
            this.handleResForSaveEpisode(from, message);
        }

        if(apiRequestCallId === this.unsaveEpisodeCallId) {
            this.handleResForUnSaveEpisode(from, message)
        }
      }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.fetchTrendingEpisodes();

        setTimeout(() => {
            if (this.scrollRef.current) {
                this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
            }
        }, 0);
    }

    fetchTrendingEpisodes = async() => { 
        let token = await getStorageData("token");
        let subCatId = await getStorageData("subCatId");
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        )  

        this.getTrendingEpisodeApiCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getTrendingEpisodeApiEndPoint}${subCatId}&per_page=20&page=1`
        )

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            token: JSON.parse(token),
          })
        )  

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleResTrendingEpisode = async (from: string, message: Message) => {
        let apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiResponse.data.length > 0) {
            const formattedData: Episode[] = apiResponse.data.map((item: ApiResponse) => ({
                id: item.id,
                cover: item.attributes.cover,
                firstName: item.attributes.account.first_name,
                lastName: item.attributes.account.last_name,
                saved: item.attributes.save_episode.saved,
                savedId: item.attributes.save_episode.saved_id,
                name: item.attributes.name,
                episode_content: item.attributes.episode_content,
                added: item.attributes.added.song_added
              }));

            this.setState({trendingEpisode: formattedData})
        }
        else {
            this.setState({trendingEpisode: []})
        }
    }

    async handleAddToPlayList(id: string) {
        let token = await getStorageData("token");
        let playListId = await getStorageData("main_playlist_id");
    
        const header = {
          token: JSON.parse(token),
        };
    
        let formdata = new FormData();
        formdata.append("id", playListId);
        formdata.append("song_id", `${id}`);
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.addToPlayListAPICallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addToPlayList}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResForAddToPlayList = async (from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiResponse.message == "Song added successfully") {
            this.fetchTrendingEpisodes();
        }
    }

    async handleRemoveFromPlayList(id: string) {    
        let token = await getStorageData("token");
        let playListId = await getStorageData("main_playlist_id");
    
        const header = {
          token: JSON.parse(token),
        };
    
        let formdata = new FormData();
        formdata.append("id", playListId);
        formdata.append("song_id", `${id}`);
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.removeFromPlayListAPICallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.removeFromPlayList}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResForRemoveFromPlayList = async (from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (apiResponse.message == "Song removed successfully") {
            this.fetchTrendingEpisodes();
        }
    }
    
    async handleSaveEpisode(id: string) {
        let token = await getStorageData("token");

        const body = {
            data: {
              saveable_type: "BxBlockLogin::Episode", 
              saveable_id: id
            }
        }

        const header = {
          "Content-Type": "application/json",
          token: JSON.parse(token),
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.saveEpisode}`
        );

        this.saveEpisodeCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResForSaveEpisode = async (from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (apiResponse.meta.message == "Successfully Saved") {
            this.fetchTrendingEpisodes();
        }
    };

    async handleUnSaveEpisode(id: string | null) {
        let token = await getStorageData("token");
        const header = {
          token: JSON.parse(token),
        };
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.unsaveEpisodeCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.saveEpisode}/${id}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );   

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResForUnSaveEpisode = async (from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiResponse.message == "Successfully Unsaved") {
            this.fetchTrendingEpisodes();              
        }
    };

    // Customizable Area End
}
