import React, { Component } from 'react';
import { Box, Dialog, Typography, TextField, Button   } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { shares } from '../../../../blocks/audiolibrary/src/assets';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface ShareDialogueProps  {
    open: boolean;
    onClose : () => void;
    copyLink: string;
    handleCopy: (copylink: string) => void;
    copied: boolean;
}


class ShareDialogue extends Component<ShareDialogueProps> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                slotProps={{
                backdrop: {
                    sx: {
                    backdropFilter: 'blur(5px)', // Apply blur effect
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional semi-transparent background
                    },
                },
                }}
                sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "1000px",
                    width: "1000px",
                    height: "350px",
                    background: "#44403C !important",
                    borderRadius:"8px",
                    borderBottomRightRadius:"32px"
                },
                }}
                data-test-id="shareModal"
            >
                <Box padding={3}>
                    <Box display={"flex"} justifyContent={"flex-end"} data-test-id="cancelIcon" onClick={this.props.onClose}>
                        <CloseIcon sx={{color:"white"}} />
                    </Box>
                    <Box display={"flex"}>
                        <img src={shares} style={{width:"22px"}} />
                        <Typography sx={{ fontSize: "24px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900}} color={"white"}>
                            Share Post
                        </Typography>
                    </Box>
                    <Box mt={5}>
                            <Box display={"flex"} gap={2} alignItems={"flex-end"}>
                                <Box>
                                    <Typography sx={{ fontSize: "14px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900, marginBottom:"8px"}}>Post URL</Typography>
                                    <TextField
                                        name="shareValue" 
                                        onChange={() => {}} 
                                        InputProps={{
                                        style: {
                                            color: "white",
                                            backgroundColor: "black",
                                            borderRadius: "8px",
                                            height: "56px", 
                                        },
                                        }}
                                        value={this.props.copyLink}
                                        sx={{
                                        width: "707px",
                                        "& .MuiOutlinedInput-root": {
                                            fontFamily: "Nexa-Heavy",
                                            fontWeight: 900,
                                            fontSize: "14px",
                                            height: "56px", // Match height
                                            "& fieldset": {
                                            borderColor: "white",
                                            borderRadius: "8px",
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                            borderColor: "white",
                                            },
                                        },
                                        }}
                                    />
                                </Box>
                                {this.props.copied ? 
                                    <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#030303", 
                                        color: "white", 
                                        fontFamily: "Nexa-Heavy",
                                        height: "56px", 
                                        borderRadius: "8px", 
                                        borderColor: "#030303", 
                                        "&:hover": {
                                        bgcolor: "#030303", 
                                        color: "white", 
                                        borderColor: "#030303", 
                                        },
                                    }}
                                    size="large"
                                    >
                                    Copied
                                    </Button>
                                :
                                <Button
                                    data-test-id="shareCopyBtn"
                                    onClick={() => this.props.handleCopy(this.props.copyLink)}
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#FF0807", 
                                        color: "white", 
                                        fontFamily: "Nexa-Heavy",
                                        height: "56px", 
                                        borderRadius: "8px", 
                                        borderColor: "#FF0807", 
                                        "&:hover": {
                                        bgcolor: "#FF0807", 
                                        color: "white", 
                                        borderColor: "#FF0807", 
                                        },
                                    }}
                                    size="large"
                                    startIcon={<ContentCopyIcon />}>
                                    Copy
                                </Button>}
                            </Box>
                        </Box>
                </Box>
            </Dialog>
            
        )
    }
}

export default ShareDialogue;