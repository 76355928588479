// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Grid, Button, TextField, RadioGroup, Radio, FormControlLabel, MenuItem, Typography, FormControl, Select, Switch
} from "@mui/material";
import NewContentController, {
  Props,
} from "./NewContentController.web";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const MIN_WINDOW_HEIGHT = 900


export default class NewContentAdds extends NewContentController {
  constructor(props: Props) {
    super(props);
  }
  validationSchema = Yup.object().shape({
  });

  render() {
    return (
      <Box ref={this.scrollRef} sx={{ maxHeight: "100%", overflowY: "auto" }}>
          <StyleWrapperSetupNewContent>
            <Box className="contentmaincontenttitle">New Content</Box>
            <Box>
              <Box className="contentredContainer"></Box>
              <Grid className="contentcontainer">
                <Grid className="contentsubContainer">
                  <Formik
                    initialValues={{
                      episodeExploration: false,
                      expireDay: "",
                      expireMonth: "",
                      expireYear: "",
                      episodeAds: false,
                      typeOfAds: "",
                      numberOfAds: "",
                      premiumEpisode: false,
                      publishDay: "",
                      publishMonth: "",
                      publishYear: "",
                      publishHour: "",
                      publishMinute: "",
                      explicitContent: false,
                      restrictedEpisode: false,
                      numberOfPostRollEpisode: "",
                      expiredayepisode:"",
                      expireepisodeMonth:"",
                      expireepisodeYear:"",
                    }}
                    data-test-id="updateFormTestId"
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                     this.handleSubmitUpdate(values)
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      values,
                      errors,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            gap: "30px",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2%",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                Set episode expiration date?
                              </label>

                              <Switch
                                checked={values.episodeExploration}
                                data-test-id="episodeExploration"
                                onChange={() => setFieldValue('episodeExploration', !values.episodeExploration)}
                                value={values.episodeExploration}
                                name="episodeExploration"
                                color="default"
                                sx={{
                                  marginLeft: "2px",
                                  width: "80px",
                                  height: "50px",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    transform: "translateX(16px)", // Move thumb to the right when checked
                                    color: "#fff", // Thumb color when checked
                                  },
                                  "& .MuiSwitch-switchBase": {
                                    color: "#fff",
                                    position: "absolute",
                                    top: "6px",
                                    left: values.episodeExploration ? "18px" : "8px",
                                  },
                                  "& .MuiSwitch-thumb": {
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#fff", // White thumb
                                  },
                                  "& .MuiSwitch-track": {
                                    backgroundColor: values.episodeExploration ? "rgb(255, 8, 7)" : "#ccc", // Highlight only when checked, gray when off
                                    opacity: !values.episodeExploration ? "0.5 " : "1 !important",
                                    borderRadius: "16px",
                                  },
                                }}
                              />
                            </Box>

                            <Box>
                              <Box>
                                <label
                                  style={{
                                    color: values.episodeExploration ? "white" : "gray",
                                    fontSize: "16px",
                                    fontFamily: "Nexa-Heavy",
                                  }}
                                >
                                  When do you want your episode to expire?
                                </label>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  padding: "10px 0",
                                }}
                              >
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      style = {{color : values.episodeExploration ? "white" : "gray" }}
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Day 
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                     
                                      name="expireDay"
                                      onChange={handleChange}
                                      disabled = {!values.episodeExploration ? true : false}
                                      displayEmpty
                                      defaultValue=""
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="dayBtn"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          sx={{ color: "#FFFFFF" }}
                                          {...props}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: "#44403C",
                                            maxHeight: 250,
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            overflowY: "auto",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled id="day">
                                        DD
                                      </MenuItem>
                                      {Array.from({ length: this.getMaxDaysInMonth(Number(values.expireMonth), Number(values.expireYear))}, (_, i) => (
                                        <MenuItem 
                                        key={i + 1} 
                                        value={i + 1}
                                        id="day"
                                        >
                                          {i + 1}
                                        </MenuItem>))}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box>
                                  <Box 
                                  sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      id="month"
                                      className="label"
                                      style = {{color : values.episodeExploration ? "white" : "gray" }}
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Month
                                    </Typography>
                                    
                                  </Box>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="expireMonth"
                                      disabled = {!values.episodeExploration ? true : false}
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="monthBtn"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          sx={{ color: "#FFFFFF" }}
                                          {...props}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 250,
                                            backgroundColor: "#44403C",
                                            "& .MuiMenuItem-root": {
                                              fontSize: "0.875rem",
                                              color: "#FFFFFF",
                                            },
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            overflowY: "auto",
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        MM
                                      </MenuItem>
                                      {Array.from({ length: 12 }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box>
                                  <Box sx={{ marginBottom: "8px" }} id="Year">
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      style = {{color : values.episodeExploration ? "white" : "gray" }}
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Year
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="expireYear"
                                      disabled = {!values.episodeExploration ? true : false}
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      data-test-id="yearBtn"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      style={{ width: "138px", background: "black" }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            backgroundColor: "#44403C",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ 
                                        color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled 
                                      id="year"> 
                                        YYYY
                                      </MenuItem>
                                      {Array.from({ length: 2035 - 2025 + 1 }, (_, i) => (
                                        <MenuItem key={2025 + i} value={2025 + i}
                                        id="year"                                        
                                        >
                                          {2025 + i}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                marginTop: "2%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                Ads for the episode?
                              </label>
                              <Switch
                                checked={values.episodeAds}
                                value={values.episodeAds}
                                data-test-id="episodeAdds"
                                onChange={() => { setFieldValue("episodeAds", !values.episodeAds) }}
                                name="episodeAds"
                                color="default"
                                sx={{
                                  marginLeft: "2px",
                                  width: "80px",
                                  height: "50px",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    transform: "translateX(16px)", // Move thumb to the right when checked
                                    color: "#fff", // Thumb color when checked
                                  },
                                  "& .MuiSwitch-switchBase": {
                                    color: "#fff",
                                    position: "absolute",
                                    top: "6px",
                                    left: values.episodeAds ? "18px" : "8px",
                                  },
                                  "& .MuiSwitch-thumb": {
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#fff", // White thumb
                                  },
                                  "& .MuiSwitch-track": {
                                    backgroundColor: values.episodeAds ? "rgb(255, 8, 7)" : "#ccc", // Highlight only when checked, gray when off
                                    opacity: !values.episodeAds ? "0.5 " : "1 !important",
                                
                                    borderRadius: "16px",
                                  },
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2%",
                                marginBottom: "4.5%",
                                alignItems: "center",
                                opacity:"0.5"
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                What type of Ads would you like to show pre and post-roll for this episode
                              </label>
                            </Box>
                            <RadioGroup
                              id="outlined-basic"
                              row
                              aria-labelledby="radio-buttons-group-label"
                              name="episodeType"
                              //   value={values.episodeType}
                              onChange={handleChange}
                              sx={{
                                marginTop: "2%",
                                background: "black",
                                gap: "70px",
                                paddingLeft: "18px",
                                borderRadius: "5px",
                                alignItems: "center",
                                opacity:"0.5"
                              }}
                            >
                              <FormControlLabel
                                value="Audio"
                                control={
                                  <Radio
                                  disabled
                                    sx={{
                                      margin: "0px",
                                      "&:before": {
                                        content: '""',
                                        width: 12,
                                        height: 12,
                                        borderRadius: "50%",
                                        border: "2px solid #CBD5E1",
                                        background: "black",
                                      },
                                      "&.Mui-checked:before": {
                                        content: '""',
                                        width: 12,
                                        height: 12,
                                        borderRadius: "50%",
                                        border: "3px solid red",
                                        backgroundColor: "white",
                                      },
                                      "& .css-hyxlzm": { display: "none" },
                                    }}
                                  />
                                }
                                label="Audio"
                                sx={{
                                  color: "#CBD5E1 !important",
                                  paddingLeft: "5px",
                                  alignItems: "center",
                                  fontFamily: "Nexa-Regular",
                                  marginRight: "0px",
                                  height: "55px",
                                }}
                                componentsProps={{
                                  typography: {
                                    sx: {
                                      paddingTop: "3px",
                                      color: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                              <FormControlLabel
                                value="Video"
                                control={
                                  <Radio
                                    disabled
                                    sx={{
                                      "&:before": {
                                        content: '""',
                                        width: 12,
                                        height: 12,
                                        borderRadius: "50%",
                                        border: "2px solid #CBD5E1",
                                        background: "black",
                                        color: "white",
                                      },
                                      "&.Mui-checked:before": {
                                        content: '""',
                                        width: 12,
                                        height: 12,
                                        borderRadius: "50%",
                                        border: "3px solid red",
                                        backgroundColor: "white",
                                        color: "white",
                                      },
                                      "& .css-hyxlzm": { display: "none" },
                                    }}
                                  />
                                }
                                label="Video"
                                sx={{
                                  color: "#CBD5E1 !important",
                                  padding: "5px",
                                  alignItems: "center",
                                  fontFamily: "Nexa-Regular",
                                }}
                                componentsProps={{
                                  typography: {
                                    sx: {
                                      paddingTop: "3px",
                                      color: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />

                              <FormControlLabel
                                value="Both"
                                control={
                                  <Radio
                                  disabled
                                    sx={{
                                      "&:before": {
                                        content: '""',
                                        width: 12,
                                        height: 12,
                                        borderRadius: "50%",
                                        border: "2px solid #CBD5E1",
                                        background: "black",
                                      },
                                      "&.Mui-checked:before": {
                                        content: '""',
                                        width: 12,
                                        height: 12,
                                        borderRadius: "50%",
                                        border: "3px solid red",
                                        backgroundColor: "white",
                                      },
                                      "& .css-hyxlzm": { display: "none" },
                                    }}
                                  />
                                }
                                label="Both"
                                sx={{
                                  color: "#CBD5E1 !important",
                                  paddingRight: "5px",
                                  alignItems: "center",
                                  fontFamily: "Nexa-Regular",
                                }}
                                componentsProps={{
                                  typography: {
                                    sx: {
                                      paddingTop: "3px",
                                      color: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                            </RadioGroup>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5%",
                                alignItems: "center",
                                opacity:"0.5"
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                How Many Ads Do You Want in Pre-Roll For This Episode?
                              </label>
                            </Box>
                            <TextField
                              id="outlined-basic"
                              disabled
                              value={values.numberOfPostRollEpisode}
                              name="numberOfPostRollEpisode"
                              onChange={() => { setFieldValue("numberOfPostRollEpisode", values.numberOfPostRollEpisode) }}
                              sx={{                              
                                marginTop: "2%",
                                opacity:"0.5",
                                width: "100%",
                                "& .MuiInputBase-input": {
                                    border:"1px solid rgb(203, 213, 225)",
                                  borderRadius:"4px",
                                  color: "#CBD5E1",
                                  backgroundColor: "black",
                                  "&:-webkit-autofill": {
                                    backgroundColor: "black !important",
                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                    WebkitTextFillColor: "#CBD5E1 !important",
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  color: "#CBD5E1",
                                },
                                "& .MuiOutlinedInput-root": {                                 
                                  height: "56px",
                                  "&:hover fieldset": {
                                  },                                 
                                },
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2%",
                                alignItems: "center",
                                opacity:"0.5"
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                Which Ads would you like to insert in pre-roll?
                              </label>
                            </Box>
                            <Button
                              style={{
                                color: "white",
                                textTransform: "capitalize",
                                opacity:"0.5"
                              }}
                              data-test-id="submit-btn"
                              className="contentinventorybtn"
                              disabled
                            >
                              View Inventory Options
                            </Button>
                          </Box>

                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2%",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                Do you want this to be a premium episode?
                              </label>
                              <Switch
                                checked={values.premiumEpisode}
                                value={values.premiumEpisode}
                                data-test-id="premiumEpisode"
                                onChange={() => {
                                  setFieldValue("premiumEpisode", !values.premiumEpisode)
                                }}
                                name="premiumEpisode"
                                color="default"
                                sx={{
                                  marginLeft: "2px",
                                  width: "80px",
                                  height: "50px",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    transform: "translateX(16px)", // Move thumb to the right when checked
                                    color: "#fff", // Thumb color when checked
                                  },
                                  "& .MuiSwitch-switchBase": {
                                    color: "#fff",
                                    position: "absolute",
                                    top: "6px",
                                    left: values.premiumEpisode ? "18px" : "8px",
                                  },
                                  "& .MuiSwitch-thumb": {
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#fff", // White thumb
                                  },
                                  "& .MuiSwitch-track": {
                                    opacity: !values.premiumEpisode ? "0.5 " : "1 !important",
                                    backgroundColor: values.premiumEpisode ? "#FF0000" : "#ccc", 
                                    borderRadius: "16px",
                                  },
                                }}
                              />
                            </Box>
                            <Box>
                              <Box>
                                <label
                                  style={{
                                    color: "white",
                                    fontFamily: "Nexa-Heavy",
                                    fontSize: "16px",
                                  }}
                                >
                                  Publish Date and Time?
                                </label>
                              </Box>
                              <Box
                                sx={{
                                  gap: "20px",
                                  padding: "10px 0",
                                  display: "flex",
                                }}
                              >
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Day
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishDay"
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="dayBtn2"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            backgroundColor: "#44403C",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled 
                                      id="epday">
                                        DD
                                      </MenuItem>
                                      {Array.from({ length: this.getMaxDaysInMonth(Number(values.publishMonth), Number(values.publishYear))}, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1} 
                                        id="epday">
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Month
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          fontSize: "0.875rem",
                                          color: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishMonth"
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="monthBtn2"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: "#44403C",
                                            overflowY: "auto",
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            maxHeight: 250,
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        MM
                                      </MenuItem>
                                      {Array.from({ length: 12 }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box>
                                  <Box sx={{ marginBottom: "8px" }} id="test">
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Year
                                    </Typography>
                                  </Box>
                                  <FormControl
                                  id="expireepisodeYear"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          fontSize: "0.875rem",
                                          color: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishYear"
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      data-test-id="yearBtn2"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      style={{ width: "138px", background: "black" }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            overflowY: "auto",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            backgroundColor: "#44403C",
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            maxHeight: 250,
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        YYYY
                                      </MenuItem>
                                      {Array.from({ length: 2035 - 2025 + 1 }, (_, i) => (
                                        <MenuItem key={2025 + i} value={2025 + i}>
                                          {2025 + i}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  padding: "10px 0",
                                }}
                              >
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Hour
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      
                                      name="publishHour"
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      style={{ width: "216px", background: "black" }}
                                      data-test-id="dayBtn3"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            overflowY: "auto",
                                            backgroundColor: "#44403C",
                                            maxHeight: 250,
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        HH
                                      </MenuItem>
                                      {Array.from({ length: 24 }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem" }}
                                    >
                                      Min
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishMinute"
                                      onChange={handleChange}
                                      displayEmpty
                                      defaultValue=""
                                      style={{ width: "216px", background: "black" }}
                                      data-test-id="monthBtn3"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            "& .MuiMenuItem-root": {
                                              fontSize: "0.875rem",
                                              color: "#FFFFFF",
                                            },
                                            backgroundColor: "#44403C",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        MM
                                      </MenuItem>
                                      {Array.from({ length: 61 }, (_, i) => (
                                        <MenuItem key={i} value={i}>
                                          {i}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",

                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                Does This Episode Contain Explicit Content?
                              </label>
                              <Switch
                                checked={values.explicitContent}
                                value={values.explicitContent}
                                data-test-id="explicitContent"
                                onChange={() => { setFieldValue("explicitContent", !values.explicitContent) }}
                                name="explicitContent"
                                color="default"
                                sx={{
                                  marginLeft: "2px",
                                  width: "80px",
                                  height: "50px",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    transform: "translateX(16px)", // Move thumb to the right when checked
                                    color: "#fff", // Thumb color when checked
                                  },
                                  "& .MuiSwitch-switchBase": {
                                    color: "#fff",
                                    position: "absolute",
                                    top: "6px",
                                    left: values.explicitContent ? "18px" : "8px",
                                  },
                                  "& .MuiSwitch-thumb": {
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#fff", // White thumb
                                  },
                                  "& .MuiSwitch-track": {
                                    backgroundColor: values.explicitContent ? "rgb(255, 8, 7)" : "#ccc", // Highlight only when checked, gray when off
                                    opacity: !values.explicitContent ? "0.5 " : "1 !important",
                                    borderRadius: "16px",
                                  },
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",

                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                }}
                              >
                                Restricted Episode For 18+?
                              </label>
                              <Switch
                                checked={values.restrictedEpisode}
                                value={values.restrictedEpisode}
                                data-test-id="restrictedEpisode"
                                onChange={() => {
                                  setFieldValue("restrictedEpisode", !values.restrictedEpisode);
                                }}
                                name="restrictedEpisode"
                                color="default"
                                sx={{
                                  marginLeft: "2px",
                                  width: "80px",
                                  height: "50px",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    transform: "translateX(16px)", // Move thumb to the right when checked
                                    color: "#fff", // Thumb color when checked
                                  },
                                  "& .MuiSwitch-switchBase": {
                                    color: "#fff",
                                    position: "absolute",
                                    top: "6px",
                                    left: values.restrictedEpisode ? "18px" : "8px",
                                  },
                                  "& .MuiSwitch-thumb": {
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#fff", // White thumb
                                  },
                                  "& .MuiSwitch-track": {
                                    backgroundColor: values.restrictedEpisode ? "rgb(255, 8, 7)" : "#ccc", // Highlight only when checked, gray when off
                                    opacity: !values.restrictedEpisode ? "0.5 " : "1 !important",
                                
                                    borderRadius: "16px",
                                  },
                                }}
                              />

                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2%",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                  opacity:"0.5"
                                }}
                              >
                                How Many Ads Do You Want in Pre-Roll For This
                                Episode?
                              </label>
                            </Box>
                            <TextField
                            disabled
                              id="outlined-basic"
                              name="numberOfPostRollEpisode"
                              value={values.numberOfPostRollEpisode}
                              onChange={() => { setFieldValue("numberOfPostRollEpisode", values.numberOfPostRollEpisode) }}
                              sx={{
                                opacity:"0.5",
                                marginTop: "2%",
                                width: "100%",
                                "& .MuiInputLabel-root": {
                                  color: "#CBD5E1",
                                },
                                "& .MuiInputBase-input": {
                                 
                                    border:"1px solid rgb(203, 213, 225)",
                                  borderRadius:"4px",
                                  color: "#CBD5E1",
                                  backgroundColor: "black",
                                  "&:-webkit-autofill": {
                                    backgroundColor: "black !important",
                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                    WebkitTextFillColor: "#CBD5E1 !important",
                                  },
                                },

                                "& .MuiOutlinedInput-root": {
                                
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#CBD5E1",
                                  },
                                 
                                  height: "56px",
                                },
                              }}
                            />
                            <Box
                            id="adspayroll"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2%",
                                alignItems: "center",
                                opacity:"0.5"
                              }}
                            >
                              <label
                              id="payroll"
                                style={{
                                  color: "white",
                                  fontSize: "16px",
                                  fontFamily: "Nexa-Heavy",
                                  paddingTop:"1px"
                                }}
                              >
                                Which Ads would you like to insert in pre-roll?
                              </label>
                            </Box>
                            <Button
                              style={{
                                color: "white",
                                textTransform: "capitalize",
                                opacity:"0.5"
                              }}
                              data-test-id="submit-btn"
                              className="contentinventorybtn"
                              disabled
                            >
                              View Inventory Options
                            </Button>
                          </Box>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              color: "white",
                              width: "80%",
                              marginTop: "0px",
                              height: "55px",
                              textTransform: "capitalize",
                            }}
                            data-test-id="form-submit-btn"
                            className="contentinventorybtn"
                            type="submit"
                          //   onClick={() => console.log(values, "diya")}
                          >
                            Continue
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Box>
          </StyleWrapperSetupNewContent>
          <Footer navigation={this.props.navigation} id={"1"} />
      </Box>
    );
  }

}
const StyleWrapperSetupNewContent = styled('div')({
  height: "auto",
  padding:"20px 50px",
  background: "black",
  "& .subContain": {
    position: "relative",
    paddingRight: "40px",
    height: "47rem",
    overflow: "hidden",
    scrollbarWidth: "thin",
    overflowY: "scroll",
    scrollbarColor: "#FF0807 #4D4D4D",
  },
  "& .btnSignUp": {
    cursor: "pointer",
    height: "6%",
    background: "#B2B3B3",
    color: "white",
    margin: "auto",
    fontFamily: "Nexa-Heavy",
    display: "flex",
    marginTop: "5%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "40%",
    borderRadius: "8px",
    fontSize: "14px",
  },
  "& *": {
    boxSizing: "border-box",
  },
  "& .field": {
    height: "56px",
    width: "954px",
  },

  
  "& .contentinventorybtn": {
    cursor: "pointer",
    borderRadius: "8px",
    height: "45px",
    margin: "auto",
    display: "flex",
    marginTop: "5%",
    marginBottom: "7%",
    color: "white",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    width: "100%",
    fontSize: "14px",
    background: "#FF0807",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .contenttitle": {
    color: "white",
    fontFamily: "Nexa-Heavy",
    textTransform: "capitalize",
    paddingTop: "1%"
  },
 
  "& .contentsubContainer": {
    display: "flex",
    justifyContent: "center",
    height: "auto",
    width: "80%",
    marginTop: "28px",
  },
  "& .contentredContainer": {
    height: "80px",
    margin: 0,
    borderRadius: "0px 16px 0px 0px",
    backgroundColor: "#FF0807",
  },
  "& .label": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
  },
  "& .contentmaincontenttitle": {
    margin: "auto",
    height: "40px",
    color: "white",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "30px",
    marginBottom: "21px"
  },
  "& .contentcontainer": {
    height: "auto",
    backgroundColor: "#312F2D",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItens: "center",
    margin: "auto",
    borderRadius: "0px 0px 8px 8px"
  },
})
const webStyles = {
  contentcontainer: {
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const,
    scrollbarWidth: "thin",
    scrollbarColor: "red gray",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "gray",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  } as const
}
// Customizable Area End
