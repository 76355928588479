// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Dialog,
    DialogContent,
    Typography,
} from "@mui/material";
import PartnerLoginController, {
    Props,
} from "./PartnerLoginController";
import { hostBg } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class PartnerLogin extends PartnerLoginController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <>
                <Box ref={this.scrollRef}>
                <StyledWrapperSignUp>
                <Box id="loginHost">
                <Box className="container">
                    <img src={hostBg} alt="hostBg" className="image"/>
                    <Box className="subContainer">
                        <Box className="title">Begin to set up my Partner profile</Box>
                        <Box className="desc">Start the journey to broadcast your message to your audience</Box>
                        <Button data-test-id = "buttonClick" className="btn" onClick={()=>{this.props.handleCommonPath("HostPaymentPlan")}}>Begin To Setup My Advertising Profile</Button>                        
                    </Box>                    
                </Box>
                </Box>
                </StyledWrapperSignUp>
                <Footer navigation={this.props.navigation} id={"1"} />
                </Box>
                <Dialog
                open={this.state.isHost}
                PaperProps={{
                    sx: {
                        borderRadius: "8px 8px 32px 8px",
                        backgroundColor: "transparent",
                        width: "600px",
                    },
                }}>
                <DialogContent
                    style={{ backgroundColor: "#44403C", color: "white",display:"flex",flexDirection:"column",alignItems:"center",padding:"20px 0 0" }}
                >
                    <Box sx={{width:"520px"}}>
                        <h2 style={{ fontFamily: "Nexa-Heavy",fontWeight:900, textAlign: "center", fontSize:"24px" }}>You're already Subscribed!</h2>
                        <Typography style={{ fontFamily: "Nexa-Regular",fontWeight:400,textAlign: "center", fontSize:"20px" }}>Would you like to change your subscription or begin to setup your Advertising Offer preferences?
                        </Typography>
                    </Box>
                    <Box style={{
                        justifyContent: "center",gap:"5%",
                        width: "100%",display: "flex",
                        height: "104px", borderTop: "1px solid white",
                        alignItems: "center", marginTop: "30px"
                    }} id="loginModal">
                            <Button 
                            data-test-id="changeplanBtn"
                            onClick={()=>this.props.handleCommonPath("HostPaymentPlan")}
                            style={{
                                padding:"0 5%",
                                borderRadius: "8px",
                                height: "56px",
                                width: "auto",
                                textTransform: "capitalize",
                                color: "white",
                                backgroundColor: "#FF0807",
                                fontWeight: 900,
                                fontSize: "14px",
                            }}>Change subscription</Button>
                            <Button 
                            data-test-id="setupbtn"
                            style={{
                                height: "56px",
                                width: "auto",
                                padding:"0 5%",
                                borderRadius: "8px",
                                backgroundColor: "#030303",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                color: "white",
                                fontWeight: 900,
                            }}>Setup My Advertising Profile</Button>
                         </Box>
                </DialogContent>
                </Dialog>
            </>
        );
    }

}
const StyledWrapperSignUp = styled("div")({
    background: "black",
    padding: "20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .scrollHostLogin":{
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        height: "34rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
    },
    "& .btn" :{
        cursor : "pointer",
        margin : "auto",
        background : "#FF0807",
        color : "white",
        marginBottom : "5%",
        marginTop:"2%",
        borderRadius : "8px",
        height : "56px",
        width : "auto",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        fontWeight : 900,
        fontSize : "14px",
        fontFamily : "Nexa-Heavy",
        textTransform : "capitalize",
        "&:hover" : {
            background : "#FF0807",        
        }
    },
    "& .desc":{
        fontFamily : "Nexa-Regular",
        fontSize : "16px",
        margin : "auto",
        textAlign : "center", 
    },
    "& .title":{
        textAlign : "center",
        fontSize : "30px",
        color : "white",
        fontFamily : "Nexa-Heavy",
        fontWeight : 900
    },
    "& .subContainer":{
        margin : "auto",
        flexDirection : "column",
        width:"80%",
        height : "auto",
        gap:"15px",
        display:"flex",
        marginTop : "2%"
    },
    "& .container":{
    color : "white",
    height:"auto",
    margin:"auto",
    background:"#44403C",
    },
    "& .image":{
        display:"flex",
        height : "50%",
        width : "80%",
        paddingTop : "5%",
        boxSizing: "content-box",
        margin:"auto",       
    }
})   
// Customizable Area End
