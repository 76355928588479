import React, { Component } from 'react';
import {
    Box,
    Card,
    Typography,
    Avatar,
    IconButton,
    CardContent,
    Divider,
    Menu,
    MenuItem,
    ListItemIcon,
} from "@mui/material";

import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BlockIcon from '@mui/icons-material/Block';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShareDialogue from './ShareDialogue.web';

interface CommentCardProps {
    currentCommentData: any[];
    handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
    anchorEl: HTMLElement | null;
    handleMenuClose: () => void;
    handleSharePopup: (url: string) => void;
    openCommentSharePopup: boolean;
    handleCloseSharePopup: () => void;
    handleCopy: (copylink: string) => void;
    copied: boolean;
    copyUrl: string;
}

class CommentCard extends Component<CommentCardProps> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {
        const { currentCommentData, handleMenuOpen, anchorEl, handleMenuClose } = this.props;

        return (
            <>
                <Box sx={{ display: "flex", flexDirection: "column", width:"100%"}}>
                    {currentCommentData.map((card: any, index: any) => (
                        <Card
                            key={card.id}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: index % 2 === 0 ? "#44403C" : "#312F2D",
                                marginBottom: 2,
                                borderRadius: "20px",
                                padding: "20px",
                                justifyContent: "space-between",
                                overflow: "hidden",
                                cursor: "pointer",
                                position: "relative",
                                width:"96%"
                            }}
                        >
                            <Box display={"flex"} justifyContent={"space-between"} gap={"20px"}>
                                <Box>
                                    <Avatar
                                        src={card.avatar}
                                        alt={card.name}
                                        sx={{ width: "48px", height: "48px" }}
                                    />
                                </Box>
                                <Box display={"flex"} gap={"15px"} flexDirection={"column"}>
                                    <Typography variant="body1" style={style.typographyText}>
                                        {card.name}
                                    </Typography>
                                    <CardContent sx={{ padding: 0 }}>
                                        <Typography
                                            variant="body2"
                                            color="grey"
                                            fontSize={"14px"}
                                            fontFamily={"Nexa-Regular"}
                                            fontWeight={100}
                                        >
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                    <Box
                                        display={"flex"}
                                        gap={"30px"}
                                        alignItems={"center"}
                                        style={style.typographyText}
                                    >
                                        <Typography variant="body2" style={style.typographyText}>
                                            {card.date}
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <ThumbUpAltOutlinedIcon sx={{ marginRight: 1 }} />
                                            <Typography variant="body2" style={style.typographyText}>
                                                {card.likes}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <ChatBubbleOutlineOutlinedIcon sx={{ marginRight: 1 }} />
                                            <Typography variant="body2" style={style.typographyText}>
                                                {card.comments}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <ShareOutlinedIcon data-test-id="shareCopyBtn" onClick={() => {
                                                this.props.handleSharePopup(card.post_url)
                                                }} sx={{ marginRight: 1 }} />
                                            <Typography variant="body2" style={style.typographyText}>
                                                {card.shares}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box position={"absolute"} right={0} marginRight={"20px"}>
                                    <IconButton size="small" sx={{ color: "#fff" }}
                                        aria-label="more options"
                                        onClick={(e) => handleMenuOpen(e)}>

                                        <MoreHorizIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Card>
                    ))}
                </Box>

                <Menu
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                    sx={{
                        "& .MuiPaper-root": {
                            color: "#FFFFFF",
                            backgroundColor: "#44403C",
                            width: "200px",
                            border: "1px solid #ffffff",
                        },
                    }}
                >
                    <Box sx={{
                        marginTop: "-8px",
                        "&:hover": {
                            backgroundColor: "#FF0807",
                        }
                    }}>
                        <MenuItem
                            onClick={handleMenuClose}
                            sx={{ height: "60px" }}
                        >
                            <ListItemIcon>
                                <VisibilityOffIcon style={{ fontSize: "36px", color: "#FFFFFF" }} />
                            </ListItemIcon>
                            <span style={{ marginLeft: "8px", fontSize: "14px" }}>
                                Hide
                            </span>
                        </MenuItem>
                    </Box>
                    <Divider sx={{ borderColor: "#ffffff" }} />
                    <Box sx={{
                        marginBottom: "-8px",
                        "&:hover": {
                            backgroundColor: "#FF0807",
                        }
                    }}>
                        <MenuItem
                            sx={{ height: "60px" }}
                            onClick={handleMenuClose}
                        >
                            <ListItemIcon>
                                <BlockIcon style={{ fontSize: "36px", color: "#FFFFFF" }} />
                            </ListItemIcon>
                            <span style={{ marginLeft: "8px", fontSize: "14px" }}>
                                Block User
                            </span>
                        </MenuItem>
                    </Box>
                </Menu>

                {
                    this.props.openCommentSharePopup && 
                        <ShareDialogue open={this.props.openCommentSharePopup} onClose={this.props.handleCloseSharePopup} copyLink={this.props.copyUrl} handleCopy={this.props.handleCopy} copied={this.props.copied} />
                }
            </>

        );
    }
}
const style = {
    typographyText: {
        fontWeight: 900,
        fontSize: "14px",
        fontFamily: "Nexa-Heavy",
        color: "#fff"
    }
}


export default CommentCard;
