// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { createRef } from "react";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path: string) => void;
}

export interface PodcastDetails {
  podcastCover: string;
  podcastName: string;
  podcastCreator: string;
  description: string;
  website: string;
  podcastLanguage: string;
  podcastCategory: string;
  podcastSubtitle: string;
  podcastOwnerInfo: string;
  podcastKeywords: string;
  podcastContentRating: string;
  podcastFeedRedirection: string;
  podcastSubCategory: string;
};

export interface EpisodeDetails {
  title: string;
  audio_track: string;
  episode_type: number;
  season_number: number;
  episode_number: number;
  publication_date: string;
  unique_id: string;
  episode_length: number;
  content_rating: string;
  description: string;
};

export interface S {
  isLinkValid: boolean | null,
  link: string,
  isValidated: boolean | null,
  imageUrl: string | null;
  invalidFileMessage: string;
  podcastDetails: PodcastDetails;
  episodeDetails: EpisodeDetails[];
  isPlaying: boolean[];
  currentTime: number[];
  duration: number[];
  volume: number[];
  catagoryData: [];
  subCatagoryData: [];
}

export interface SS {
  id: any;
}

export default class HostSetupRssFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchRssFeedCallId: string = "";
  audioRefs: React.RefObject<HTMLAudioElement>[]
  scrollRef: any = createRef();
  fetchCatalogApiCallId: string = "";
  fetchSubCatalogApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.audioRefs = [1, 2, 3, 4].map(() => createRef<HTMLAudioElement>());
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: null,
      link: '',
      isValidated: null,
      imageUrl: null,
      invalidFileMessage: "",
      podcastDetails: {
        podcastCover: '',
        podcastName: '',
        podcastCreator: '',
        description: '',
        website: '',
        podcastLanguage: '',
        podcastCategory: '',
        podcastSubtitle: '',
        podcastOwnerInfo: '',
        podcastKeywords: '',
        podcastContentRating: '',
        podcastFeedRedirection: '',
        podcastSubCategory: ''
      },
      episodeDetails: [],
      isPlaying: Array(4).fill(false),
      currentTime: Array(4).fill(0),
      duration: Array(4).fill(0),
      volume: Array(4).fill(1),
      catagoryData: [],
      subCatagoryData: []
    };
  }

  async componentDidMount() {
    let isLinkValid = await getStorageData("linkValid");
    let isShowInMiddle = await getStorageData("isShowInMeddile");
    let rssData = await getStorageData("rssData");
    const rssLink = JSON.parse(rssData).link;
    let linkValidity = isLinkValid === "true" ? true : isLinkValid === "false" ? false : null;
    this.setState({ isLinkValid: linkValidity });
    if (isShowInMiddle === "HostSetupRssFeedInfo" || isShowInMiddle === "HostSetupRssEpisodeInfo") {
      this.fetchRssFeedApi(rssLink);
    }
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    this.fetchCatagory();
    this.fetchSubCatagory();
  }

  openLink = (url: string) => {
    if (typeof window !== "undefined") {
      window.open(url, "_blank");
    }
  };

  handleGoBack = () => {
    this.props.handleCommonPath("HostSetupRssFeed");
  }

  handleGoBackToEpisode = () => {
    this.props.handleCommonPath("HostSetupRssEpisodeInfo");
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handlefetchRssFeedResponse(from, message);
    let apiCallIdMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.validResponse(apiCallIdMessage, responseJson);
    // Customizable Area End
  }
  fetchRssFeedApi = async (validateLink?: string) => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
      "content-type": "application/json",
    };
    const body = {
      "rss_url": validateLink ? validateLink : this.state.link
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchRssFeedCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchRss
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlefetchRssFeedResponse = async (from: string, message: Message) => {
    if (this.fetchRssFeedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          if (responseJson.error) {
            console.error('RSS Feed validation failed:', responseJson.message);
            this.setState({
              isValidated: false,
            });
          } else if (responseJson.podcast) {
            const podcast = responseJson.podcast;
            const episodeDetails = responseJson.episodes.map((episode: any) => {
              return {
                title: episode.title,
                audio_track: episode.enclosure_url,
                episode_type: 0,
                season_number: 0,
                episode_number: 0,
                publication_date: moment(episode.published).format("MM/DD/YYYY"),
                unique_id: "?",
                episode_length: episode.enclosure_length,
                content_rating: "Explicit",
                description: episode.summary,
              }
            });
            this.setState({
              isValidated: true,
            }, () => {
              this.setState({
                imageUrl: podcast.image_url,
                podcastDetails: {
                  podcastCover: podcast.image_url,
                  podcastName: podcast.podcast_title,
                  podcastCreator: podcast.hosts.map((host: { name: string; email: string }) => host.name).join(", "),
                  description: podcast.description,
                  website: podcast.link,
                  podcastLanguage: podcast.language,
                  podcastSubtitle: podcast.podcast_title,
                  podcastOwnerInfo: podcast.hosts.map((host: { name: string; email: string }) => host.email).join(", "),
                  podcastKeywords: podcast.categories.map((category: { text: string }) => category.text).join(", "),
                  podcastContentRating: podcast.explicit === "yes" ? "Explicit" : "",
                  podcastFeedRedirection: podcast.link,
                  podcastCategory: podcast.categories.map((category: { text: string }) => category.text),
                  podcastSubCategory: podcast.categories.map((category: { text: string }) => category.text),
                },
                episodeDetails
              });
            });
          }
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const link = e.target.value;
    this.setState({ link: link, isValidated: null });
    this.fetchRssFeedApi();
  };
  handleContinue = async () => {
    let storageData = await getStorageData('rssData');
    let rssData = storageData ? JSON.parse(storageData) : {};
    rssData['link'] = this.state.link;
    setStorageData("rssData", JSON.stringify(rssData));
    setStorageData("feedName", "rss");
    setStorageData("linkValid", this.state.isValidated ? "true" : "false");
    try {
      this.props.handleCommonPath("HostSetupRssFeedInfo");
    } catch (error) {
      console.error("Error navigating:", error);
    }
  }

  handleDrop = (fileEvent: React.DragEvent<HTMLDivElement>, setFieldValue: any) => {
    fileEvent.preventDefault();
    const files = Array.from(fileEvent.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFileSelect = (fileEvent: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const files = fileEvent.target.files ? Array.from(fileEvent.target.files) : [];
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFile = (file: File, setFieldValue: any) => {
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (img.width !== 3000 || img.height !== 3000) {
          this.setState({ invalidFileMessage: "Photo is not 3000x3000, please upload new photo of that size to move forward" });
        }
        else {
          this.setState({ invalidFileMessage: "" });
        }
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        this.setState({ imageUrl: base64String });
        img.src = base64String;
        setFieldValue("podcastCover", file);
      };
      reader.readAsDataURL(file);
    };
  };

  updateFeedStorageData = async (values: PodcastDetails) => {
    let storageData = await getStorageData("rssData");
    let data = storageData !== null ? JSON.parse(storageData) : {};
    data["podcastCover"] = values.podcastCover;
    data["podcastName"] = values.podcastName;
    data["hostName"] = values.podcastCreator;
    data["description"] = values.description;
    data["website"] = values.website;
    data["podcastLanguage"] = values.podcastLanguage;
    data["podcastCategory"] = values.podcastCategory;
    data["podcastSubtitle"] = values.podcastSubtitle;
    data["podcastOwnerInfo"] = values.podcastOwnerInfo;
    data["podcastKeywords"] = values.podcastKeywords;
    data["podcastContentRating"] = values.podcastContentRating;
    data["podcastFeedRedirection"] = values.podcastFeedRedirection;
    data["podcastSubCategory"] = values.podcastSubCategory;
    setStorageData("rssData", JSON.stringify(data));
  };

  updateEpisodesStorageData = async (values: { episodes: EpisodeDetails[] }) => {
    let storageData = await getStorageData("rssData");
    let data = storageData !== null ? JSON.parse(storageData) : {};
    data["episodes"] = values.episodes;
    setStorageData("rssData", JSON.stringify(data));
  };

  handleFeedSubmit = (values: PodcastDetails) => {
    this.updateFeedStorageData(values);
    this.props.handleCommonPath("HostSetupRssEpisodeInfo");
  };

  handleGoBackToFeed = () => {
    this.props.handleCommonPath("HostSetupRssFeedInfo");
  };

  convertMillisecondsToTime = (millisecondsString: number) => {
    const totalSeconds = Math.floor(millisecondsString / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  handleEpisodeSubmit = (values: { episodes: EpisodeDetails[] }) => {
    this.updateEpisodesStorageData(values);
    this.props.handleCommonPath("SetupRssFeedPreview");
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, index: number) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFieldValue(`episodes[${index}].audio_track`, fileUrl);
    }
  };

  handleDropFile = (e: React.DragEvent<HTMLDivElement>, setFieldValue: any, index: number) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFieldValue(`episodes[${index}].audio_track`, fileUrl);
    }
  };

  stripHtml = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  handlePlayPause = (index: number) => {
    this.audioRefs.forEach((ref, idx) => {
      const audio = ref?.current;
      if (audio && idx !== index) {
        audio.pause();
      }
    });
    const audio = this.audioRefs[index]?.current;
    if (audio) {
      const isPlaying = !audio.paused;
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      this.setState(_prevState => {
        const isPlaying = [false, false, false, false];
        isPlaying[index] = !isPlaying[index];
        return { isPlaying };
      });
    }
  };
  
  handleRewind = (index: number) => {
    const audio = this.audioRefs[index].current; // Use individual audio ref for each episode
    if (audio) {
      const newTime = Math.max(0, audio.currentTime - 10);
      audio.currentTime = newTime;
      this.setState(prevState => {
        const currentTime = [...prevState.currentTime];
        currentTime[index] = newTime;
        return { currentTime };
      });
    }
  };
  
  handleForward = (index: number) => {
    const audio = this.audioRefs[index].current; // Use individual audio ref for each episode
    if (audio) {
      const newTime = Math.min(this.state.duration[index], audio.currentTime + 10);
      audio.currentTime = newTime;
      // Update the current time for the specific episode
      this.setState(prevState => {
        const currentTime = [...prevState.currentTime];
        currentTime[index] = newTime;
        return { currentTime };
      });
    }
  };
  
  handleTimeUpdate = (index: number) => {
    const audio = this.audioRefs[index].current; // Use individual audio ref for each episode
    if (audio) {
      // Update the current time for the specific episode
      this.setState(prevState => {
        const currentTime = [...prevState.currentTime];
        currentTime[index] = audio.currentTime;
        return { currentTime };
      });
    }
  };
  
  handleLoadedMetadata = (index: number) => {
    const audio = this.audioRefs[index].current; // Use individual audio ref for each episode
    if (audio) {
      // Set the duration for the specific episode
      this.setState(prevState => {
        const duration = [...prevState.duration];
        duration[index] = audio.duration;
        return { duration };
      });
    }
  };
  
  handleVolumeChange = (index: number, value: number | number[]) => {
    const audio = this.audioRefs[index].current; // Use individual audio ref for each episode
    const newVolume = value as number;
    if (audio) {
      audio.volume = newVolume;
      // Update the volume for the specific episode
      this.setState(prevState => {
        const volume = [...prevState.volume];
        volume[index] = newVolume;
        return { volume };
      });
    }
  };
  
  handleSliderChange = (index: number, value: number | number[]) => {
    const audio = this.audioRefs[index].current; // Use individual audio ref for each episode
    if (audio) {
      audio.currentTime = value as number;
      this.setState(prevState => {
        const currentTime = [...prevState.currentTime];
        currentTime[index] = value as number;
        return { currentTime };
      });
    }
  };

  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  apiCall = async (payload: any) => {
    const { contentType, method, endPoint, body } = payload;
    let token = await getStorageData("token");

    let header = {
      token: JSON.parse(token),
      'Content-Type': contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validResponse = (apiCallIdMessage: string, responseJson: any) => {
    if (apiCallIdMessage === this.fetchCatalogApiCallId) {
      this.fetchCatagorySuccessCallBack(responseJson);
    };
    if (apiCallIdMessage === this.fetchSubCatalogApiCallId) {
      this.fetchSubCatagorySuccessCallBack(responseJson);
    };
  }

  fetchCatagory = async () => {
    this.fetchCatalogApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCategoryDropdown,
    });
  };

  fetchCatagorySuccessCallBack = (responseJson: any) => {
    this.setState({ catagoryData: responseJson.data });
  };

  fetchSubCatagory = async () => {
    this.fetchSubCatalogApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getSubCategoryDropdown,
    });
  };

  fetchSubCatagorySuccessCallBack = (responseJson: any) => {
    this.setState({ subCatagoryData: responseJson.data });
  };
}

// Customizable Area End
