import React, { Component } from "react";
import { Card, Box, Typography, Button, Chip,} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import MoodIcon from '@mui/icons-material/Mood';
import { ResolveCondition } from "../../../../blocks/utilities/src/CustomHelpers";

// Defining types for props
interface CardComponentProps {
  sponsoredImage: string;
  title: string;
  subTitle: string;
  episode: string;
  episodeType : string,
  publishDate: string;
  status: string;
  name : string;
  isPremium: boolean;
  onShare: () => void;
  onEdit: () => void;
  onAnalytics: () => void;
  onDelete: () => void;
}

class   MediaManagementCard extends Component<CardComponentProps> {
  render() {
    const {
      sponsoredImage,
      title,
      subTitle,
      name,
      episode,
      publishDate,
      status,
      isPremium,
      episodeType,
      onShare,
      onEdit,
      onAnalytics,
      onDelete,
    } = this.props;

    const buttonStyles = {
      width: "150px", 
      padding: "8px 0",
      fontFamily: "Nexa-Heavy",
      fontSize: "0.875rem",
      textTransform: "capitalize",
      borderRadius: "8px"
    };

    return (
      <Card
        sx={{
          padding: "24px",
          backgroundColor: "#44403C",
          boxShadow: "0px 22px 70px 4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft:"2%",
          marginBottom:"24px"
        }}
        data-test-id='card'
      >
        <Box display={"flex"} gap={"5.3125rem"}>
          <img
            src={sponsoredImage}
            alt="sponsoredImage"
            style={{ objectFit: "cover", height:"120px", width: "120px", borderRadius:"3px" }}
          />
          <Box color={"white"}>
            <Box display={"flex"} gap={20}>
              <Box>
              <Typography
                fontFamily={"Nexa-Heavy"}
                fontSize={"0.875rem"}
                lineHeight={"1.2rem"}
                sx={{
                  paragraph: "2rem",
                }}
              >
                {name}
              </Typography>
              
              <Typography
                fontFamily={"Nexa-Heavy"}
                fontSize={"0.675rem"}
                lineHeight={"1.2rem"}
                sx={{
                  paragraph: "2rem",
                  marginBottom: "8px",
                }}
              >
                {title}
              </Typography>
              </Box>
              {isPremium && (
                <Box
                  data-test-id="premium-label"
                  display="flex"
                  alignItems="center"
                  sx={{
                    background: "linear-gradient(45deg, #FCD34D, #D97706)",
                    color: "black",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    fontFamily:"nexa-haavy",
                    fontSize:"0.625rem",
                    height:"26px",
                    fontWeight:"bold",
                    gap:"8px"
                  }}
                >
                  <StarOutlineIcon />
                  Premium
                </Box>
              )}
              {episodeType === "trailer" && !isPremium ? (
               <Box
               data-test-id="trailer-label"
               display="flex"
               alignItems="center"
               sx={{
                 background: "#FF0807",
                 color: "white",
                 padding: "8px 32px",
                 borderRadius: "16px",
                 fontFamily:"Inter",
                 fontSize:"0.625rem",
                 height:"26px",
                 fontWeight:"bold",
                 gap:"8px"
               }}
             >
               TRAILER
             </Box>) : ""}
             {episodeType === "bonus" && !isPremium ? (
               <Box
               data-test-id="bonus-label"
               display="flex"
               alignItems="center"
               sx={{
                 background: "#FFF",
                 color: "black",
                 padding: "8px 16px",
                 borderRadius: "8px",
                 fontSize:"0.625rem",
                 height:"26px",
                 fontWeight:"bold",
                 gap:"8px"
               }}
             >
              <MoodIcon/>
               Bonus Episode
             </Box>): ""}
            </Box>
            <Typography
              fontFamily={"Nexa-Heavy"}
              fontSize={"0.875rem"}
              lineHeight={"0.75rem"}
              sx={{
                paragraph: "0.875rem",
                marginBottom: "8px",
              }}
            >
              {episode}
            </Typography>
            <Typography
              fontFamily={"Nexa-Regular"}
              fontSize={"0.875rem"}
              lineHeight={"0.75rem"}
              sx={{
                paragraph: "0.875rem",
                marginBottom: "8px",
              }}
            >
              Publish Date: {publishDate}
            </Typography>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography
                fontFamily={"Nexa-Heavy"}
                fontSize={"0.875rem"}
                lineHeight={"0.75rem"}
                sx={{
                  marginBottom: "8px",
                }}
              >
                Status: 
              </Typography>
              <Chip
                data-test-id="status-chip"
                label={status}
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  textTransform: "uppercase",
                  height: "18px",
                  backgroundColor: status.toLowerCase() === "draft" ? "#60A5FA" : status.toLowerCase() === "scheduled" ? "#CBD5E1" : "#D1FAE5", 
                  color: status.toLowerCase() === "draft" ? "#FFFFFF" : status.toLowerCase() === "scheduled" ? "#475569" : "#059669" 
                  // padding: "11px 1px"
                }}
              />
               
            </Box>  

          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={3}>
            <Button
              data-test-id="share-button"
              startIcon={<ShareIcon />}
              sx={{
                ...buttonStyles,
                background: "#312F2D",
                border: "none",
                color: "white",
                boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", 
                "&:hover": {
                  background: "#312F2D",
                  color: "white",
                  border: "none",
                  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", 
                },
              }}
              onClick={onShare}
            >
              Share
            </Button>
            <Button
              data-test-id="edit-button"
              variant="outlined"
              startIcon={<EditIcon />}
              sx={{
                ...buttonStyles,
                background: "white",
                color: "black",
                border: "solid white",
                "&:hover": {
                  background: "white",
                  color: "black",
                  border: "solid white",
                },
              }}
              onClick={onEdit}
            >
              Edit
            </Button>
          </Box>
          <Box display="flex" gap={3}>
            <Button
              data-test-id="analytics-button"
              variant="outlined"
              startIcon={<TrendingUpIcon />}
              sx={{
                ...buttonStyles,
                background: "black",
                border: "none",
                color: "white",
                "&:hover": {
                  background: "black",
                  color: "white",
                  border: "none",
                },
              }}
              onClick={onAnalytics}
            >
              Analytics
            </Button>
            <Button
              data-test-id="delete-button"
              variant="outlined"
              startIcon={<DeleteOutlineIcon />}
              sx={{
                ...buttonStyles,
                color: "white",
                border: "1px solid white",
                "&:hover": {
                  color: "white",
                  border: "1px solid white",
                },
              }}
              onClick={onDelete}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Card>
    );
  }
}

export default MediaManagementCard;
