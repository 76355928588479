import React from "react";
//Customizable Area Start
import ActivityFeedController, {
  Props,
  configJSON,
} from "./ActivityFeedController";
import {
  Box,
  IconButton,
  styled,
  Tabs,
  Tab
} from "@mui/material";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CommentCard from "./components/CommentCard.web";
import ActivityFeedCard from "./components/ActivityFeedCard.web";
import FollowedPodcastersPosts from "./components/FollowedPodcastersPosts.web";
import ListeningStats from "./components/ListeningStats.web";
//Customizable Area End
import { ActivityInterface } from "./dataInterface";

export default class ActivityFeed extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  //Customizable Area Start
  renderActivityList() {
    return this.state.activities.map((activity: ActivityInterface) => {
      return this.renderActivity(activity);
    });
  }

  renderActivity(activity: ActivityInterface) {
    const date = new Date(activity.created_at);
    return (
      <Box sx={style.activityRow} key={`activity${activity.id}`}>
        <span>{activity.trackable_type.split("::")[0]}</span>
        <span>{`${activity.owner.first_name} ${activity.owner.last_name}`}</span>
        <span>{`${date.toDateString()}`}</span>
      </Box>
    );
  }

  renderOptions(opts: string[]) {
    const option = (option: { name: string; value: string }) => (
      <option key={`option-${option.name}`} value={option.value}>
        {option.name}
      </option>
    );

    return [
      option({ name: "All", value: "" }),
      opts.map((optionItem: string) =>
        option({ name: optionItem, value: optionItem })
      ),
    ];
  }

  renderSelect() {
    return (
      <select
        style={style.select}
        onChange={(event) => {
          this.setFilter(event.target.value);
        }}
      >
        {this.renderOptions(this.activityTypes)}
      </select>
    );
  }

  renderActivityFeedSliderDots(onSliderDotClick: any, currentPage: number, allData: any, itemsPerPage: number) {
    const totalPages = Math.ceil(allData?.length / itemsPerPage);

    return (
      <Box display={this.state.showAllDataOfBlock === 'Allblock' ? "flex" : "none"} justifyContent="center" alignItems="center" marginTop={2} >
        {Array.from({ length: totalPages }).map((_, index) => (
          <Box
            key={index}
            onClick={()=>onSliderDotClick(index)}
            sx={{
              width: 10,
              height: 10,
              backgroundColor: currentPage === index ? "#c0c0c0" : "#ffffff",
              borderRadius: "50%",
              marginX: 0.5,
              cursor: "pointer",
              scale: currentPage === index ? 1.2 : 0.8
            }}
          />
        ))}
      </Box>
    );
  }
  //Customizable Area End

  render() {
    // Customizable Area Start
    const totalPages = Math.ceil(this.state.activityFeedData.length / this.itemsPerPage);
    const totalCommentPages = Math.ceil(this.state.allCommentData.length / this.commentItemsPerPage);
    const totalFollowedPostsPage = Math.ceil(this.state.allfollowedPostsData.length / this.FollowedPostsItemsPerPage);
    return (
      <div style={webStyles.container} ref={this.scrollRef}>

          <Box className="listeningStats-block" >
            <Box className="listeningStats-section" display={this.state.showAllDataOfBlock === 'Allblock' ? 'flex' : 'none'}>
              <CustomTypography style={{ marginLeft: "50px" }}>
                Listening Stats
              </CustomTypography>
            </Box>

            <Box display={this.state.showAllDataOfBlock === 'Allblock' ? 'flex' : 'block'} justifyContent={this.state.showAllDataOfBlock === 'Allblock' ? 'start' : 'end'}
              sx={{
                overflow: 'hidden',
                borderRadius: '30px',
                bgcolor: 'black',
                display: 'flex',
                alignItems: "center",
                p: '6px 20px 6px 12px',
              }}
            > <Box sx={{ display: "flex", color: "#FFFFFF", fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 400, marginLeft: "40px" }}>Filter by:</Box>
              <Tabs
                value={this.state.activeMonthBlockTab}
                onChange={this.handleHoursTabChange}
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{
                  '& .MuiTab-root': {
                    minWidth: '89px',
                    textTransform: 'none',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontFamily: "Nexa-Heavy",
                    color: '#334155',
                    height: "32px",
                    margin: "0 10px",
                    background: 'white',
                    borderRadius: '24px',
                    fontWeight: 900,
                  },
                  '& .Mui-selected': {
                    background: 'red',
                    color: 'white !important',
                  },
                }}
              >
                <Tab label="1 Month" />
                <Tab label="3 Months" />
                <Tab label="6 Months" />
                <Tab label={"12 Months"} />
              </Tabs>
            </Box>

           <Box display={this.state.showAllDataOfBlock === 'Allblock' ? 'block' : 'none'}>
           <ListeningStats
              totalListeningTime={this.state.totalListeningTime}
              mostedListendTime={this.state.mostedListendTime}
              averageListeningTime={this.state.averageListeningTime}
              onSavedPostBtnClick={this.handleSavedPostsBtnClick}
              totalPodcastFollowing={this.state.totalFollowing}
              image={this.state.mostedListendImage}
            />
           </Box>
          </Box>
        
          <Box className="activity-block" display={(this.state.showAllDataOfBlock==='Allblock' || this.state.showAllDataOfBlock==='Activity') ? 'block' : 'none'}> 
            <Box className="activity-feed-section" sx={{ display: "flex", }}>
              <IconButton
                sx={{
                  width: "40px",
                  height: "40px",
                  paddingLeft: "15px",
                  borderRadius: "8px",
                  color: "#F5F5F5",
                  background: "#44403C",
                  "&:hover": {
                    background: "#44403C",
                  },
                  display: this.state.showAllDataOfBlock === 'Activity' ? "block" : "none",
                  marginRight: "-30px"
                }}
                onClick={() => this.handleShowAllDataOfBlock('Allblock')}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <CustomTypography style={{ marginLeft: "50px" }}>
                Activity Feed
              </CustomTypography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2px" }}>
              <Box >
                <IconButton
                  sx={{
                    width: "48px",
                    height: "48px",
                    color: "#FFFFFF",
                    background: "#FF0807",
                    "&:hover": {
                      background: "#FF0807",
                    },
                  }}
                  onClick={this.handleSliderPreviousActivityClick}
                  disabled={this.state.currentPage === 0 || this.state.showAllDataOfBlock === 'Activity'}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Box>
              <ActivityFeedCard
                currentActivityData={this.currentActivityData()}
                expandedCardId={this.state.expandedCardId}
                anchorEl={this.state.anchorEl}
                handleCardClick={this.handleCardClick}
                handleMenuOpen={this.handleMenuOpen}
                handleMenuClose={this.handleMenuClose}
              />
              <Box >
                <IconButton
                  sx={{
                    width: "48px",
                    height: "48px",
                    color: "#FFFFFF",
                    background: "#FF0807",
                    "&:hover": {
                      background: "#FF0807",
                    },
                  }}
                  onClick={this.handleSliderNextActivityClick}
                  disabled={this.state.currentPage === totalPages - 1 || this.state.showAllDataOfBlock === 'Activity'}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>

            <Box>
              {this.renderActivityFeedSliderDots(this.handleDotClick, this.state.currentPage, this.state.activityFeedData, this.itemsPerPage)}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{
                fontFamily: "Nexa-Heavy",
                fontSize: "18px",
                fontWeight: 900,
                lineHeight: "26px",
                textAlign: "right",
                color: "#FFFFFF",
                marginRight: "42px",
                marginTop: "18px",
                cursor: "pointer",
                display: this.state.showAllDataOfBlock === 'Allblock' ? "block" : "none",
              }}
                onClick={() => this.handleShowAllDataOfBlock('Activity')}
              >See All Activity</span>
            </Box>
          </Box>

          <Box className="comment-block" display={(this.state.showAllDataOfBlock==='Allblock' || this.state.showAllDataOfBlock==='Comment') ? 'block' : 'none'}>
            <Box display={"flex"} gap={"15px"} alignItems={"center"} marginBottom={"24px"} marginTop={"12px"}>
              <Box className="activity-feed-section" sx={{ display: "flex", }}>
                <IconButton
                  sx={{
                    width: "40px",
                    height: "40px",
                    paddingLeft: "15px",
                    borderRadius: "8px",
                    color: "#F5F5F5",
                    background: "#44403C",
                    "&:hover": {
                      background: "#44403C",
                    },
                    display: this.state.showAllDataOfBlock === 'Comment' ? "block" : "none",
                    marginRight: "-30px"
                  }}
                  onClick={() => this.handleShowAllDataOfBlock('Allblock')}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <CustomTypography style={{ marginBottom: 0, marginLeft: "50px", }}>Comments</CustomTypography>
              </Box>
              <Box sx={{ bgcolor: 'red', padding: "5px", borderRadius: '30px', overflow: 'hidden', display: 'inline-block' }}>
                <Tabs
                  value={this.state.activeCommenBlockTab}
                  onChange={this.handleCommentBlockTabChange}
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  sx={{
                    '& .MuiTab-root': {
                      minWidth: '120px',
                      color: 'white',
                      background: "red",
                      textTransform: 'none',
                      fontSize: '14px',
                      fontWeight: '600',
                      cursor: "pointer"
                    },
                    '& .Mui-selected': {
                      color: 'black !important',
                      background: 'white',
                      cursor: "pointer",
                      borderRadius: "24px",
                    },
                  }}
                >
                  <Tab label="Top Comments" sx={{ fontWeight: 900, fontSize: "14px", fontFamily: "Nexa-Heavy", }} />
                  <Tab label="Recent Comments" sx={{ fontFamily: "Nexa-Heavy", fontSize: "14px", fontWeight: 900, }} />
                </Tabs>
              </Box>
            </Box>

            <Box sx={{ flexDirection: "row", display: "flex", gap: "2px", alignItems: "center" }}>
              <Box >
                <IconButton
                  disabled={this.state.currentCommentPage === 0 || this.state.showAllDataOfBlock === 'Comment'}
                  sx={{
                    background: "#FF0807",
                    color: "#FFFFFF",
                    width: "48px",
                    "&:hover": {
                      background: "#FF0807",
                    },
                    height: "48px",
                  }}
                  onClick={this.handleSliderPreviousCommentClick}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Box>
              <CommentCard
                currentCommentData={this.currentCommentData()}
                handleMenuOpen={this.handleCommentMenuOpen}
                anchorEl={this.state.commentAnchorEl}
                handleMenuClose={this.handleCommentMenuClose}
                handleSharePopup={this.openSharePoup}
                openCommentSharePopup={this.state.openCommentSharePopup}
                handleCloseSharePopup={this.closeSharePopup}
                handleCopy={this.handleCopy}
                copied={this.state.copied}
                copyUrl={this.state.copyLink}
              />

              <Box >
                <IconButton
                  onClick={this.handleSliderNextCommetClick}
                  disabled={this.state.currentCommentPage === totalCommentPages - 1 || this.state.showAllDataOfBlock === 'Comment'}
                  sx={{
                    "&:hover": {
                      background: "#FF0807",
                    },
                    height: "48px",
                    color: "#FFFFFF",
                    background: "#FF0807",
                    width: "48px",
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>

            </Box>

            <Box>
              {this.renderActivityFeedSliderDots(this.handleCommentSliderDotClick, this.state.currentCommentPage, this.state.allCommentData, this.commentItemsPerPage)}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <span
                style={{
                  display: this.state.showAllDataOfBlock === 'Allblock' ? "block" : "none",
                  fontSize: "18px",
                  cursor: "pointer",
                  lineHeight: "26px",
                  color: "#FFFFFF",
                  marginRight: "42px",
                  marginTop: "18px",
                  fontFamily: "Nexa-Heavy",
                  textAlign: "right",
                  fontWeight: 900,
                }}
                onClick={() => this.handleShowAllDataOfBlock('Comment')}
              >See All Comments</span>
            </Box>
          </Box>

          <Box className="followedPosts-block" marginBottom={"230px"}
            display={(this.state.showAllDataOfBlock === 'Allblock' || this.state.showAllDataOfBlock === 'Podcast') ? 'block' : 'none'}>
            <Box marginTop={"45px"} alignItems={"center"} display={"flex"} marginBottom={"24px"} gap={"15px"} >
              <Box sx={{ display: "flex" }} className="followed-post-section">
                <IconButton
                  onClick={() => this.handleShowAllDataOfBlock('Allblock')}
                  sx={{
                    display: this.state.showAllDataOfBlock === 'Podcast' ? "block" : "none",
                    height: "40px",
                    paddingLeft: "15px",
                    marginRight: "-30px",
                    borderRadius: "8px",
                    background: "#44403C",
                    "&:hover": {background: "#44403C"},
                    color: "#F5F5F5",
                    width: "40px",
                  }}
                ><ArrowBackIosIcon /></IconButton>
                <CustomTypography 
                  style={{ 
                    marginBottom: 0, 
                    marginLeft: "50px" 
                  }}>Followed Podcasters Posts
                </CustomTypography>
              </Box>
              <Box sx={{ overflow: 'hidden', borderRadius: '30px', bgcolor: 'red', display: 'inline-block',  padding: "5px" }}>
                <Tabs
                  value={this.state.activeFollowedPostsTab}
                  onChange={this.handleFollowedPostsBlockTabChange}
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  sx={{
                    '& .MuiTab-root': {
                      fontWeight: '600',
                      color: 'white',
                      background: "red",
                      cursor: "pointer",
                      textTransform: 'none',
                      fontSize: '14px',
                      minWidth: '120px',
                    },
                    '& .Mui-selected': {
                      borderRadius: "24px",
                      cursor: "pointer",
                      color: 'black !important',
                      background: 'white',
                    },
                  }}
                >
                  <Tab label="Top Posts" 
                    sx={{ 
                      fontWeight: 900, 
                      fontSize: "14px", 
                      fontFamily: "Nexa-Heavy", 
                      }} 
                    />
                  <Tab label="Recent Posts" 
                    sx={{ fontFamily: "Nexa-Heavy", fontSize: "14px", fontWeight: 900, }} />
                </Tabs>
              </Box>
            </Box>

            <Box sx={{ alignItems: "center",flexDirection: "row", display: "flex", gap: "2px",  }}>
              <Box >
                <IconButton
                  onClick={this.handleSliderPreviousFollowedPostsClick}
                  sx={{
                    color: "#FFFFFF",
                    background: "#FF0807",
                    "&:hover": {background: "#FF0807",},
                    height: "48px",
                    width: "48px",
                  }}
                  disabled={this.state.currentFollowedPostsPage === 0 || this.state.showAllDataOfBlock === 'Podcast'}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Box>

              <FollowedPodcastersPosts
                currentFollowedPostsData={this.currentFollowedPostsData()}
                handleFollowedPostsMenuOpen={this.handleFollowedPostsMenuOpen}
                handleFollowedPostsCardClick={this.handleFollowedPostsCardClick}
                followedPostsAnchorEl={this.state.followedPostsAnchorEl}
                handleFollowedPostsMenuClose={this.handleFollowedPostsMenuClose}
                expandedFollowedPostsCardId={this.state.expandedFollowedPostsCardId}
                handleSharePopup={this.openPodcastSharePoup}
                openPodcastSharePopup={this.state.openPodcastSharePopup}
                handleCloseSharePopup={this.closePodcastSharePoup}
                copied={this.state.copied}
                handleCopy={this.handleCopy}
                copyUrl={this.state.copyPodcastShareLink}
              />
              <Box >
                <IconButton
                  onClick={this.handleSliderNextFollowedPostsClick}
                  sx={{
                    "&:hover": {
                      background: "#FF0807",
                    },
                    height: "48px",
                    color: "#FFFFFF",
                    background: "#FF0807",
                    width: "48px",
                  }}
                  disabled={this.state.currentFollowedPostsPage === totalFollowedPostsPage - 1 || this.state.showAllDataOfBlock === 'Podcast'}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>

            </Box>

            <Box>
              {this.renderActivityFeedSliderDots(this.handleFolloewdPostsSliderDotClick, this.state.currentFollowedPostsPage, this.state.allfollowedPostsData, this.FollowedPostsItemsPerPage)}
            </Box>

            <Box sx={{  justifyContent: "flex-end", display: "flex", }}>
              <span
                onClick={() => this.handleShowAllDataOfBlock('Podcast')}
                style={{
                  fontWeight: 900,
                  cursor: "pointer",
                  lineHeight: "26px",
                  color: "#FFFFFF",
                  marginRight: "42px",
                  marginTop: "18px",
                  display: this.state.showAllDataOfBlock === 'Allblock' ? "block" : "none",
                  textAlign: "right",
                  fontFamily: "Nexa-Heavy",
                  fontSize: "18px",
                }}
              >See All Followed Podcasters Posts</span>
            </Box>
          </Box>

          <Box className="savedPosts-block"
            marginBottom={"107px"}
            display={this.state.showAllDataOfBlock === 'SavedPosts' ? 'block' : 'none'}
          >
            <Box className="savedPosts-section" sx={{ display: "flex", }}>
              <IconButton
                sx={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "8px",
                  paddingLeft: "15px",
                  background: "#44403C",
                  color: "#F5F5F5",
                  "&:hover": {
                    background: "#44403C",
                  },
                  marginRight: "-30px"
                }}
                onClick={() => this.handleShowAllDataOfBlock('Allblock')}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <CustomTypography sx={{ marginLeft: "50px" }}>
                All Saved Post
              </CustomTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                flexDirection: "row",
              }}>
              <FollowedPodcastersPosts
                currentFollowedPostsData={this.state.allSavedPostData}
                expandedFollowedPostsCardId={this.state.allSavedPostexpandedCardId}
                followedPostsAnchorEl={this.state.allSavedPostAnchorEl}
                handleFollowedPostsMenuOpen={this.handleAllSavedPostMenuOpen}
                handleFollowedPostsCardClick={this.handleAllSavedCardClick}
                handleFollowedPostsMenuClose={this.handleAllSavedPostMenuClose}
                handleSharePopup={this.openPodcastSharePoup}
                openPodcastSharePopup={this.state.openPodcastSharePopup}
                handleCloseSharePopup={this.closePodcastSharePoup}
                copied={this.state.copied}
                handleCopy={this.handleCopy}
                copyUrl={this.state.copyPodcastShareLink}
              />
            </Box>
            <Box>
              {this.renderActivityFeedSliderDots(this.handleDotClick, this.state.currentPage, this.state.activityFeedData, this.itemsPerPage)}
            </Box>
          </Box>

          <Footer navigation={this.props.navigation} id={"1"} />
      </div>
    );
  }
  //Customizable Area End
}

// Customizable Area Start

const CustomTypography = styled(Box)({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
  marginBottom: "24px",
  fontFamily: "Nexa-Heavy",
});

const webStyles = {
  container: {
    padding: "20px 50px",
  } as const,
}
const style = {
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: 16,
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  header: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    fontSize: 24,
  },
  activityRow: {
    padding: 16,
    borderWidth: 1,
    borderColor: "#ccc",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  activityList: {
    overflowY: "scroll",
    height: "calc(100vh - 200px)",
  },
  loadMore: {
    cursor: "pointer",
  },
  select: {
    width: 100,
    height: 30,
    border: "1px solid #ccc",
  },
  typographyText: {
    fontWeight: 900,
    fontSize: "14px",
    fontFamily: "Nexa-Heavy",
    color: "#fff"
  }
};
//Customizable Area End
