import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { podcastImage1, podcastImage2 } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  screenId: string;
  handleCommonPath: (path: string) => void;
  handleAddAudio : (playable_type: string, playable_id: string) => void;
}

interface ChannelLibraryData {
  episodeId: string;
  name: string;
  description: string;
  cover: string | null;
  episode_content: string | null;
  added: boolean;
  saved: boolean;
  savedId: number;
}

interface S {
    channelLibraryList: ChannelLibraryData[];
}

interface SS {
  screenId: string;
}

export default class ChannelLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
    unSavedEpisodeApiCallId: string = "";
    addToMainPlayListAPIId: string = "";
    removeFromMainPlayListAPIId: string = "";
    getChannelLibraryApiCallId: string = "";
    saveEpisodeApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        channelLibraryList: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    
    if(apiRequestCallId === this.getChannelLibraryApiCallId) {
      this.handlePodcastlistResponse(from, message)
    }

    if(apiRequestCallId === this.unSavedEpisodeApiCallId) {
      this.unsavedEpisodeResponse(from, message);
    }
    if(apiRequestCallId === this.addToMainPlayListAPIId) {
      this.addToMainPlayListResponse(from, message);
    }
    if(apiRequestCallId === this.removeFromMainPlayListAPIId) {
      this.removeToMainlaylistResponse(from, message)
    }

    if(apiRequestCallId === this.saveEpisodeApiCallId) {
      this.handleResForSaveEpisodeFromChannelLibrary(from, message)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getPodCastList();
  }

  getPodCastList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChannelLibraryApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let payloadToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/podcasts/followed_podcast_episodes`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: payloadToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    // this.setState({channelLibraryListLoading: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePodcastlistResponse = async (from: string, message: Message)  => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse.data.length >  0) {
      const episodes = apiResponse.data.map((item: any) => {
        const attributes = item.attributes;
          return {
              name: attributes.name,
              description: `${attributes.account?.first_name || ''} ${attributes.account?.last_name || ''}`.trim(),
              cover: attributes.cover,
              episode_content: attributes.episode_content,
              added: attributes.added.song_added,
              saved: attributes.save_episode.saved,
              savedId: attributes.save_episode.saved_id,
              episodeId: item.id
          };
        });
      this.setState({channelLibraryList: episodes})
    } 
    else {
      this.setState({channelLibraryList: []});
    }
  }

  async handleRemoveSave(id: number) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );

    this.unSavedEpisodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unsavedEpisodeResponse = async(from: string, message: Message) => {
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response.message == "Successfully Unsaved") {
      this.getPodCastList()  
    }
  }

  addToMainPlayList = async(id: string) => {

    let getToken = await getStorageData("token");
    let playListId = await getStorageData("main_playlist_id");

    const header = {
      token: JSON.parse(getToken),
    };

    let formdata = new FormData();
    formdata.append("song_id", `${id}`);
    formdata.append("id", playListId);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToMainPlayListAPIId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToPlayList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addToMainPlayListResponse = async(from: string, message: Message) => {
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response.message == "Song added successfully") {
      this.getPodCastList();                  
    }
  }

  removeFromMainPlayList = async(id:string) => {
    let mainPlaylistId = await getStorageData("main_playlist_id");
    let token = await getStorageData("token");

    const header = {
      token: JSON.parse(token),
    };

    let formdata = new FormData();
    formdata.append("id", mainPlaylistId);
    formdata.append("song_id", `${id}`);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFromMainPlayListAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromPlayList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeToMainlaylistResponse = async(from: string, message: Message) => {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (response.message === "Song removed successfully") {
      this.getPodCastList(); 
    }
  }

  async handleAddToSaveLater(id: string) {
    let token = await getStorageData("token");

    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveEpisodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForSaveEpisodeFromChannelLibrary = async(from: string, message: Message) => {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (response.meta.message === "Successfully Saved") {
      this.getPodCastList(); 
    }
  };
}