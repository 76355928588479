import React from "react";
// Customizable Area Start
import {
    Grid,
    IconButton,
    Typography,
    Box,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { addBtn, heart, addedBtn, selectedBtn } from "../../../dashboard/src/assets";
import Footer from "../../../navigationmenu/src/Footer.web";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import TrendingEpisodeController, {
    Props,
} from "./TrendingEpisodeController.web";
// Customizable Area End

export default class TrendingEpisode extends TrendingEpisodeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <div style={webStyles.container} ref={this.scrollRef}>
                <Box sx={{ display: "flex" }} >
                    <IconButton
                        sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "8px",
                            paddingLeft: "15px",
                            color: "#F5F5F5",
                            background: "#44403C",
                            marginRight: "-30px",
                            "&:hover": {
                                background: "#44403C",
                            },
                        }}
                        data-test-id="backBtn"
                        onClick={() => this.props.handleCommonPath("SubCategories")}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Box sx={webStyles.containerTitle}>Discover</Box>
                </Box>

                <Box sx={webStyles.tredingEpisodesBox} data-test-id="trendingEpisode">
                    <Typography style={webStyles.title}>Trending Episodes</Typography>
                    <Grid container spacing={3}>
                        {this.state.trendingEpisode.map((episode, index) => (
                            <Grid item lg={2.4} md={2.4} sm={4} xs={12} key={episode.id}>
                                <div style={webStyles.suggestedImgBody}>
                                    <img style={{ ...webStyles.image, height: "223px" }} src={episode.cover} alt={"image"} />
                                    {episode.saved ?
                                        <img src={selectedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "10px" }} onClick={() => this.handleUnSaveEpisode(episode.savedId)} /> :
                                        <img src={heart} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "10px" }} onClick={() => this.handleSaveEpisode(episode.id)} />
                                    }
                                    {episode.added ?
                                        <img src={addedBtn} alt="EpisodeAdded" style={{ position: "absolute", right: "10px", top: "50px" }} onClick={() => this.handleRemoveFromPlayList(episode.id)} /> :
                                        <img src={addBtn} alt="unAddEpisode" style={{ position: "absolute", right: "10px", top: "50px" }} onClick={ () => this.handleAddToPlayList(episode.id)} />
                                    }

                                    <Box data-test-id="audioCard" onClick={() => this.props.handleAddAudio("BxBlockLogin::Episode", episode.id)}  sx={{position:"absolute", left: "50%", top:"30%", transform: "translateX(-50%)"}} >
                                        <PlayCircleOutlineIcon sx={{color:"white", width:"70px", height:"70px"}} />
                                    </Box>

                                    <div style={webStyles.description}>
                                        <Box sx={{
                                            height: "76px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "5px 16px",
                                        }}>
                                            <Box>
                                                <Typography sx={{ ...webStyles.episode, fontWeight: "900" }}>
                                                    {episode.name}
                                                </Typography>
                                                <Typography sx={{ ...webStyles.episode, fontWeight: "900" }}>
                                                    {`${episode.firstName} ${episode.lastName}`}
                                                </Typography>
                                            </Box>
                                            <Typography style={{ ...webStyles.episode, fontWeight: "900", fontSize: "24px" }}>
                                                #<span style={{ fontSize: '32px' }}>{index + 1}</span>
                                            </Typography>
                                        </Box>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Footer navigation={this.props.navigation} id={"1"} />
            </div>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const webStyles = {
    container: {
        padding: "20px 50px",
        overflowX: "hidden" as const,
        overflowY: "auto" as const,
        maxHeight: "100%",
    },
    img: {
        width: "40%",
        height: "40%",
    },
    tredingEpisodesBox: {
        paddingTop: "40px",
        position: 'relative',
    },
    containerTitle: {
        marginLeft: "50px",
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        marginBottom: "24px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy",
    },
    title: {
        marginBottom: "10px",
        fontWeight: 900,
        fontSize: "30px",
        color: '#fff',
        fontFamily: "Nexa-Heavy",
    },
    image: {
        margin: "0 auto",
        width: '100%',
        height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
        display: "flex",
        objectFit: "cover" as const,
        borderRadius: "8px 8px 0 0",
    },
    episode: {
        fontSize: '14px',
        color: "#fff",
        fontWeight: 600,
        lineHeight: '22px',
        fontFamily: "Nexa-Heavy",
    },
    suggestedImgBody: {
        position: 'relative' as const,
        width: '270px',
    },
    description: {
        borderBottomLeftRadius: "16px",
        backgroundColor: "#44403c",
        borderBottomRightRadius: "16px",
    },
};
// Customizable Area End
