Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.loginEndPoint = "bx_block_login/logins";
exports.getCategoryDropdown = "bx_block_categories/categories";
exports.getSubCategoryDropdown = "bx_block_categories/sub_categories";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.fetchSubsPlan = "bx_block_custom_user_subs/subscriptions";
exports.fetchRss = "bx_block_login/fetch_rss";
exports.monetizationCategories = "bx_block_admin/monetization_categories";
exports.getmonetizationData = "bx_block_login/monetizations"
exports.sponserFeature = "bx_block_admin/sponser_features";
exports.subscription_tiers = "bx_block_admin/subscription_tiers";
exports.createPodcast = "bx_block_login/podcasts";
exports.hereLink = "https://www.podcasttown.net"
exports.createMonitization="bx_block_login/monetizations"
exports.updateMonitization="bx_block_login/monetizations";
exports.patchAPiMethod = "PATCH";
exports.deleteAPiMethod = "DELETE";
exports.getRole = "account_block/accounts/become_host";
exports.deleteMonetize = "bx_block_login/monetizations";
exports.userDetails ="account_block/accounts/profile";
exports.countryApiEndPoint = "bx_block_stripe_integration/all_countries";

// Customizable Area End