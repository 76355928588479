import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import  { SelectChangeEvent } from '@mui/material/Select';
import { podcastImage1, podcastImage2 } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  screenId: string;
  handleCommonPath: (path:string) => void
  handlePodcastId: (id: string) => void
}

interface PodcastList {
    id?: string,
    imageUrl: string;
    songName: string;
    artist: string;
    following: boolean;
    podcastId: string;
  }

interface S {
  podcastName: string;
  followingPodCasts: PodcastList[];
}

interface SS {
  screenId: string;
}

interface ValidResponse {
  data: [
    {
      id: string,
      type: string,
      attributes: {
        followable_type: string,
        followable_id: string,
        followable: {
          data: {
            id: string,
            type: string,
            attributes: {
              name: string,
              description: string,
              subtitle: string,
              cover_image: string,
              sub_categories: [
                {
                  id: string,
                  name: string
                }
              ],
              follower: {
                following: boolean,
                follow_id: string
              }
            }
          }
        }
      }
    }
  ]
}

export default class PodCastsFollowingController extends BlockComponent<
  Props,
  S,
  SS
> {
    podcastFollowingId : string = "";
    followersEpisodeApiCallId : string = "";
    unFollowPodcastCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      podcastName: "Podcasts I'm Following",
      followingPodCasts : [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleChange = (event: SelectChangeEvent<string | number>) => {
    this.setState({ podcastName: event.target.value as string });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId == this.followersEpisodeApiCallId) {
      this.followersPoscastFetchSuccessCallBack(responseJson)
    }

    if(apiRequestCallId === this.unFollowPodcastCallId) {
      this.handleResForUnFollowPodcast(from, message)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.handleFollowersPodcastFetch()
  }

  handleFollowersPodcastFetch = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followersEpisodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followersPodcast
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  followersPoscastFetchSuccessCallBack = async (responseJson: ValidResponse) => {
    const data = responseJson.data;
    const each = data.map((item: {id: string, attributes:{followable:{data:{id: string, attributes:{hosts?: any, name:string, cover_image:string,description:string,follower:{following:boolean}}}}}}) => {
      let mainItem = item.attributes.followable.data.attributes;
      let name = mainItem.name;
      let image = mainItem.cover_image;
      let following = mainItem.follower.following;
      let description = mainItem.description;
      const host = item.attributes.followable.data.attributes?.hosts[0]
      
      return ({
        id: item.id,
        imageUrl: image,
        artist: `${host?.first_name} ${host?.last_name}`,
        songName: name,
        following: following,
        podcastId: item.attributes.followable.data.id
      });
    });
    this.setState({ followingPodCasts: each });
  }

  handleUnFollowPodcast = async(id:string | undefined) =>{
    let token = await getStorageData("token");

    const header = {
      token: JSON.parse(token),
    };
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unFollowPodcast}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    this.unFollowPodcastCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForUnFollowPodcast  = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiResponse.message == "Successfully Unfollowed") {
      this.handleFollowersPodcastFetch();                  
    }
  };

}