export const podcastImage = require("../assets/product_card_small_item_gen.png").default;
export const Image_dots = require("../assets/image_dots.png").default;
export const tshirtIcon = require("../assets/tshirtIcon.png").default;
export const recommended_episode_1 = require("../assets/image3.png").default;
export const recommended_episode_2 = require("../assets/image4.png").default;
export const recommended_episode_3 = require("../assets/image5.png").default;
export const ad_1 = require("../assets/image1.png").default;
export const ad_2 = require("../assets/image2.png").default;
export const logo = require("../assets/logo.svg").default;
export const messageLogo = require("../assets/image_message.svg").default;
export const imageEyeoff = require("../assets/image_eye-off.svg").default;
export const imageBlock = require("../assets/image_block.svg").default;
export const imageDelete = require("../assets/image_delete.svg").default;
export const imageEdit = require("../assets/image_edit.svg").default;
export const goBack = require("../assets/goBack.svg").default;



