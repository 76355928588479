import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  styled,
  Box,
  Grid
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { template1, template2, template3, template4 } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
// Customizable Area End
import ChooseTemplateController, { Props } from "./ChooseTemplateController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ChooseTemplate extends ChooseTemplateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const templates = [
      { id: 1, name: 'Audio Focused', imageSrc: template1 },
      { id: 2, name: 'Video Focused', imageSrc: template2 },
      { id: 3, name: 'Audio + Community Focused', imageSrc: template3 },
      { id: 4, name: 'Video + Community Focused', imageSrc: template4 },
    ];
    return (
      // Customizable Area Start
      <Box ref={this.scrollRef}>
        <StyledWrapperSetting>
          <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "96px", fontWeight: "bold", fontFamily: 'Nexa-Heavy' }}>
            Choose a Template
          </Typography>
          <Grid container spacing={4} style={{width: "98%", marginLeft: "3%"}}>
            {templates.map((template) => (
              <Grid item xs={12} sm={6} md={3} key={template.id}>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ mt: 2, color: 'white', fontFamily: 'Nexa-Heavy' }}
                >
                  {template.name}
                </Typography>
                <div
                  style={{
                    border: this.state.selectedTemplate === template.name ? '5px solid red' : 'none',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    width: '90%',
                    margin: '0 auto',
                    height: '85%'
                  }}
                >
                  <img
                    src={template.imageSrc}
                    alt={template.name}
                    style={{
                      width: '103%',
                      height: 'auto',
                      objectFit: 'cover',
                      display: 'block',
                      borderRadius: '8px',
                    }}
                    onClick={() => this.handleTemplateClick(template.name)}
                    data-test-id="template"
                  />
                </div>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ textAlign: 'center', mt: "4%", ml: "5%" }}>
            <Button
              variant="contained"
              disabled={!this.state.selectedTemplate}
              className="continueButtn"
              onClick={this.handleContinue}
              data-test-id="continueBtn"
            >
              Continue
            </Button>
          </Box>
        </StyledWrapperSetting>
        <Footer navigation={this.props.navigation} id={"1"} />
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  padding:"20px 50ps",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .continueButtn": {
    backgroundColor: '#FF0807',
    borderRadius: '8px',
    color: '#fff',
    textTransform: 'capitalize',
    width: '20rem',
    height: '3.5rem',
    fontFamily: 'Nexa-Heavy',
    marginBottom: "2%",
    "&:disabled": {
      backgroundColor: "#B2B3B3",
      color: '#fff',
    },
    "&:hover": {
      backgroundColor: '#FF0807',
      borderColor: '#FF0807'
    }
  }
});

// Customizable Area End
