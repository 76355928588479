import React, { Component } from 'react';
import { Box, Typography, Button, Dialog, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


interface DeletePlayListProps {
    open: boolean;
    close: () => void;
    isLoading: boolean;
    handleSave: () => void;
}

class DeletePlayList extends Component<DeletePlayListProps> {
    constructor(props: DeletePlayListProps) {
      super(props);
    }
  
    render() {
        return (
            <Dialog                        
                open={this.props.open}
                onClose={() => {}}
                slotProps={{
                backdrop: {
                    sx: {
                    backdropFilter: 'blur(5px)', // Apply blur effect
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
                }}
                sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "800px",
                    width: "600px",
                    background: "#44403C",
                    borderRadius: '8px 8px 32px 8px',
                },
                }}
                data-test-id="deleteModal"
            >
                <Box onClick = {this.props.close} display={"flex"} justifyContent={"flex-end"} padding={3}>
                    <CloseIcon sx={{color:"white"}} />
                </Box>
                <Typography sx={{...webStyles.title, fontSize:"24px", paddingLeft:"30px"}}>
                    Are you sure want to delete this playlist?
                </Typography>
                <Box m={2} display={"flex"} gap={2} justifyContent={"flex-end"}>
                <Button
                    data-test-id="cancel_deletePlaylist"
                    onClick={this.props.close}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                    >
                        No
                </Button>

                <Button
                    data-test-id="delete_saveBtn"
                    onClick={this.props.handleSave}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807" ,
                        height:"56px",
                        "&:hover": {
                            background:  "#FF0807" ,
                        },
                        }}
                        variant="contained"
                    >
                        {this.props.isLoading ? "Deleting...." : "Yes"}
                </Button>
        </Box> 
            </Dialog>
        )
    }
}

export default DeletePlayList;

const webStyles = {
    container: {
      padding:"20px 50px",
      overflowX: "clip",
      overflowY: "auto",
      maxWidth: "100%",
    } as const,
    title : {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    }
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    height: "34px",
};