// Customizable Area Start
import React from "react";
import {Box ,styled , Select  , FormControl, Button, TextField,RadioGroup,Radio,FormControlLabel
  , MenuItem, Switch , Grid, IconButton,Dialog, DialogContent, Divider, DialogActions,Slider
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditEpisodeController, {
Props ,
EditCategories,
configJSON ,
} from "./EditEpisodeController.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { uploadRedIcon ,podcastCover,tickIcon } from "./assets";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
import { ConditionJsx } from "../../../blocks/utilities/src/ConditionJsx";
import { Close } from '@mui/icons-material';
import AudiotrackIcon from '@mui/icons-material/Audiotrack'; 
import MovieIcon from '@mui/icons-material/Movie';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PauseIcon from "@mui/icons-material/Pause";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// Customizable Area End

export default class EditEpisode extends EditEpisodeController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <Box sx={{ maxHeight: "100%", overflowY: "auto" }}>
            <StyleWrapperEditEpisode>
             <Box className="mainTitle">{configJSON.editText}</Box>
             <Box>
               <Box className="redContainer"/>
               <Box className="container">
                  <Box className="accordionContainer">
                  <StyleWrapperAccordion 
                      data-test-id="accordionContainerID"
                      onClick={()=>this.toggleAccordionId(configJSON.generalInfoId)}
                      key={configJSON.generalInfoId}
                      elevation={0}
                      disableGutters
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${configJSON.generalInfoId}-content`}
                      id={`${configJSON.generalInfoId}-header`}
                    >
                      <Typography data-test-id="accordion_text_id" component="span">{configJSON.generalText}</Typography>
                    </AccordionSummary>
                  </StyleWrapperAccordion>
                    {ConditionJsx(this.state.accordionIds.includes(configJSON.generalInfoId),
              <Box
                sx={{
                  padding: "10px 20px",
                }}
              >
              <StyleWrapperGeneralText>
                <Box
                  data-test-id="drop"
                  sx={{
                    position: 'relative',
                    margin: "auto",
                    marginTop: "2%",
                    marginBottom: "2%",
                    width: "100%",
                    height: "247px",
                    border: `2px dashed ${ResolveCondition(this.state.data.fileerror , 'red' , '#ccc')}`,
                    borderRadius: '10px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                    >
                      <Box sx={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        backgroundColor: "red",
                        padding: "10px",
                        borderRadius: "8px",
                        cursor: "pointer"
                      }} >
                        <DeleteOutlineIcon sx={{ color: "white" }} />
                      </Box>
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        style={{ display: 'none', padding: "16.5px 14px" }}
                        id="file-input"
                        data-test-id="selectFile"
                        onChange={this.handleFileSelect}
                        
                      />
                     {this.state.data.selectedFile && (
                              <div>
                                <div
                                  style={{
                                    width: '100%',
                                    height: "50%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    display: "flex",
                                    maxWidth: '450px',
                                    borderRadius: '8px',
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: ' translate(-50%, -50%)',
                                    backgroundColor: "#030303",
                                    padding: '10px',
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    {this.state.data.selectedFile.name.endsWith('.mp3') ? (
                                      <AudiotrackIcon style={{ color: 'red' }} />
                                    ) : (
                                      <MovieIcon style={{ color: 'blue' }} />
                                    )}
                                    <span
                                      style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        color: 'white',
                                      }}
                                    >
                                      {this.getShortFileName(this.state.data.selectedFile.name)}
                                    </span>
                                  </div>
                                  <CloseIcon
                                      data-test-id="clearFileSelection"
                                      onClick = {this.clearFileSelection}
                                    sx={{
                                      color: 'white',
                                      cursor: 'pointer',
                                      marginBottom: "auto"
                                    }}
                                  />
                                </div>
                              </div>
                            )}
              
                <Box sx={{ marginTop: "5%", lineHeight: 2 }}>
                  {!this.state.data.imageUrl ? (
                    <>
                      <img src={uploadRedIcon} width="22" alt="upload" />
                      <StyleWrapperBrowseTitle>
                        {configJSON.dragDropAudioText} <br />OR
                      </StyleWrapperBrowseTitle>
                      <StyleWrapperBrowseButton>
                        <label htmlFor = "file-input">
                        {configJSON.browserFileText}
                        </label>
                      </StyleWrapperBrowseButton>
            {this.state.data.fileerror && <div style={{
              color: "red",
              fontFamily: "Nexa-Heavy",
              fontWeight: 400,
              fontSize: "12px",
              marginBottom: "15px",
              padding:"10px"
            }}>{configJSON.uploadAudioVideoText}</div>}
                    </>
                  ) : null}
                </Box>
            </Box>
                  <Box
                    sx={{
                      marginBottom: "30px",
                      padding: "16px 100px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#000",
                      color: "#fff",
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#F8FAFC", fontFamily: "Inter", fontSize: "14px", fontWeight: 700 }}>
                      {this.formatTime(this.state.currentTime)}
                    </Typography>
                    <audio
                      ref={this.audioRefs}
                      src={this.state.audio_track}
                      onTimeUpdate={this.handleTimeUpdate}
                      onLoadedMetadata={this.handleLoadedMetadata}
                    />
                    <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 4,
                          marginBottom: 1,
                        }}
                      >
                        <IconButton data-test-id="rewindBtn"  onClick={this.handleRewind} sx={{ color: "#fff" }}>
                          <Replay10Icon />
                        </IconButton>
                        <IconButton
                          data-test-id="playPauseBtn"
                          onClick={this.handlePlayPause}
                          sx={{
                            color: "#fff",
                            backgroundColor: true ? "red" : "#fff",
                            "&:hover": { backgroundColor: true ? "darkred" : "" },
                          }}
                        >
                          {this.state.isPlaying ? (
                            <PauseIcon sx={{ color: "black"}} />
                          ) : (
                            <PlayArrowIcon sx={{ color: "black" }} />
                          )}
                            
                        </IconButton>
                        <IconButton data-test-id="forwardBtn" onClick={this.handleForward}  sx={{ color: "#fff" }}>
                          <Forward10Icon />
                        </IconButton>
                      </Box>
                     
                      <Slider
                        data-test-id="episodeSlider"
                        value={this.state.currentTime}
                        max={this.state.duration}
                        onChange={this.handleSliderChange}
                        sx={{
                          color: "red",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ color: "#F8FAFC", fontFamily: "Inter", fontSize: "14px", fontWeight: 700 }}>
                        {this.formatTime(this.state.duration)}
                      </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "50px" }}>
                      <VolumeUpIcon sx={{ color: "#fff", marginRight: 1 }} />
                      <Slider
                        data-test-id="volumeSlider"
                        value={this.state.volume}
                        min={0}
                        max={1}
                        step={0.1}
                        onChange={this.handleVolumeChange}
                        sx={{
                          width: 100,
                          color: "white",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "white",
                          },
                        }}
                      />
                    </Box>
                  </Box>
            <Box>
              <label className="label">{configJSON.episodeTitleText}<span>*</span></label>
            </Box>
            <TextField
                id="outlined-basic"
                data-test-id="episodeTitle"
                name="episodeTitle"
                required
                defaultValue={this.state.data.episodeTitle}
                onChange={(event) => this.handleDataChange('episodeTitle', event.target.value)}
                sx={{
                  width: "954px",
                  height:"56px",
                  marginTop: "2%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "&:hover fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#CBD5E1",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#CBD5E1",
                  },
                  "& .MuiInputBase-input": {
                    color:"#FFFFFF", fontSize:"14px",
                    fontFamily:"Nexa-Heavy", fontWeight:900, 
                    backgroundColor: "black", borderRadius: "8px",
                    "&:-webkit-autofill": {
                      backgroundColor: "black !important",
                      WebkitBoxShadow: "0 0 0 1000px black inset",
                      WebkitTextFillColor: "#CBD5E1 !important",
                    },
                  },
                }}
              />
              <Box style={{ marginTop: "3%" }}>
                <label className="label">{configJSON.episodeShowNotesText}<span>*</span></label>
              </Box>
              <TextField
                id="outlined-basic"
                name="episodeShowNotes"
                required
                data-test-id = "episodeShowNotes"
                defaultValue={this.state.data.episodeShowNotes}
                onChange={(event) => this.handleDataChange('episodeShowNotes', event.target.value)}
                multiline
                sx={{
                  width: "100%",
                  height: "177px",
                  marginTop: "2%",
                  "& .MuiOutlinedInput-root": {
                    height: "177px",
                    backgroundColor: "black",
                    boxSizing: "border-box",
                    alignItems: "flex-start",
                    padding: "0",
                    "& fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "&:hover fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#CBD5E1",
                    },
                  },
                  "& .MuiInputBase-root": {
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-input": {
                    color:"#FFFFFF", fontSize:"14px",
                    fontFamily:"Nexa-Heavy", fontWeight:900, 
                    padding: "10px",
                    lineHeight: "1.5",
                    height: "100%",
                    boxSizing: "border-box",
                    maxHeight: "177px",
                    overflowY: "auto",
                    display: "block",
                    "&:-webkit-autofill": {
                      backgroundColor: "black !important",
                      WebkitBoxShadow: "0 0 0 1000px black inset",
                      WebkitTextFillColor: "#CBD5E1 !important",
                    },
                  },
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                <Box sx={{ width: "39%", marginTop: "3%", height: "1.4375em" }}>
                  <label className="label">{configJSON.episodeTypeText}<span>*</span></label>
                  <RadioGroup
                    id="outlined-basic"
                    row
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue = {this.state.data.episodeType}
                    onChange={(event) => this.handleDataChange("episodeType" , event.target.value)}
                    name="episodeType"
                    data-test-id = "episodeType"
                    sx={{ marginTop: "2%", background: "black", gap: "40px", paddingLeft: '18px', borderRadius: "8px", alignItems: "center" }}
                  >
                    <FormControlLabel
                      value="normal"
                      control={<Radio
                        defaultChecked={this.state.data.episodeType === 'normal'}
                        sx={{
                        margin: "0px",
                        "&:before": {
                          content: '""',
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          border: "2px solid #CBD5E1",
                          background: "black",

                        },
                        "&.Mui-checked:before": {
                          content: '""',
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          border: "3px solid red",
                          backgroundColor: "white",

                        },
                        "& .css-hyxlzm": { display: "none" }

                      }} />}
                      label="Normal"
                      sx={{ color: "#CBD5E1", paddingLeft: '5px', alignItems: "center", fontFamily: "Nexa-Regular", marginRight: "0px", height: "55px" }}
                      componentsProps={{
                        typography: {
                          sx: {
                            paddingTop: '3px',
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="trailer"
                      control={<Radio
                        defaultChecked={this.state.data.episodeType === 'trailer'}
                        sx={{
                        "&:before": {
                          content: '""',
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          border: "2px solid #CBD5E1",
                          background: "black",

                        },
                        "&.Mui-checked:before": {
                          content: '""',
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          border: "3px solid red",
                          backgroundColor: "white",
                        }, "& .css-hyxlzm": { display: "none" }
                      }} />}
                      label="Trailer"
                      sx={{ color: "#CBD5E1", padding: '5px', alignItems: "center", fontFamily: "Nexa-Regular" }}
                      componentsProps={{
                        typography: {
                          sx: {
                            paddingTop: '3px',
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="bonus"
                      control={<Radio
                        defaultChecked={this.state.data.episodeType === 'bonus'}
                        sx={{
                        "&:before": {
                          content: '""',
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          border: "2px solid #CBD5E1",
                          background: "black",

                        },
                        "&.Mui-checked:before": {
                          content: '""',
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          border: "3px solid red",
                          backgroundColor: "white",

                        }, "& .css-hyxlzm": { display: "none" }
                      }} />}
                      label="Bonus"
                      sx={{ color: "#CBD5E1", paddingRight: '5px', alignItems: "center", fontFamily: "Nexa-Regular" }}
                      componentsProps={{
                        typography: {
                          sx: {
                            paddingTop: '3px',
                          },
                        },
                      }}
                    />
                  </RadioGroup>
                </Box>
                <Box sx={{
                  width: "25%",
                  marginTop: "3%"
                }}>
                  <label className="label">{configJSON.seasonNumberText}</label>
                  <TextField
                    id="outlined-basic"
                    name="seasonNumber"
                    type="number"
                    data-test-id = "seasonNumber"
                    defaultValue={this.state.data.seasonNumber}
                    onChange={(event) => this.handleDataChange('seasonNumber', event.target.value)}
                    sx={{
                      marginTop: "2%",
                      width: "100%",
                      background:"#030303",
                      "& .MuiInputLabel-root": {
                        color: "#CBD5E1",
                      },
                      "& .MuiInputBase-input": {
                        color:"#FFFFFF", fontSize:"14px",
                        fontFamily:"Nexa-Heavy", fontWeight:900, 
                        backgroundColor: "black",
                        borderRadius: "8px !important",
                        "&:-webkit-autofill": {
                          backgroundColor: "black !important",
                          WebkitBoxShadow: "0 0 0 1000px black inset",
                          WebkitTextFillColor: "#CBD5E1 !important",
                        },
                      },

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#CBD5E1",
                        },
                        height: "56px",
                        "&:hover fieldset": {
                          borderColor: "#CBD5E1",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#CBD5E1",
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{
                  width: "25%",
                  marginTop: "3%"
                }}>
                  <label className="label">{configJSON.episodeNumberText}</label>
                  <StyleWrapperTextField
                    id="outlined-basic"
                    name="episodeNumber"
                    data-test-id = "episodeNumber"
                    type="number"
                    defaultValue={this.state.data.episodeNumber}
                    onChange={(event) => this.handleDataChange('episodeNumber', event.target.value)}
                  />
                </Box>
              </Box>
            </StyleWrapperGeneralText>
          </Box>
      ,  <></>)}
                    <StyleWrapperAccordion 
                      data-test-id="accordionContainerID"
                      onClick={()=>this.toggleAccordionId(configJSON.editPublishExpirationId)}
                      key={configJSON.editPublishExpirationId}
                      elevation={0}
                      disableGutters
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${configJSON.editPublishExpirationId}-content`}
                      id={`${configJSON.editPublishExpirationId}-header`}
                    >
                      <Typography data-test-id="accordion_text_id" component="span">{configJSON.publishExpirationText}</Typography>
                    </AccordionSummary>
                  </StyleWrapperAccordion>
                    {ConditionJsx(this.state.accordionIds.includes(configJSON.editPublishExpirationId), (<Box className="accordionDetail">
                      <Box
                        sx={{
                          padding: "10px 20px",
                          paddingTop: "2rem"
                        }}
                      >
                       <Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "30px",
                          }}
                        >
                          <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                          }}
                            >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              style={{
                                color: "white",
                                fontSize: "16px",
                                fontFamily: "Nexa-Heavy",
                              }}
                            >
                                {configJSON.setepisodeExpirationText}
                              <Switch
                                data-test-id="episodeExploration"
                                name="episodeExploration"
                                onChange={(e) => this.handleDataChange("episodeExploration" , e.target.checked)}
                                color="default"
                                sx={{
                                    marginLeft: "2%",
                                    width: "80px",
                                    height: "50px",
                                    marginTop: "2%",
                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                        transform: "translateX(16px)",
                                        color: "#fff",
                                    },
                                    "& .MuiSwitch-switchBase": {
                                        color: "#fff",
                                        position: "absolute",
                                        top: "6px",
                                        left: ResolveCondition(this.state.data.episodeExploration, "18px", "8px"),
                                    },
                                    "& .MuiSwitch-thumb": {
                                        width: "20px",
                                        height: "20px",
                                        backgroundColor: "#fff",
                                    },
                                    "& .MuiSwitch-track": {
                                        backgroundColor: ResolveCondition(this.state.data.episodeExploration, "rgb(255, 8, 7)", "#ccc"),
                                        opacity: ResolveCondition(!this.state.data.episodeExploration, "0.5 ", "1 !important"),
                                        borderRadius: "16px",
                                    },
                                }}
                            />
                            </Box>
                            <Box>
                              <Box style={{
                                    color: "gray",
                                    fontSize: "16px",
                                    fontFamily: "Nexa-Heavy",
                                  }}>
                                  {configJSON.whenEpisodeExpireText}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  padding: "10px 0",
                                }}
                              >
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className=""
                                      style = {{color : "gray" }}
                                      sx={{ fontSize: "0.75rem", fontWeight: "900" }}
                                    >
                                      Day
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    disabled = {!this.state.data.episodeExploration}
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                          borderRadius: "8px"
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="expireDay"
                                      defaultValue={this.state.data.episodeExpiryDay}
                                      onChange={(e) => this.handleDataChange("episodePublishDay" , e.target.value)}
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="dayBtn"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          sx={{ color: "#FFFFFF" }}
                                          {...props}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: "#44403C",
                                            maxHeight: 250,
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            overflowY: "auto",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" id="day">
                                        DD
                                      </MenuItem>
                                      {Array.from({ length: this.getMaxDaysInMonth(Number(this.state.data.episodeExpiryMonth), Number(this.state.data.episodeExpiryYear))}, (_, i) => (
                                        <MenuItem 
                                        key={i + 1} 
                                        value={i + 1}
                                        id="day"
                                        >
                                          {i + 1}
                                        </MenuItem>))}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Box 
                                  sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      id="month"
                                      className=""
                                      style = {{color : "gray" }}
                                      sx={{ fontSize: "0.75rem", fontWeight: "900" }}
                                    >
                                      Month
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    disabled = {!this.state.data.episodeExploration}
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                          borderRadius: "8px"
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="expireMonth"
                                      value={this.state.data.episodeExpiryMonth}
                                      onChange={(e) => this.handleDataChange("episodeExpiryMonth" , e.target.value)}
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="monthBtn"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          sx={{ color: "#FFFFFF", opacity: ResolveCondition(this.state.data.episodeExploration, "1", "0") }}
                                          {...props}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 250,
                                            backgroundColor: "#44403C",
                                            "& .MuiMenuItem-root": {
                                              fontSize: "0.875rem",
                                              color: "#FFFFFF",
                                            },
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            overflowY: "auto",
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="">
                                        MM
                                      </MenuItem>
                                      {Array.from({ length: 12 }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }} id="Year">
                                    <Typography
                                      variant="body2"
                                      className=""
                                      style = {{color :"gray" }}
                                      sx={{ fontSize: "0.75rem", fontWeight: "900" }}
                                    >
                                      Year
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    disabled = {!this.state.data.episodeExploration}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                          borderRadius: "8px"
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="expireYear"
                                      value={this.state.data.episodeExpiryYear}
                                      onChange={(e) => this.handleDataChange("episodeExpiryYear" , e.target.value)}
                                      data-test-id="yearBtn"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF" }}
                                        />
                                      )}
                                      style={{ width: "138px", background: "black" }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            backgroundColor: "#44403C",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ 
                                        color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" 
                                      id="year"> 
                                        YYYY
                                      </MenuItem>
                                      {Array.from({ length: 2035 - 2025 + 1 }, (_, i) => (
                                        <MenuItem key={2025 + i} value={2025 + i}
                                        id="year"                                        
                                        >
                                          {2025 + i}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box>
                              <Box>
                                <label
                                  style={{
                                    color: "white",
                                    fontFamily: "Nexa-Heavy",
                                    fontSize: "16px",
                                  }}
                                >
                                  {configJSON.publishDateText}
                                </label>
                              </Box>
                              <Box
                                sx={{
                                  gap: "20px",
                                  padding: "10px 0",
                                  display: "flex",
                                }}
                              >
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem", color: "#fff", fontWeight: "900" }}
                                    >
                                      Day
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    disabled = {this.state.data.episodeExploration}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                          borderRadius: "8px"
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishDay"
                                      displayEmpty
                                      value={this.state.data.episodePublishDay}
                                      onChange={(e) => this.handleDataChange("episodePublishDay" , e.target.value)}
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="dayBtn2"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF", opacity: ResolveCondition(!this.state.data.episodeExploration, "1", "0") }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            backgroundColor: "#44403C",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled 
                                      id="epday">
                                        DD
                                      </MenuItem>
                                      {Array.from({ length: this.getMaxDaysInMonth(Number(this.state.data.episodePublishMonth), Number(this.state.data.episodePublishYear)) }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1} 
                                        id="epday">
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem", color: "#fff", fontWeight: "900" }}
                                    >
                                      Month
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    disabled = {this.state.data.episodeExploration}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          fontSize: "0.875rem",
                                          color: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishMonth"
                                      displayEmpty
                                      value={this.state.data.episodePublishMonth}
                                      onChange={(e) => this.handleDataChange("episodePublishMonth" , e.target.value)}
                                      style={{ width: "138px", background: "black" }}
                                      data-test-id="monthBtn2"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF", opacity: ResolveCondition(!this.state.data.episodeExploration, "1", "0") }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: "#44403C",
                                            overflowY: "auto",
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            maxHeight: 250,
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        MM
                                      </MenuItem>
                                      {Array.from({ length: 12 }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }} id="test">
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem", color: "#fff", fontWeight: "900" }}
                                    >
                                      Year
                                    </Typography>
                                  </Box>
                                  <FormControl
                                  id="expireepisodeYear"
                                    disabled = {this.state.data.episodeExploration}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          fontSize: "0.875rem",
                                          color: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishYear"
                                      displayEmpty
                                      value={this.state.data.episodePublishYear}
                                      onChange={(e) => this.handleDataChange("episodePublishYear" , e.target.value)}
                                      data-test-id="yearBtn2"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF", opacity: ResolveCondition(!this.state.data.episodeExploration, "1", "0") }}
                                        />
                                      )}
                                      style={{ width: "138px", background: "black" }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            overflowY: "auto",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            backgroundColor: "#44403C",
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            maxHeight: 250,
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        YYYY
                                      </MenuItem>
                                      {Array.from({ length: 2035 - 2025 + 1 }, (_, i) => (
                                        <MenuItem key={2025 + i} value={2025 + i}>
                                          {2025 + i}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  padding: "10px 0",
                                }}
                              >
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem", color: "#fff", fontWeight: "900" }}
                                    >
                                      Hour
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    fullWidth
                                    disabled = {this.state.data.episodeExploration}
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                          borderRadius: "8px"
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                  >
                                    <Select
                                      name="publishHour"
                                      displayEmpty
                                      value={this.state.data.episodePublishHour}
                                      onChange={(e) => this.handleDataChange("episodePublishHour" , e.target.value)}
                                      style={{ width: "216px", background: "black" }}
                                      data-test-id="publishHr"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF", opacity: ResolveCondition(!this.state.data.episodeExploration, "1", "0") }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            "& .MuiMenuItem-root": {
                                              color: "#FFFFFF",
                                              fontSize: "0.875rem",
                                            },
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                            overflowY: "auto",
                                            backgroundColor: "#44403C",
                                            maxHeight: 250,
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        HH
                                      </MenuItem>
                                      {Array.from({ length: 24 }, (_, i) => (
                                        <MenuItem key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Box sx={{ marginBottom: "8px" }}>
                                    <Typography
                                      variant="body2"
                                      className="label"
                                      sx={{ fontSize: "0.75rem", color: "#fff", fontWeight: "900" }}
                                      >
                                      Min
                                    </Typography>
                                  </Box>
                                  <FormControl
                                    disabled = {this.state.data.episodeExploration}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& fieldset": {
                                          borderColor: "#FFFFFF",
                                        },
                                        "& .MuiSelect-select": {
                                          color: "#FFFFFF",
                                          fontSize: "0.875rem",
                                          borderRadius: "8px"
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "transparent",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                      },
                                    }}
                                    >
                                    <Select
                                      name="publishMinute"
                                      displayEmpty
                                      value={this.state.data.episodePublishMin}
                                      onChange={(e) => this.handleDataChange("episodePublishMin" , e.target.value)}
                                      style={{ width: "216px", background: "black" }}
                                      data-test-id="monthBtn3"
                                      IconComponent={(props) => (
                                        <KeyboardArrowDownIcon
                                          {...props}
                                          sx={{ color: "#FFFFFF", opacity: ResolveCondition(!this.state.data.episodeExploration, "1", "0") }}
                                        />
                                      )}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            maxHeight: 250,
                                            overflowY: "auto",
                                            "& .MuiMenuItem-root": {
                                              fontSize: "0.875rem",
                                              color: "#FFFFFF",
                                            },
                                            backgroundColor: "#44403C",
                                            "& .Mui-selected": {
                                              backgroundColor:
                                                "#333333 !important",
                                            },
                                          },
                                        },
                                      }}
                                      sx={{ color: "#FFFFFF" }}
                                    >
                                      <MenuItem value="" disabled>
                                        MM
                                      </MenuItem>
                                      {Array.from({ length: 31 }, (_, i) => (
                                        <MenuItem key={i} value={i+1}>
                                          {i+1}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        </Box>
                    </Box>
                  </Box>), <></>)}
                  <StyleWrapperAccordion 
                      data-test-id="accordionContainerID"
                      onClick={()=>this.toggleAccordionId(configJSON.premiumExplicitId)}
                      key={configJSON.premiumExplicitId}
                      elevation={0}
                      disableGutters
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${configJSON.premiumExplicitId}-content`}
                      id={`${configJSON.premiumExplicitId}-header`}
                    >
                      <Typography data-test-id="accordion_text_id" component="span">{configJSON.premiumExplicitText}</Typography>
                    </AccordionSummary>
                  </StyleWrapperAccordion>
                    {ConditionJsx(this.state.accordionIds.includes(configJSON.premiumExplicitId), (<Box className="accordionDetail">
                      <Box
                        sx={{
                          padding: "0 20px",
                          paddingTop: "1.5rem"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "baseline",
                            marginInline: "auto",
                            columnGap: "20px",
                            width: "90%",
                            "&>*": {
                              flexGrow: "1",
                              maxWidth: "47%",
                              alignItems: "start"
                            }
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              "&>*:first-child": {
                                marginTop: "0.2rem",
                              }
                            }}
                          >
                            <label
                              style={{
                                color: "white",
                                fontSize: "16px",
                                fontFamily: "Nexa-Heavy",
                              }}
                            >
                              {configJSON.doyouWantPremiumText}
                            </label>
                            <Switch
                              data-test-id="premiumEpisode"
                              checked = {this.state.data.isPremiumEpisode}
                              name="isPremiumEpisode"
                              onChange={(event) => this.handleDataChange('isPremiumEpisode', event.target.checked)}
                              color="default"
                              sx={{
                                  marginLeft: "2%",
                                  width: "80px",
                                  height: "50px",
                                  marginTop: "2%",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                      transform: "translateX(16px)",
                                      color: "#fff",
                                  },
                                  "& .MuiSwitch-switchBase": {
                                      color: "#fff",
                                      position: "absolute",
                                      top: "6px",
                                      left: ResolveCondition(this.state.data.isPremiumEpisode, "18px", "8px"),
                                  },
                                  "& .MuiSwitch-thumb": {
                                      width: "20px",
                                      height: "20px",
                                      backgroundColor: "#fff",
                                  },
                                  "& .MuiSwitch-track": {
                                      backgroundColor: ResolveCondition(this.state.data.isPremiumEpisode, "rgb(255, 8, 7)", "#ccc"),
                                      opacity: ResolveCondition(!this.state.data.isPremiumEpisode, "0.5 ", "1 !important"),
                                      borderRadius: "16px",
                                  },
                              }}
                            />
                          </Box>
                          <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            "&>*:first-child": {
                              marginTop: "0.2rem",
                            }
                          }}
                        >
                            <label
                              style={{
                                color: "white",
                                fontSize: "16px",
                                fontFamily: "Nexa-Heavy",
                              }}
                            >
                              {configJSON.doesContainExpText}
                            </label>
                            <Switch
                              data-test-id="explicitContent"
                              checked = {this.state.data.isEpisodeContainExplicit}
                              name="isEpisodeContainExplicit"
                              onChange={(event) => this.handleDataChange('isEpisodeContainExplicit', event.target.checked)}
                              color="default"
                              sx={{
                                  marginLeft: "2%",
                                  width: "80px",
                                  height: "50px",
                                  marginTop: "2%",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                      transform: "translateX(16px)",
                                      color: "#fff",
                                  },
                                  "& .MuiSwitch-switchBase": {
                                      color: "#fff",
                                      position: "absolute",
                                      top: "6px",
                                      left: ResolveCondition(this.state.data.isEpisodeContainExplicit, "18px", "8px"),
                                  },
                                  "& .MuiSwitch-thumb": {
                                      width: "20px",
                                      height: "20px",
                                      backgroundColor: "#fff",
                                  },
                                  "& .MuiSwitch-track": {
                                      backgroundColor: ResolveCondition(this.state.data.isEpisodeContainExplicit, "rgb(255, 8, 7)", "#ccc"),
                                      opacity: ResolveCondition(!this.state.data.isEpisodeContainExplicit, "0.5 ", "1 !important"),
                                      borderRadius: "16px",
                                  },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              "&>*:first-child": {
                                marginTop: "0.2rem",
                              }
                            }}
                          >
                            <label
                              style={{
                                color: "white",
                                fontSize: "16px",
                                fontFamily: "Nexa-Heavy",
                              }}
                            >
                              {configJSON.restricted18Text}
                            </label>
                            <Switch
                              data-test-id="isEpisodeAdultOnly"
                              checked = {this.state.data.isEpisodeAdultOnly}
                              name="isEpisodeAdultOnly"
                              onChange={(event) => this.handleDataChange('isEpisodeAdultOnly', event.target.checked)}
                              color="default"
                              sx={{
                                  marginLeft: "2%",
                                  width: "80px",
                                  height: "50px",
                                  marginTop: "2%",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                      transform: "translateX(16px)",
                                      color: "#fff",
                                  },
                                  "& .MuiSwitch-switchBase": {
                                      color: "#fff",
                                      position: "absolute",
                                      top: "6px",
                                      left: ResolveCondition(this.state.data.isEpisodeAdultOnly, "18px", "8px"),
                                  },
                                  "& .MuiSwitch-thumb": {
                                      width: "20px",
                                      height: "20px",
                                      backgroundColor: "#fff",
                                  },
                                  "& .MuiSwitch-track": {
                                      backgroundColor: ResolveCondition(this.state.data.isEpisodeAdultOnly, "rgb(255, 8, 7)", "#ccc"),
                                      opacity: ResolveCondition(!this.state.data.isEpisodeAdultOnly, "0.5 ", "1 !important"),
                                      borderRadius: "16px",
                                  },
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      </Box>), <></>)}

                    <StyleWrapperAccordion 
                      data-test-id="accordionContainerID"
                      onClick={()=>this.toggleAccordionId(configJSON.advertisingId)}
                      key={configJSON.advertisingId}
                      elevation={0}
                      disableGutters
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${configJSON.advertisingId}-content`}
                        id={`${configJSON.advertisingId}-header`}
                      >
                        <Typography data-test-id="accordion_text_id" component="span">{configJSON.advertisingText}</Typography>
                     </AccordionSummary>
                    </StyleWrapperAccordion>
                    {ConditionJsx(this.state.accordionIds.includes(configJSON.advertisingId), (<Box className="accordionDetail">
                      <Box
                        sx={{
                          padding: "10px 20px"
                        }}
                      >
                        <StyleWrapperFlexPairBox2 sx={{marginBottom: '90px'}}>
                          <Box sx={{ width: "39%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              "&>*:first-child": {
                                marginTop: "0.2rem",
                              }
                            }}
                          >
                            <StyleWrapperLabel>
                                {configJSON.adsEpisodeText}
                            </StyleWrapperLabel>
                            <Switch
                              data-test-id="isAdsInEpisode"
                              checked = {this.state.data.isAdsInEpisode}
                              name="isAdsInEpisode"
                              color="default"
                              sx={{
                                  marginLeft: "2%",
                                  width: "80px",
                                  height: "50px",
                                  marginTop: "2%",
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                      transform: "translateX(16px)",
                                      color: "#fff",
                                  },
                                  "& .MuiSwitch-switchBase": {
                                      color: "#fff",
                                      position: "absolute",
                                      top: "6px",
                                      left: ResolveCondition(this.state.data.isAdsInEpisode, "18px", "8px"),
                                  },
                                  "& .MuiSwitch-thumb": {
                                      width: "20px",
                                      height: "20px",
                                      backgroundColor: "#fff",
                                  },
                                  "& .MuiSwitch-track": {
                                      backgroundColor: ResolveCondition(this.state.data.isAdsInEpisode, "rgb(255, 8, 7)", "#ccc"),
                                      opacity: ResolveCondition(!this.state.data.isAdsInEpisode, "0.5 ", "1 !important"),
                                      borderRadius: "16px",
                                  },
                              }}
                            />
                          </Box>
                          </Box>
                          <StyleWrapperFlexPairBox>
                            <StyleWrapperLabel>
                              <label>{configJSON.whatTypeAdsEpisode}<span>*</span></label>
                            </StyleWrapperLabel>
                            <StyleWrapperRadioGroup
                              id="outlined-basic"
                              row
                              aria-labelledby="radio-buttons-group-label"
                              name="adsType"
                            >
                              <StyleWrapperFormControlLabel
                                value="audio"
                                control={<StyleWrapperRadio />}
                                checked={this.state.data.adsType === 'audio'}
                              
                                label="Audio"
                                componentsProps={{
                                  typography: {
                                    sx: {
                                      paddingTop: '3px',
                                    },
                                  },
                                }}
                              />
                              <StyleWrapperFormControlLabel
                                value="video"
                                control={<StyleWrapperRadio />}
                                checked={this.state.data.adsType === 'video'}
                               
                                label="video"
                                componentsProps={{
                                  typography: {
                                    sx: {
                                      paddingTop: '3px',
                                    },
                                  },
                                }}
                              />
                              <StyleWrapperFormControlLabel
                                value="both"
                                control={<StyleWrapperRadio />}
                                checked={this.state.data.adsType === 'both'}
                              
                                label="Both"
                                componentsProps={{
                                  typography: {
                                    sx: {
                                      paddingTop: '3px',
                                    },
                                  },
                                }}
                              />
                            </StyleWrapperRadioGroup>
                          </StyleWrapperFlexPairBox>
                      </StyleWrapperFlexPairBox2>
                      <StyleWrapperFlexPair>
                        <StyleWrapperFlexPairBox>
                            <StyleWrapperLabel>{configJSON.adsCountInPreRollText}</StyleWrapperLabel>
                            <StyleWrapperTextField
                              id="outlined-basic-adsCountInPreRoll"
                              name="adsCountInPreRoll"
                              type="number"
                              defaultValue={this.state.data.adsCountInPreRoll}
                              
                            />
                        </StyleWrapperFlexPairBox>
                        <StyleWrapperFlexPairBox>
                            <StyleWrapperLabel>{configJSON.adsCountInPostRollText}</StyleWrapperLabel>
                            <StyleWrapperTextField
                              id="outlined-basic-adsCountInPostRoll"
                              name="adsCountInPostRoll"
                              type="number"
                              defaultValue={this.state.data.adsCountInPostRoll}
                              
                            />
                        </StyleWrapperFlexPairBox>
                      </StyleWrapperFlexPair>
                      <StyleWrapperFlexPair>
                        <StyleWrapperFlexPairBox>
                            <StyleWrapperFlex>
                              <StyleWrapperLabel>{configJSON.acceptPreRollText}</StyleWrapperLabel>
                              <StyleWrapperRedButton>View All</StyleWrapperRedButton>
                            </StyleWrapperFlex>
                            <StyleWrapperAdsCarousel>
                              <StyleWrapperAdsBox />
                              <StyleWrapperAdsBox />
                            </StyleWrapperAdsCarousel>
                        </StyleWrapperFlexPairBox>
                        <StyleWrapperFlexPairBox>
                            <StyleWrapperFlex>
                              <StyleWrapperLabel>{configJSON.acceptPostRollText}</StyleWrapperLabel>
                              <StyleWrapperRedButton>View All</StyleWrapperRedButton>
                            </StyleWrapperFlex>
                            <StyleWrapperAdsCarousel>
                              <StyleWrapperAdsBox />
                              <StyleWrapperAdsBox />
                            </StyleWrapperAdsCarousel>
                        </StyleWrapperFlexPairBox>
                      </StyleWrapperFlexPair>
                      <StyleWrapperFlexPair>
                        <StyleWrapperFlexPairBox>
                            <StyleWrapperLabel>{configJSON.whichAdsInsertPreRollText}</StyleWrapperLabel>
                            <StyleWrapperWhiteBigButton>{configJSON.viewInvOptText}</StyleWrapperWhiteBigButton>
                            <StyleWrapperWarningText>{configJSON.warningMessagePreRollText}</StyleWrapperWarningText>
                        </StyleWrapperFlexPairBox>
                        <StyleWrapperFlexPairBox>
                              <StyleWrapperLabel>{configJSON.whichAdsInsertPostRollText}</StyleWrapperLabel>
                            <StyleWrapperWhiteBigButton>{configJSON.viewInvOptText}</StyleWrapperWhiteBigButton>
                            <StyleWrapperWarningText>{configJSON.warningMessagePreRollText}</StyleWrapperWarningText>
                        </StyleWrapperFlexPairBox>
                      </StyleWrapperFlexPair>
                      </Box>
                      </Box>), <></>)
                    }

                    <StyleWrapperAccordion 
                      data-test-id="accordionContainerCoverID"
                      onClick={()=>this.toggleAccordionId(configJSON.coverPhotoId)}
                      key={configJSON.coverPhotoId}
                      elevation={0} disableGutters
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${configJSON.coverPhotoId}-content`}
                        id={`${configJSON.coverPhotoId}-header`}
                      >
                        <Typography data-test-id="accordion_text_id" component="span">{configJSON.coverText}</Typography>
                      </AccordionSummary>
                  </StyleWrapperAccordion>
                    {ConditionJsx(this.state.accordionIds.includes(configJSON.coverPhotoId), (<Box className="accordionDetail">
                    <Box
                      sx={{
                        padding: "10px 20px"
                      }}
                      >
                        <StyleWrapperFlexPairBox2
                          sx={{
                            width: "95%",
                            marginInline: "auto"
                          }}
                        >
                        <StyleWrapperFlexColumn
                          sx={{
                            width: "80%",
                            gap: "16px"
                          }}
                        >
                          <StyleWrapperCoverHeading>{configJSON.currentEpisodeCoverText}</StyleWrapperCoverHeading>
                            <StyleWrapperFlexColumn>
                              <PodcastCoverTextImage >
                                <img src={this.state.contentepisodeData.cover ?? podcastCover} style={{ width: "237px", height: "229px" }} />
                                <div>
                                  <CoverPhotoText style={{fontSize:'30px' }}>{this.state.contentepisodeData?.name}</CoverPhotoText>
                                  <CoverPhotoText  style={{fontSize:'40px'}}>{this.state.contentepisodeData?.description}</CoverPhotoText>
                                </div>
                              </PodcastCoverTextImage>
                            
                          </StyleWrapperFlexColumn>
                          <Typography style={{ color: "#fff", fontSize: "12px", lineHeight: "22px", fontFamily: "Nexa-Regular" }}>Podcast Runtime: 58:25</Typography>
                        </StyleWrapperFlexColumn>
                        <StyleWrapperFlexColumnEnter>
                          <StyleWrapperCoverHeading>{configJSON.newEpisodeCoverText}</StyleWrapperCoverHeading>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                              {ConditionJsx((this.state.data.invalidFileMessage === "" && this.state.data.imageUrl !== ""), <Box sx={{ position: 'relative', width: '165px', border: "1px solid white", height: "165px", borderRadius: "8px" }}>
                                <img src={this.state.data.imageUrl} alt="Preview" style={{ width: '150px', height: '150px', borderRadius: '5px', padding: "17px", margin: "4%" }} />
                                <IconButton
                                 onClick={() => this.setState({data : {...this.state.data , imageUrl : ""}})}
                                  sx={{ position: 'absolute', top: "11%", right: "10%", color: '#FFFFFF' }}
                                  data-test-id="logo-url"
                                >
                                  <Close />
                                </IconButton>
                              </Box>, <></>
                              )}
                              <StyleWrapperDropBoxPhoto
                                sx={{
                                  marginTop: ResolveCondition((this.isImageValid()), "2%", "7%"),
                                  width: ResolveCondition((this.isImageValid()), "338px", "300px"),
                                  height: ResolveCondition((this.isImageValid()), "205px", "300px"),
                                  border: '2px dashed',
                                  borderColor: ResolveCondition((this.state.data.invalidFileMessage !== ""), "#FF0807", "#F5F5F5"),
                                }}
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  multiple
                                  style={{ display: 'none', padding: "16.5px 14px" }}
                                  id="file-input-new"
                                  data-test-id= "file-image-input-cover"
                                  onChange = {(e) => this.handleFileChapter(e)}
                                />
                                <Box sx={{ marginTop: "5%", lineHeight: 2 }}>
                                  <img src={uploadRedIcon} width={24} alt="" />
                                    <StyleWrapperBrowseTitle>
                                      <StyleWrapperLabel>{configJSON.dragDropPhotoText}</StyleWrapperLabel>
                                      <br />
                                      <StyleWrapperLabel>OR</StyleWrapperLabel>
                                    </StyleWrapperBrowseTitle>
                                    <StyleWrapperBrowseButton>
                                    <label htmlFor="file-input-new">
                                      {configJSON.browserFileText}
                                    </label>
                                    </StyleWrapperBrowseButton>
                                </Box>
                              </StyleWrapperDropBoxPhoto>
                            </Box>
                          <StyleWrapperDropCaption>{configJSON.dropCaptionText}</StyleWrapperDropCaption>
                        </StyleWrapperFlexColumnEnter>
                        </StyleWrapperFlexPairBox2>
                      </Box>
                      </Box>) , <></>)}
                  <StyleWrapperAccordion 
                      data-test-id="accordionContainerChapterID"
                      onClick={()=>this.toggleAccordionId(configJSON.chaptersId)}
                      key={configJSON.chaptersId}
                      elevation={0}
                      disableGutters
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${configJSON.chaptersId}-content`}
                      id={`${configJSON.chaptersId}-header`}
                    >
                      <Typography data-test-id="accordion_text_id_chapters" component="span">{configJSON.chaptersText}</Typography>
                    </AccordionSummary>
                  </StyleWrapperAccordion>
                  {ConditionJsx(this.state.accordionIds.includes(configJSON.chaptersId), (<Box className="accordionDetail" sx={{backgroundColor: "transparent !important"}}>
                    <Box
                    data-add={this.state.data.additionalPhotos}
                    sx={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "column"
                    }}
                    >
                      <StyleWrapperChapterHeading sx={{marginInline: '45px'}}>{configJSON.chaptersText}:</StyleWrapperChapterHeading>
                      <Box style={{ display: "flex", marginInline: '45px' }}>
                        <Typography style={{ color: "#fff", fontSize: "16px", lineHeight: "22px", fontFamily: "Nexa-Heavy", maxWidth: "65%", marginTop: "1%", textTransform: 'capitalize' }}>{configJSON.insertPhotosText} </Typography>
                        <Switch
                            data-test-id="additionalPhotos"
                            name="additionalPhotos"
                            onChange={this.toggleAdditionalPhotos}
                            color="default"
                            sx={{
                                marginLeft: "2%",
                                width: "80px",
                                height: "50px",
                                marginTop: "2%",
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                    transform: "translateX(16px)",
                                    color: "#fff",
                                },
                                "& .MuiSwitch-switchBase": {
                                    color: "#fff",
                                    position: "absolute",
                                    top: "6px",
                                    left: ResolveCondition(this.state.data.additionalPhotos, "18px", "8px"),
                                },
                                "& .MuiSwitch-thumb": {
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "#fff",
                                },
                                "& .MuiSwitch-track": {
                                    backgroundColor: ResolveCondition(this.state.data.additionalPhotos, "rgb(255, 8, 7)", "#ccc"),
                                    opacity: ResolveCondition(!this.state.data.additionalPhotos, "0.5 ", "1 !important"),
                                    borderRadius: "16px",
                                },
                            }}
                        />
                      </Box>
                      {ConditionJsx(this.state.data.additionalPhotos, (
                        <>
                        <StyleWrapperChapterHeading
                          sx={{ marginTop: "50px", marginInline: '45px' }}
                        >{configJSON.additionalPhotosText}</StyleWrapperChapterHeading>
                                    {this.state.data.chapters.map((chapter, index) => (
                                        <Box key={index} mb={5} className={ResolveCondition(index >= 1, "newHostBg", "")}
                                            sx={{ position: 'relative', width: ResolveCondition(index >= 1, "94%", "85%"), borderRadius: ResolveCondition(index >= 1, "8px", ""), backgroundColor: ResolveCondition(index >= 1, "rgb(68, 64, 60)", 'none') }}>
                                            <Box style={{ width: "100%", marginTop: ResolveCondition([0].includes(index), "-8%", "auto") }}>
                                                {ConditionJsx(index > 0, (
                                                    <IconButton
                                                        data-test-id="iconButton"
                                                        onClick={() => this.removeChapters(index)}
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                ), <></>)}
                                                <Box>
                                                    <Box>
                                                        <Typography style={{ color: "#fff", fontSize: "14px", lineHeight: "22px", fontFamily: "Nexa-Heavy", textTransform: "capitalize", marginTop: ResolveCondition(index >= 1, "8%", "9%"), marginLeft: '45px' }}>{configJSON.addChapterText}</Typography>
                                                        {ConditionJsx((this.state.data.invalidChapterMessage[index] !== ""), <label className="label" style={{ fontSize: "16px", lineHeight: "22px", whiteSpace: "nowrap", fontFamily: "Nexa-Regular", color: "#FF0807", marginLeft: ResolveCondition(index > 0, "3%", "8%") }}>(Image size must be 3000x3000)</label>, <></>)}
                                                        <Box style={{ display: "flex" }}>
                                                            <Box
                                                                data-test-id="drop"
                                                                sx={{
                                                                    marginRight: ResolveCondition(index === 0, "110px", "125px"),
                                                                    marginTop: ResolveCondition(index >= 1, "1%", "2%"),
                                                                    marginBottom: "2%",
                                                                    marginLeft: '45px',
                                                                    width: ResolveCondition(index >= 1, "32.5%", "35%"),
                                                                    height: "205px",
                                                                    border: '2px dashed',
                                                                    borderColor: ResolveCondition(this.state.data.invalidChapterMessage[index] === "", "#F5F5F5", "#FF0807"),
                                                                    borderRadius: '10px',
                                                                    padding: '20px',
                                                                    textAlign: 'center',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: '#312F2D',
                                                                    backgroundPosition: 'center',
                                                                    backgroundImage: ResolveCondition(this.state.data.additionalImages[index] != "", `url(${this.state.data.additionalImages[index]})`, "none"),
                                                                    backgroundSize: '50% auto',
                                                                    backgroundRepeat: 'no-repeat'
                                                                }}
                                                            >
                                                                {ConditionJsx(!!this.state.data.additionalImages[index], (
                                                                    <IconButton  data-test-id="icon-btn" style={{ marginLeft: "90%", marginTop: "-4%"  }}
                                                                     onClick={() => this.handleRemoveChapterImg(index)}
                                                                    ><Close style={{ color: "#fff" }} /></IconButton>
                                                                ), <></>)}
                                                                {ConditionJsx((!this.state.data.additionalImages[index]), <Box><input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    multiple
                                                                    name='files[]'
                                                                    style={{ display: 'none' }}
                                                                    id={`file-input-new-${index}`}
                                                                    data-test-id = "file-image-input"
                                                                    onChange={(e) => this.handleFileChapter(e,index)}
                                                                />
                                                                    <label htmlFor={`file-input-new-${index}`}>
                                                                        <Box sx={{ marginTop: "10%", lineHeight: 2 }}>
                                                                            <img src={uploadRedIcon} width="18" alt="upload" />
                                                                            <StyleWrapperBrowseTitle>
                                                                                {configJSON.dragDropFileText} <br />OR
                                                                            </StyleWrapperBrowseTitle>
                                                                            <StyleWrapperBrowseButton>
                                                                                {configJSON.browserFileText}
                                                                            </StyleWrapperBrowseButton>
                                                                        </Box>
                                                                    </label>
                                                                </Box>, <></>)}
                                                            </Box>
                                                            <Box style={{ width: "40%", marginLeft: ResolveCondition(index >= 1, "2%", "3%") }}>
                                                                <Typography style={{ color: "#fff", fontSize: "14px", lineHeight: "22px", fontFamily: "Nexa-Heavy", marginLeft: "-28%", textTransform: "capitalize", marginTop: ResolveCondition(index >= 1, "2%", "5%") }}>Start Time:</Typography>
                                                                <TextField
                                                                    name={`chapters[${index}]startTime`}
                                                                    id="outlined-basic"
                                                                    type="time"
                                                                    value={this.state.data.chapters[index].startTime || "00:00"}
                                                                    onChange={(e) => this.handleChapterChange(index , "startTime" , e.target.value) }
                                                                    data-test-id = "chapter-start-time"
                                                                    sx={{
                                                                        marginTop: "2%",
                                                                        opacity: "0.5",
                                                                        width: ResolveCondition(index >= 1, "120%", "140%"),
                                                                        borderRadius: "8px",
                                                                        marginLeft: "-28%",
                                                                        "& .MuiInputBase-input": {
                                                                            border: "1px solid rgb(203, 213, 225)",
                                                                            borderRadius: "4px",
                                                                            color: "#CBD5E1",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                                backgroundColor: "black !important",
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {color: "#CBD5E1"},
                                                                        "& .MuiOutlinedInput-root": {
                                                                          height: "56px",
                                                                          "&.Mui-focused fieldset": {borderColor: "#CBD5E1"}
                                                                        },
                                                                    }}
                                                                />
                                                                <Typography style={{ color: "#fff", fontSize: "14px", lineHeight: "22px", fontFamily: "Nexa-Heavy", marginLeft: "-28%", textTransform: "capitalize", marginTop: ResolveCondition(index >= 1, "4%", "7%") }}>End Time:</Typography>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    name={`chapters[${index}]endTime`}
                                                                    type="time"
                                                                   data-test-id = "chapter-end-time"
                                                                    value={this.state.data.chapters[index].endTime || "00:00"}
                                                                    onChange={(e) => this.handleChapterChange(index , "endTime" , e.target.value) }
                                                                    sx={{
                                                                        opacity:"0.5",
                                                                        marginTop:"2%",
                                                                        width: ResolveCondition(index >= 1,"120%","140%"),
                                                                        marginLeft: "-28%",
                                                                        borderRadius: "8px",
                                                                        "& .MuiInputLabel-root": { color:"#cbd5e1" },
                                                                        "& .MuiInputBase-input": {
                                                                            border: "1px solid rgb(203, 213, 225)",
                                                                            borderRadius: "4px",
                                                                            color: "#cbd5e1",
                                                                            backgroundColor: "BLACK",
                                                                            "&:-webkit-autofill": {
                                                                                backgroundColor: "black !important",
                                                                                WebkitBoxShadow:"0 0 0 1000px black inset",
                                                                                WebkitTextFillColor: "#cbd5e1 !important",
                                                                            },
                                                                        },
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#cbd5e1"
                                                                            },
                                                                            height: "56px"
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                    <Grid style={{ margin: "0%" }}  >
                                      <Grid item xs={6} style={{ marginTop: "0%" }}>
                                          <StyleWrapperButtonSignup
                                              style={{ color: "white", textTransform: "capitalize", margin: "0", marginInline: "auto", marginBottom: ResolveCondition((!this.state.data.additionalPhotos), "7%", "3%") }}
                                              data-test-id="submit-btn"
                                              className={ResolveCondition(this.state.data.chapters.some(
                                                  (chapter, index) =>
                                                      !chapter.newChapterImage ||
                                                      !chapter.startTime ||
                                                      !chapter.endTime ||
                                                      (this.state.data.invalidChapterMessage[index] !== "")
                                              ), "btnSignUp", "btnSelectedSignUp")}
                                              disabled={
                                                  this.state.data.chapters.some(
                                                      (chapter, index) =>
                                                          !chapter.newChapterImage ||
                                                          !chapter.startTime ||
                                                          !chapter.endTime ||
                                                          (this.state.data.invalidChapterMessage[index] !== "")
                                                  )
                                              }
                                              onClick = {this.handleUploadChapters}
                                          >
                                              {configJSON.uploadText}
                                          </StyleWrapperButtonSignup>
                                      </Grid>
                                    </Grid>
                                    <StyleWrapperButtonAnButton
                                        style={{ color: "white", textTransform: "capitalize", margin: "0", marginInline: "auto" }}
                                        data-test-id = "add-chapter-btn"
                                        className={ResolveCondition((this.state.data.chapters.length == 20), "addAnotherHost", "addAnotherHostBtn")}
                                        disabled={this.state.data.chapters.length == 20}
                                        fullWidth
                                        onClick={this.handleAddAnotherChapter}
                                    >
                                        {configJSON.addAnotherChapter}
                                    </StyleWrapperButtonAnButton>
                                </>), <></>
                            )}
                      </Box>
                  </Box>), <></>)}
                  </Box>
                  <Box className="buttonBox">
                      <Button onClick={this.handleUploadChapters} className="btn-save-changes">{configJSON.buttonText}</Button>
                  </Box>
                </Box>
             </Box>
             </StyleWrapperEditEpisode>

            <Dialog data-test-id = "open-" open={this.state.openSuccess} onClose={this.handleCloseFirstDialog} PaperProps={{
              style: {
                width: '27%',
                margin: '0 auto',
                borderBottomRightRadius: '30px',
                height: '36%'
              },
            }}
              componentsProps={{
                backdrop: {
                  style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    backdropFilter: 'blur(4px)',
                  },
                },
              }}>
              <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C" }}>
                <img src={tickIcon} style={{ marginTop: "5%" }} />
                <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', maxWidth: "100%" }} gutterBottom>
                  Your episode has been scheduled to publish to your channel!
                </Typography>
                <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy' }} gutterBottom>
                  What would you like to do next?
                </Typography>
              </DialogContent>
              <Divider />
              <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '25%' }}>
                <Button onClick={() => { }} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "150px", height: "45px", borderRadius: "5px", whiteSpace: "nowrap" }} data-test-id="copy-link">
                  Copy Episode Link
                </Button>
                <Button onClick={() => { this.props.handleCommonPath('MediaManagement') }} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "155px", height: "45px", borderRadius: "5px", whiteSpace: "nowrap" }} data-test-id="media-management">
                  Media Management
                </Button>
              </DialogActions>
            </Dialog>
            </Box>
            
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyleWrapperEditEpisode = styled('div')({
    height: "auto",
    padding:"20px 50px",
    background: "black",
    "& .subContain": {   
      position: "relative",
      paddingRight: "40px", 
      height: "47rem",
      overflow: "hidden",     
      scrollbarWidth: "thin", 
      overflowY: "scroll",
      scrollbarColor: "#FF0807 #4D4D4D",
    },
    "& *": {
      boxSizing: "border-box",
    },
    "& .field": {
      width: "954px",
      height: "56px",
    },"& .buttonBox": {
      display: "flex",
      justifyContent: "center"
    },
    "& .btn-save-changes" : {
      textTransform : "lowercase",
      backgroundColor : "#B2B3B3",
      color : "#FFF",
      fontFamily: "Nexa-Heavy",
      margin : "34px auto 30px auto",
      minWidth: "50%",
      padding: "16px",
      borderRadius: "8px",
    },
    "& .redContainer": {
      height: "80px",
      margin: 0,
      borderRadius: "16px 16px 0px 0px",
      backgroundColor: "#FF0807",    
    },
    "& .mainTitle": {
      margin: "auto",
      height: "40px",
      color: "white",
      fontFamily: "Nexa-Heavy",
      fontWeight: 900,
      fontSize: "30px",
      marginBottom : "21px"
    },
    "& .container": {
      backgroundColor: "#312F2D",
      display: "flex",
      width : "100%",
      flexDirection: "column",
      borderRadius: "0px 0px 8px 8px"
    },
    "& .accordionContainer": {
      width: "100%",
      maxWidth: "min(1220px,100%)",
      marginTop: "2rem",
      marginInline: "auto",
      display: "flex",
      flexDirection: "column",
      gap: "2.5rem"
    },
    "& .accordionDetail": {
        height: "max(10rem,fit-content)",
        marginTop: "1.5rem",
        paddingBottom: "1.5rem",
        marginLeft: "1.25rem",
        backgroundColor: "#44403C",
      '&[data-open="false"]': {
        display: "none !important"
      }
    },
    "& .select-dropdowns" :{
      display : "flex",
      flexDirection : "coloumn",
      gap : "40px",
      margin : "25px 200px 20px 200px"
    },
    "& .title-text":{
      color : "#FFFFFF",
      fontFamily: "Nexa-Heavy",
    }
  })

  const StyleWrapperGeneralText = styled('div')({
    width: "956px",
    marginInline: "auto",
    "& *": {
      boxSizing: "border-box",
    },
    "& .label": {
      fontFamily: "Nexa-Heavy",
      color: "white",
      fontSize: "14px",
      fontWeight: 700,
    }
  })

const StyleWrapperBrowseTitle = styled(Box)({
  color: "white",
  fontFamily: "Nexa-Heavy",
  textTransform: "capitalize",
  paddingTop: "1%"
})

const StyleWrapperLabel = styled("label")({
  fontFamily: "Nexa-Heavy",
  color: "white",
  fontSize: "14px",
  fontWeight: 700,
})

const StyleWrapperFlexPairBox2 = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "2%",
  columnGap: '20px'
})


const StyleWrapperFlexPair = styled("label")({
  justifyContent: "center",
  display: "flex",
  columnGap: '20px',
  marginTop: "2%"
})

const StyleWrapperFlexPairBox = styled(Box)({
  width: "39%"
})

const StyleWrapperTextField = styled(TextField)({
  marginTop: "2%",
  width: "100%",
  background:"#030303",
  "& .MuiInputLabel-root": {
    color: "#CBD5E1",
  },
  "& .MuiInputBase-input": {
    backgroundColor: "black",
    color:"#FFFFFF", fontSize:"14px",
    fontFamily:"Nexa-Heavy", fontWeight:900, 
    borderRadius: "8px",
    "&:-webkit-autofill": {
      backgroundColor: "black !important",
      WebkitTextFillColor: "#CBD5E1 !important",
      WebkitBoxShadow: "0 0 0 1000px black inset",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    height: "56px",
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
    },
  },
})

const StyleWrapperRadio = styled(Radio)({
  "&:before": {
    content: '""',
    width: 12,
    height: 12,
    borderRadius: "50%",
    border: "2px solid #CBD5E1",
    background: "black",

  },
  "&.Mui-checked:before": {
    content: '""',
    width: 12,
    height: 12,
    borderRadius: "50%",
    border: "3px solid red",
    backgroundColor: "white",

  }, "& .css-hyxlzm": { display: "none" }
})

const StyleWrapperRadioGroup = styled(RadioGroup)({
  marginTop: "2%",
  background: "black",
  gap: "40px",
  paddingLeft: '18px',
  borderRadius: "8px",
  alignItems: "center" 
})

const StyleWrapperFormControlLabel = styled(FormControlLabel)({
  color: "#CBD5E1",
  paddingRight: '5px',
  alignItems: "center",
  fontFamily: "Nexa-Regular",
  height: "55px"
})

const StyleWrapperAdsBox = styled(Box)({
  width: "180px",
  height: "175px",
  backgroundColor: "red"
})

const StyleWrapperAdsCarousel = styled(Box)({
  display: "flex",
  padding : "20px",
  alignItems: "center",
  columnGap: "17px",
  marginTop: "45px"
})

const StyleWrapperFlex = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
})

const StyleWrapperFlexColumn = styled(Box)({
  display: "flex",
  flexDirection: "column"
})

const StyleWrapperFlexColumnEnter = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const StyleWrapperRedButton = styled('button')({
  backgroundColor: "red",
  border:"none",
  padding: "5.97px 9.96px",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontWeight: "900"
})

const StyleWrapperBrowseButton = styled('button')({
  border:"none",
  height: "26px",
  width: "110px",
  fontWeight: "900",
  fontSize: "10px",
  marginTOp: "10px",
  fontFamily: "Nexa-Heavy",
  backgroundColor: "#FF0807",
  color: "white",
  textTransform: "capitalize",
  borderRadius: "8px"
})

const StyleWrapperWhiteBigButton = styled('button')({
  backgroundColor: "#B2B3B3",
  width: "100%",
  border:"none",
  padding: "16px",
  borderRadius: "8px",
  fontSize: "14px",
  color: "#FFFFFF",
  fontWeight: "900",
  marginTop: "23px"
})

const StyleWrapperWarningText = styled('p')({
  fontFamily: "Nexa-Heavy",
  color: "#FF0807",
  fontWeight: "400",
  fontSize: "12px"
})

const StyleWrapperCoverHeading = styled('span')({
  fontFamily: "Nexa-Heavy",
  color: "white",
  fontWeight: "900",
  fontSize: "30px"
})

const StyleWrapperBigParagraph = styled('p')({
  fontFamily: "Nexa-Heavy",
  fontSize: "16px",
  fontWeight: "700",
  color: "white"
})

const StyleWrapperDropCaption = styled('small')({
  textAlign : "center",
  fontFamily: "Nexa-Regular",
  fontSize: "12px",
  fontWeight: "400",
  color: "white"
})

const StyleWrapperDropBoxPhoto = styled('label')({
  position: 'relative',
  margin: "auto",
  marginTop: "2%",
  marginBottom: "2%",
  width: "300px",
  height: "300px",
  border: `2px dashed #ccc`,
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: '#312F2D'
})

const StyleWrapperPodcastCaption = styled('p')({
  fontFamily: "Nexa",
  fontSize: "12px",
  fontWeight: "100",
  textAlign: "left",
  color: "white"
})

const StyleWrapperCustomSwitch = styled('label')({
  "&": {
    position: "relative",
    width: "60px",
    height: "25px",
  },
    "& input": {
    appearance: "none",
    mozAppearance: "none",
    width: "100%",
    height: "100%",
    backgroundColor: "#ccc",
    position: "absolute",
    borderRadius: "20px",
    left: 0,
  },
  "&>span": {
    position: "absolute",
    width: "20px",
    height: "20px",
    backgroundColor: "#FFF",
    left: "8px",
    top: "50%",
    borderRadius: "20px",
    transform: "translateY(-8px)"
  },
  "&:checked+span": {
    left: "unset",
    right: "-8px",
  },
  "&:checked": {
    backgroundColor: "red"
  },
  "&:disabled": {
    backgroundColor: "rgb(255, 255, 255)"
  }
})

const StyleWrapperChapterHeading = styled(Typography)({
  fontFamily: "Nexa-Heavy",
  fontSize: "30px",
  fontWeight: "900",
  color: "white"
})

const StyleWrapperAccordion = styled(Accordion)({
  "&": {
    backgroundColor: "#030303",
    border: "1px solid #FFFFFF",
    color: "white",
    borderRadius: "8px !important",
  },
  "& .MuiTypography-root": {
    fontWeight: "900",
    fontFamily: "Nexa-Heavy",
  },
  "& .MuiSvgIcon-root>path": {
    color: "white"
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0 !important"
  },
  "& .MuiAccordionSummary-root": {
    padding: "0 8px"
  }
})

const StyleWrapperButtonSignup = styled(Button)({
  "&.btnSignUp": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "56px",
    background: "#B2B3B3",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "8%",
    fontWeight: 900,
    width: "578px",
    fontSize: "14px",
    marginLeft: "32%"
  },
  "&.btnSelectedSignUp": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "56px",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "8%",
    fontWeight: 900,
    width: "578px",
    fontSize: "14px",
    background: "#FF0807",
    marginLeft: "32%",
    "&:hover": {
        backgroundColor: "#FF0807",
    }
  }
})

const StyleWrapperButtonAnButton = styled(Button)({
  "&.addAnotherHostBtn": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "56px",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "2%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "578px",
    fontSize: "14px",
    background: "#030303",
    marginLeft: "32%",
    "&:hover": {
        backgroundColor: "#030303",
    }
  },
  "&.addAnotherHost": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "56px",
    background: "#B2B3B3",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "2%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "578px",
    fontSize: "14px",
    marginLeft: "32%"
  }
})

const PodcastCoverTextImage = styled('div')({
  display: 'flex', gap: '5vh', alignItems: 'center' 
})

const CoverPhotoText = styled('div')({
  color:'white', fontFamily:'Nexa-Heavy', fontWeight:'900' , overflowWrap: "anywhere"
})
// Customizable Area End
