import React, { createRef } from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { prev, rightArrow } from "../../dashboard/src/assets";
import { getStorageData } from 'framework/src/Utilities';
export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
    handleAddAudio: (playable_type: string, playable_id: string) => void;
}

interface IplaylistInfo {
    title: string;
    image: string;
    id: string;
}

interface ISelectedPlaylist {
    id: string;
    cover_image: string ; 
    name: string;
    description: string;
    episode_time: string | null; 
}

interface IMainPlaylist {
  id: string;
  cover_image: string ; 
  name: string;
  description: string;
  episode_time: string | null; 
  songAdded: boolean;
}

interface IPodcastAttributes {
  id: number;
  title: string | null;
  subtitle: string | null;
  cover_image: string | null;
  following?: boolean;
  follow_id? : string;
}

interface ArrowProps {
    onClick: () => void;
}

interface SS {
    id: any;
}


interface ISelectedPodcast {
  cover: string | null;
  name: string | null;
  description: string | null;
  podcastId: number;
}

interface IEpisodeList {
  id: string;
  cover: string;
  name: string;
  description: string;
  episode_time: string | null; 
  songAdded: boolean;
}

interface IEditPlaylist {
  title: string;
  image: string | null;
  id: string;
  selectedFile: File | null;
}
  
interface S {
    playlistInfo: IplaylistInfo;
    selectedPlaylistInfo: ISelectedPlaylist[];
    selectedPlaylist: number | null;
    addSongId: null | number;
    mainPlaylistInfo: IMainPlaylist[];
    podcastsListData: IPodcastAttributes[];
    isPodcastsLoading: boolean;
    podcastSearchValue: string;
    openPlaylistModal: boolean;
    isEpisodesLoading: boolean;
    isAddLoading: boolean;
    selectedSearchPodcast: ISelectedPodcast;
    addEpisodeId: string;
    episodeData: IEpisodeList[];
    isDeleteSongLoading: boolean;
    deletePlaylistModal: boolean;
    deletePlaylistLoading: boolean;
    showEditModal: boolean;
    editPlaylistData: IEditPlaylist;
    editPlaylistLoading: boolean;
}



export default class OtherPlayListEditController extends BlockComponent<Props, S, SS> {

    getShowPlaylistApiCallId: string = ""
    getMainPlaylistApiCallId: string = ""
    getAddSongsApiCallId: string = ""
    getPodcastListsApiCallId: string = ""
    searchPodCastApiCallId: string = ""
    searchEpisodesApiCallId: string = ""
    addEpisodesApiCallId: string = ""
    getPlaylistDelteSongAPICallId: string = ""
    unFollowPodcastCallId: string = ""
    followPodcastCallId: string = ""
    deletePlaylistApiCallId: string=""
    updatePlaylistApiCallId: string=""
    scrollref: any = createRef();
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      this.state = {
        playlistInfo: {
            title:"",
            image:"",
            id: ""
        },
        selectedPlaylistInfo: [],
        selectedPlaylist: null,
        addSongId: null,
        mainPlaylistInfo: [],
        podcastsListData:[],
        isPodcastsLoading: false,
        podcastSearchValue: "",
        openPlaylistModal: false,
        isEpisodesLoading: false,
        isAddLoading: false,
        selectedSearchPodcast: {
          cover: "",
          name: "",
          description:"",
          podcastId: 0,
        },
        addEpisodeId: "",
        episodeData: [],
        isDeleteSongLoading: false,
        deletePlaylistModal: false,
        deletePlaylistLoading: false,
        showEditModal: false,
        editPlaylistData: {
          title:"",
          image:"",
          id: "",
          selectedFile: null,
        },
        editPlaylistLoading: false
      };
  
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.getSongPlaylist();
        this.getMainPlaylist();
        this.getPodcastsList();
        setTimeout(() => {
          if (this.scrollref.current) {
            this.scrollref.current.scrollTo({ top: 0, behavior: "smooth" });
          }
        }, 0);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          if(this.getShowPlaylistApiCallId === apiRequestCallId) {
            this.handleShowplaylistResponse(from, message);
          }

          if(this.getMainPlaylistApiCallId === apiRequestCallId) { 
            this.handleMainPlaylistResponse(from, message);
          }

          if(this.getAddSongsApiCallId === apiRequestCallId) {
            this.handleAddSongApiResponse(from, message)
          }

          if(this.getPodcastListsApiCallId === apiRequestCallId) {
            this.handlePodcastListsApiResponse(from, message);
          }

          if(this.searchPodCastApiCallId === apiRequestCallId) {
            this.handleSearchResponse(from, message);
          }

          if(this.searchEpisodesApiCallId === apiRequestCallId) {
            this.handleSearchEpisodeListsResponse(from, message);
          }

          if(this.addEpisodesApiCallId === apiRequestCallId) {
            this.handleAddedEpisodesListResponse(from, message);
          }

          if(this.getPlaylistDelteSongAPICallId === apiRequestCallId) {
            this.handleDeleteResponse(from, message)
          }

          if(this.unFollowPodcastCallId === apiRequestCallId) {
            this.handleUnfolowResponse(from, message);
          }

          if(this.followPodcastCallId === apiRequestCallId) {
            this.handleFollowResponse(from, message)
          }

          if(this.deletePlaylistApiCallId === apiRequestCallId) {
            this.handleDeletePlaylistResponse(from, message)
          }          

          if(this.updatePlaylistApiCallId === apiRequestCallId) {
            this.handleEditPlaylistResponse(from, message)
          }
        }
    }

    handleAllPlaylist = () => {
        this.props.handleCommonPath("Playlist")
    }

    handlePlaylistRowClick = (index: number) => {
        this.setState({ selectedPlaylist: index });
    }

    getSearchSlickSettings(){
        const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    
        return(
           {
            infinite: false,
            dots: false,
            slidesToShow: 1,
            speed: 800,
            slidesToScroll: 1,
            nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
            initialSlide: 0,
            // prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
            responsive: [
              {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1700, 
                settings: {
                  slidesToShow: 1, 
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1440,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
            ]
           }
        )
    }
    
    prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
        <img
        src={prev} 
        onClick={onClick} 
        alt="Previous Arrow"
        style={{
            left: innerWidth > 1440 ? "-21.5%" : "-0.5%",
            position: 'absolute',
            top: innerWidth > 1440 ? "55%" : "40%",
            transform: 'translateY(-50%)',
            zIndex: 1,
            cursor: 'pointer',
        }} 
        />
    )
    }
    
    nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        zIndex: 1,
        borderRadius: "50%",
        height: "50px", width: "50px",
        background: "#FF0807",
        position: 'absolute',
        right: "-2%",
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        top: '50%',
      }}>
        <img
          src={rightArrow}
          style={{
            height: "100%",
            width: "100%",
          }}
          alt="RightArrow"
        />
      </div>
    )
    }

    handleAddBtnClick = async(song_id: string | number, index: number) => {

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.getAddSongsApiCallId = requestMessage?.messageId
  
        const getPlaylistId  = await getStorageData('selected_playlist_id')      
  
        let formdata = new FormData();
        formdata.append("song_id", song_id.toString())
        formdata.append("id", getPlaylistId);
        
        const token = await getStorageData('token')
        
        let cleanedToken = token.slice(1, -1);
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addSong}`
        );
          
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
          
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              token: cleanedToken
          })
        );

        this.setState({addSongId: index})
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPostMethod
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAddSongApiResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.message === "Song added successfully") {
        this.setState({addSongId: null})
        this.getSongPlaylist();
      }
      else {
        this.setState({addSongId: null})
        this.showAlert("Error", "Song Not Added")
      }
    }

    getSongPlaylist = async() => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
        
          this.getShowPlaylistApiCallId = requestMessage?.messageId
    
    
          const getPlaylistId  = await getStorageData('selected_playlist_id')     
    
          let playlistId = getPlaylistId;
    
          const token = await getStorageData('token')
      
          let cleanedToken = token.slice(1, -1);

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.validationApiContentType,
                token: cleanedToken
            })
            );
    
          requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.songListApiEndPoint}/${playlistId}`
          );
    
          requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.validationApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleShowplaylistResponse = async(from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
    
          if(apiResponse.playlist.data.id) {
            
            const filteredPlaylist: ISelectedPlaylist[] = 
            apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
              id: item.id,
              cover_image: item.attributes.cover || null,
              name: item.attributes.name || '',
              description: `${item.attributes.account?.first_name} ${item.attributes.account?.last_name}`,
              episode_time: item.attributes.episode_time || null,
            })) || [];
          
          this.setState({ selectedPlaylistInfo: filteredPlaylist })
  
            this.setState({
              playlistInfo: {
                title: apiResponse?.playlist?.data?.attributes?.title || '',
                image: apiResponse?.playlist?.data?.attributes?.image || '',
                id: apiResponse.playlist.data.id
              },
            });

          }
    }

    getMainPlaylist = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getMainPlaylistApiCallId = requestMessage?.messageId

      const getPlaylistId  = await getStorageData('main_playlist_id')     

      let playlistId = getPlaylistId;

      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.songListApiEndPoint}/${playlistId}`
      );
    
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMainPlaylistResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.playlist?.data?.id) {
        const filteredPlaylist: IMainPlaylist[] = 
        apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
          id: item.id,
          name: item.attributes.name || '',
          description: `${item.attributes.account?.first_name} ${item.attributes.account?.last_name}`,
          cover_image: item.attributes.cover || null,
          songAdded: item.attributes.added.song_added,
          episode_time: item.attributes.episode_time || null,
        })) || [];

        this.setState({mainPlaylistInfo: filteredPlaylist})
      }
    }

    getPodcastsList = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.setState({isPodcastsLoading: true});
  
      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);
  
      this.getPodcastListsApiCallId = requestMessage?.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listPodCastsEndPoint}`
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handlePodcastListsApiResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(apiResponse?.data?.length > 0) {

        const podcastData = apiResponse?.data?.map((podcast:any) => ({
          id: podcast.id,
          title: podcast.attributes.name,
          subtitle: `${podcast.attributes.hosts?.[0]?.first_name ?? ''} ${podcast.attributes.hosts?.[0]?.last_name ?? ''}`.trim(),
          cover_image: podcast.attributes.cover_image,
          following: podcast.attributes.follower.following,
          follow_id: podcast.attributes?.follower?.follow_id,
        }));

        this.setState({isPodcastsLoading: false});
  
        this.setState({podcastsListData: podcastData})
      }
      else {
        this.setState({isPodcastsLoading: false});
        this.setState({podcastsListData: []})
      }
    } 

    handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({podcastSearchValue: event.target.value})
    }

    handlePodcastSearchInput = async(event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchPodCastApiCallId = requestMessage?.messageId;
    
        const token = await getStorageData('token')
    
        let cleanedToken = token.slice(1, -1);
    
        this.setState({isPodcastsLoading: true});
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              "Content-Type": configJSON.validationApiContentType,
              token: cleanedToken
          })
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.globalSearchEndPoint}?query=${this.state.podcastSearchValue}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethod
        );

        
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    };

    handleSearchResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(apiResponse?.podcasts?.data.length > 0){
        this.setState({isPodcastsLoading: false});
  
        const extractedData = apiResponse?.podcasts?.data?.map((podcast:any) => ({
          id: podcast.id,
          title: podcast.attributes.name,
          subtitle: `${podcast.attributes.hosts?.[0]?.first_name ?? ''} ${podcast.attributes.hosts?.[0]?.last_name ?? ''}`.trim(),
          cover_image: podcast.attributes.cover_image,
          following: podcast.attributes.follower.following,
          follow_id: podcast.attributes?.follower?.follow_id,
        }));
    
        this.setState({podcastsListData: extractedData})
      }
      else {
        this.setState({isPodcastsLoading: false});
        this.setState({podcastsListData: []})
      }
    }

    handleCarouselImgClick = async(data:IPodcastAttributes) => {
      this.setState({openPlaylistModal: true})
      this.setState({
        selectedSearchPodcast: {
          cover: data.cover_image,
          description: data.subtitle,
          name: data.title,
          podcastId: data.id
        }
      });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const token = await getStorageData('token')
    
        let cleanedToken = token.slice(1, -1);
    
        this.searchEpisodesApiCallId = requestMessage?.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              "Content-Type": configJSON.validationApiContentType,
              token: cleanedToken
          })
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.listPodCastsEndPoint}/${data?.id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethod
        );
  
        this.setState({isEpisodesLoading: true});
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleSearchEpisodeListsResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      
      if(apiResponse.episodes.data.length > 0) {
        const extractedData = apiResponse.episodes.data.map((item:any) => {
          const { id, attributes } = item;
          const { cover, name, description, episode_time, added } = attributes;
  
          return {
            cover,
            id,
            name,
            description: `${attributes.account?.first_name} ${attributes.account?.last_name}`,
            songAdded: added?.song_added,
            episode_time,
          };
        });
        this.setState({isEpisodesLoading: false});
        this.setState({episodeData: extractedData})
      }
      else {
        this.setState({isEpisodesLoading: false});
        this.setState({episodeData: []})
      }
    }

    handleCloseModal = () => {
      this.setState({openPlaylistModal: false})
    }

    handleAdd = async(id: string) => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const token = await getStorageData('token')
      
      let getToken = token.slice(1, -1);
      
      const main_playlist_id = await getStorageData('main_playlist_id')
      
      this.addEpisodesApiCallId = requestMessage?.messageId
      
        let formdata = new FormData();
        formdata.append("song_id", id);
        formdata.append("id", main_playlist_id);

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addSong}`
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              token: getToken
          })
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiPostMethod
      );
      this.setState({isAddLoading: true});
      this.setState({addEpisodeId: id})
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleAddedEpisodesListResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(apiResponse?.message === "Song added successfully") {
        this.setState({isAddLoading: false})
        this.getMainPlaylist();
        const data = {
          id: this.state.selectedSearchPodcast.podcastId,
          title: this.state.selectedSearchPodcast.name,
          subtitle: this.state.selectedSearchPodcast.description,
          cover_image: this.state.selectedSearchPodcast.cover
        }
        this.handleCarouselImgClick(data)
      }
      else {
        this.setState({isAddLoading: false})
        this.showAlert("Error", "Song Not Added")
      }
    }

    handleDone = () => {
      this.getMainPlaylist();
      this.setState({openPlaylistModal: false})
    }

    handleDeleteSong = async(data: ISelectedPlaylist) => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.setState({isDeleteSongLoading: true});
  
      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);
    
      this.getPlaylistDelteSongAPICallId = requestMessage?.messageId
  
      const mainPlaylistID  = await getStorageData('selected_playlist_id')
  
      let formdata = new FormData();
      formdata.append("id", mainPlaylistID);
      formdata.append("song_id", data.id);

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.removeSong}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: cleanedToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethodtype
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDeleteResponse = async(from: string, message: Message) => {
      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(response?.message === "Song removed successfully") {
        this.setState({isDeleteSongLoading: false})
        this.getSongPlaylist();
        this.getMainPlaylist();
      }
      else {
        this.setState({isDeleteSongLoading: false})
        this.showAlert("Error", "Song Not Removed")
      }
    }

    isSongAdded = (id: string) => {
      return this.state.selectedPlaylistInfo.some((playlist) => playlist.id === id);
    }

    getRowStyles(index: number, selectedPlaylist: number | null) {
      return {
        backgroundColor: index === selectedPlaylist ? "black" : "",
        cursor: "pointer",
      };
    }
    
  handleFollowClick = async(data: IPodcastAttributes) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.followPodcastCallId = requestMessage.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    const body = {
      data: {
        followable_type: "BxBlockLogin::Podcast", 
        followable_id: data.id
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createfollow}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFollowResponse = async(from: string, message: Message) => {
    let followResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(followResponse.meta.message === "Successfully Followed") {
      this.getPodcastsList();
    }
  }

  handleUnFollowClick = async(data: IPodcastAttributes) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.unFollowPodcastCallId = requestMessage.messageId

    const token = await getStorageData('token')

    let getToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unfollow}${data.follow_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: getToken
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnfolowResponse = async(from: string, message: Message) => {
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(response.message === "Successfully Unfollowed") {
      this.getPodcastsList();
    }
  }

  handleEditPlaylist = () => {
    this.setState({showEditModal: true});
    this.setState((prevState) => ({
      editPlaylistData: {
        ...prevState.editPlaylistData,
        image: this.state.playlistInfo.image,
        id: this.state.playlistInfo.id,
        title: this.state.playlistInfo.title,
      },
    }));
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      editPlaylistData: {
        ...prevState.editPlaylistData,
        title: e.target.value,
      },
    }));
  }


  handleCloseEditPlaylist = () => {
    this.setState({showEditModal: false});
    this.setState((prevState) => ({
      editPlaylistData: {
        ...prevState.editPlaylistData,
        image: null,
        selectedFile: null,
      },
    }));
  }

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        editPlaylistData: {
          ...prevState.editPlaylistData,
          image: URL.createObjectURL(file),
          selectedFile: file,
        },
      }));
    }
  };  

  handleCancelImage = () => {
    this.setState((prevState) => ({
      editPlaylistData: {
        ...prevState.editPlaylistData,
        image: null,
        selectedFile: null,
      },
    }));
  }

  handleUpdatePlaylist = async() => {
    const storedToken = await getStorageData('token')

    let token = storedToken.slice(1, -1);

    const header = { token }

    if (this.state.showEditModal) {
      this.setState({ editPlaylistLoading: true });
    
      let formdata = new FormData();
      formdata.append("title", this.state.editPlaylistData.title); 
    
      if (this.state.editPlaylistData.selectedFile) {
        formdata.append("image", this.state.editPlaylistData.selectedFile); 
      }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePlaylistApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.songListApiEndPoint}/${this.state.editPlaylistData.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateMethodType
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }

  handleEditPlaylistResponse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.play_list_colection?.data?.id) {
      this.setState({ editPlaylistLoading: false }); 
      this.getSongPlaylist()
      this.setState({showEditModal: false})
    }
    else {
      this.setState({ editPlaylistLoading: false, showEditModal: false });
      this.showAlert("error", "The playlist could not be updated")
    }
  }

  handlePlayIconClick = () => {
    if(this.state.selectedPlaylistInfo.length > 0) {
      this.props.handleAddAudio("BxBlockLogin::Episode", this.state.selectedPlaylistInfo[0].id) 
    }
    else {
      this.showAlert("Error", "The playlist has no songs")
    }
  }


  handleDeletePlaylist = () => {
    this.setState({deletePlaylistModal: true})
  }

  handleCloseDeletePlaylistModal = () => {
    this.setState({deletePlaylistModal: false})
  }

  handleConfirmDeletePlaylist = async() => {
    this.setState({deletePlaylistLoading: true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deletePlaylistApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype
  );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.songListApiEndPoint}/${this.state.playlistInfo?.id}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeletePlaylistResponse = async (from: string, message: Message) => {
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(response.message === "This Playlist deleted successfully") {
      this.setState({deletePlaylistLoading: false});
      this.props.handleCommonPath("Playlist")
    }
    else {
      this.setState({deletePlaylistLoading: false});
    }
  }


}