// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";



export interface ApiCallMethodInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}

export interface PodcastDataObject {
  id: string,
  type: string,
  attributes: {
    cover_image: string,
    cover?: string,
    follower: {
      following: boolean,
      follow_id: number
    },
    name: string,
    hosts: [
      {
        first_name: string,
        last_name: string
      }
    ],
    account: [
      {
        first_name: string,
        last_name: string,
      },
    ],
    liked_episode: {
      liked: boolean,
      liked_id: string,
      count: number
    },
    save_episode: {
      saved: boolean,
      saved_id: number
    }

  }
}


export interface PostDataObject {
  id: string,
  type: string,
  attributes: {
    cover_image: string,
    cover?: string,
    created_at: string,
    share_post_count: string,
    follower: {
      following: boolean,
      follow_id: number
    },
    photos: [
      {
        photot_link: string,
        photot_name: string,
        upload_date: string,
        photot_id: number
      }
    ],
    name: string,
    body: string,
    hosts: [
      {
        first_name: string,
        last_name: string
      }
    ],
    account: {
      attributes: {
        profile_picture: string
      }
    },

    liked_post: {
      liked: boolean,
      liked_id: string,
      count: number
    },
    save_episode: {
      saved: boolean,
      saved_id: number
    },
    comments:
    {
      data: [
        {

        }
      ]
    }

  }
}

interface followingAPIData {
  data: {
    followable_id: string,
    followable_type: string,
    id?: number
  },
  message?: string
}

interface unFollowingAPIData {
  meta: {
    message: string
  }
}

interface GetSearchPodcastSuccessResponse { podcasts: { data: Array<PodcastDataObject> }, episodes: { data: Array<PodcastDataObject> }, posts: { data: Array<PostDataObject> } }


export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path: string) => void;
  // Customizable Area End
}



interface S {
  // Customizable Area Start
  searchPodcast: Array<PodcastDataObject>,
  searchEpisodes: Array<PodcastDataObject>,
  searchPost: Array<PostDataObject>,
  searchValue: string
  filterCategory: any,
  loader: boolean,
  currentChip: string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SearchAllResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchChannelDataApiCallId: string = "";
  followPodcastCallId: any = "";
  unFollowPodcastCallId: any = "";
  saveEpisodeCallId: any = "";
  unSaveEpisodeCallId: any = "";
  unlikePostApiCallId: any = "";
  thumbsUpPostApicall: string = "";
  thumbsDownDeleteApicall: string = ''
  likePostApiCallId: any = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchTextMessage)
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      searchPodcast: [],
      searchEpisodes: [],
      searchPost: [],
      searchValue: '',
      filterCategory: '',
      loader: true,
      currentChip: configJSON.channel
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start 
    this.getChannelDataApi();
    this.handleChipsCall();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start  
    let apiMessageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.validResponse(apiMessageId, responseJson);
    // Customizable Area End
  }

  handleChipsCall = async() => {
    let chipLabel = await getStorageData('chipLabel');
    if(chipLabel){
      this.changeChip(chipLabel)
    }
  }

  // Customizable Area Start
  apiCall = async (data: ApiCallMethodInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await getStorageData("token");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: JSON.parse(token),
        'Content-Type': contentType,
      })
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


    return requestMessage.messageId;
  };

  validResponse = (apiMessageId: string, responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    if (apiMessageId === this.searchChannelDataApiCallId) {
      this.getChannelDataApiSuccessCallback(responseJson)
    }
    if (this.followPodcastCallId === apiMessageId) {
      this.handleResForfollowPodcast(responseJson)
    }
    if (this.unFollowPodcastCallId === apiMessageId) {
      this.handleResForfollowPodcast(responseJson)
    }
    if (this.unSaveEpisodeCallId === apiMessageId) {
      this.handleResForUnSaveEpisode(responseJson);
    }
    if (this.saveEpisodeCallId === apiMessageId) {
      this.handleResForSaveEpisode(responseJson);
    }
    if (this.likePostApiCallId === apiMessageId) {
      this.handleResForLikePost(responseJson);
    }
    if (this.unlikePostApiCallId === apiMessageId) {
      this.handleResForUnlikepost(responseJson);
    }
    if (this.thumbsUpPostApicall === apiMessageId) {
      this.handleResForThubmsUpPost(responseJson);
    }
    if (this.thumbsDownDeleteApicall == apiMessageId) {
      this.handleResForThubmsDownPost(responseJson);
    }

  };

  getChannelDataApi = async () => {
    let searchValue = JSON.parse(await getStorageData('previousAllSearchResult')).searchQuery;
    let url = `${configJSON.searchResults}?query=${searchValue || ""}&per_page=10&page=1`;
    this.searchChannelDataApiCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: url,
    });
  };

  getChannelDataApiSuccessCallback = (responseJson: GetSearchPodcastSuccessResponse) => {
    let searchPodcast = responseJson.podcasts.data;

    let searchEpisode = responseJson.episodes.data;
    let searchPost = responseJson.posts.data;
    this.setState({ loader: false, searchPodcast, searchEpisodes: searchEpisode, searchPost: searchPost })
  };

  cardLabelCondition = (isFollow: boolean) => {
    return isFollow ? configJSON.following : configJSON.follow;
  };

  cardLabelColorCondition = (isFollow: boolean) => {
    return isFollow ? { background: "black", width: "108px" } : { background: "#FF0807", width: "84px" };
  };

  handleConditionFollow = (item: any, id: string) => {
    let isFollow = item.attributes.follower.following;
    if (isFollow) {
      this.handleUnFollowPodcast(item.attributes.follower.follow_id);
    } else {
      this.handleFollowPodcast(id);
    }
  };

  handleUnFollowPodcast = async (id: number) => {
    this.unFollowPodcastCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.unFollowPodcast}/${id}`
    });
  };

  handleResForfollowPodcast = async (responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    this.getChannelDataApi();
  };

  handleFollowPodcast = async (id: string) => {
    const body = {
      data: {
        "followable_type": "BxBlockLogin::Podcast",
        "followable_id": id
      }
    }
    this.followPodcastCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.postMethod,
      endPoint: configJSON.followPodcast,
      body
    });
  }

  handleResForUnfollowPodcast = async () => {
    this.getChannelDataApi()
  };

  changeChip(selectedChip: string) {
    this.setState({ currentChip: selectedChip })
  }

  selectionForLoop() {

    if (this.state.currentChip == configJSON.channel) {
      return this.state.searchPodcast
    }
    else if (this.state.currentChip == configJSON.episode) {
      return this.state.searchEpisodes
    }

  }



  handleUnSave = async (id: number | null) => {
    this.unSaveEpisodeCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.unSaveEpisode}/${id}`,
    });
  }


  handleSave = async (id: string) => {
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode",
        saveable_id: id
      }
    }
    this.saveEpisodeCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.postMethod,
      endPoint: configJSON.saveEpisode,
      body
    });
  }

  handleResForUnSaveEpisode = async (responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    if (responseJson.message == "Successfully Unsaved") {
      this.getChannelDataApi();
    }
  };

  handleResForSaveEpisode = async (responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    if (responseJson.data.id) {
      this.getChannelDataApi();
    }
  };

  handleUnLike = async (id: string) => {
    const body = {
      data: {
        "likeable_type": "BxBlockLogin::Episode",
        "likeable_id": id
      }
    }
    this.unlikePostApiCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.unLikePost}/${id}`,
      body
    });
  }

  handleLike = async (id: string) => {
    const body = {
      data: {
        "likeable_type": "BxBlockLogin::Episode",
        "likeable_id": id
      }
    }
    this.likePostApiCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.postMethod,
      endPoint: configJSON.likePost,
      body
    });
  }

  handleResForLikePost = async (responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    if (responseJson.data.id) {
      this.getChannelDataApi();
    }
  };

  handleResForUnlikepost = async (responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    if (responseJson.message == "Successfully destroy") {
      this.getChannelDataApi();
    }
  };

  convertDate(date: string) {
    return moment(date).format("MM/DD/YYYY");
  }

  async clickedThumb(id: string) {
    const body = {
      data: {
        "likeable_type": "BxBlockCommunityforum::Post",
        "likeable_id": id
      }
    }
    this.thumbsUpPostApicall = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.postMethod,
      endPoint: configJSON.likePost,
      body
    });
  }


  handleResForThubmsUpPost(responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) {
    if (responseJson.data.id) {
      this.getChannelDataApi();
    }
  }

  handleResForThubmsDownPost(responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) {
    if (responseJson.message == "Successfully destroy") {
      this.getChannelDataApi();
    }
  }

  async clickedUnThumb(id: string) {
    this.thumbsDownDeleteApicall = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.unLikePost}/${id}`,
    });
  }
  // Customizable Area End
}