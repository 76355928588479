import OtherPlayListEditController, { Props } from "./OtherPlayListEditController.web";
import { Box, Typography, Button, TextField, Grid, InputAdornment, styled } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SearchIcon from "@mui/icons-material/Search";
import ImageCard from "./components/ImageCard.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import PlayListModal from "./components/PlayListModal.web";
import { micImg } from "./assets";
import DeletePlayList from "./components/DeletePlayList.web";
import EditPlayList from "./components/EditPlayList.web";


const MIN_WINDOW_HEIGHT = 900


export default class OtherPlayListEdit extends OtherPlayListEditController {
    constructor(props: Props) {
        super(props);
    }

    renderDeleteContent(index: number) {
        const { selectedPlaylist, isDeleteSongLoading } = this.state;

        if (index === selectedPlaylist && isDeleteSongLoading) {
            return (
                <Typography
                color={"white"}
                padding={1}
                fontFamily={"Nexa-Regular"}
                fontWeight={"bold"}
                fontSize="14px"
                >
                Deleting...
            </Typography>
            )
        }
        return <DeleteOutlineIcon sx={{ color: "white" }} />
    }

    render() {
        return (
            <>
                <div ref={this.scrollref} style={webStyles.container}>
                <Box mb={3}>
                <Grid container spacing={2} mb={5}>
                    <Grid item md={3}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
                                <img src={this.state.playlistInfo?.image || micImg} style={{width:"273px", height:"273px", borderRadius:"8px", objectFit:"cover"}} />
                                <Box>
                                    <Typography data-test-id="otherPlaylistEdit" sx={{...webStyles.title, textAlign:"center"}}>{this.state.playlistInfo?.title}</Typography>
                                    <Box display={"flex"} justifyContent={"space-around"}>
                                    <Box
                                        bgcolor="#312F2D"
                                        borderRadius={1}
                                        padding={1}
                                        data-test-id="edit"
                                    >
                                        <EditIcon onClick={this.handleEditPlaylist} sx={{ color: "white", }} />
                                    </Box>
                                    <Box
                                        padding={1}
                                        bgcolor="#FF0807"
                                        data-test-id="play"
                                        borderRadius={1}
                                    >
                                        <PlayCircleOutlineIcon onClick={this.handlePlayIconClick} sx={{ color: "white", }} />
                                    </Box>
                                    <Box
                                        bgcolor="#312F2D"
                                        padding={1}
                                        borderRadius={1}
                                        data-test-id="delete"
                                    >
                                        <DeleteOutlineIcon onClick={this.handleDeletePlaylist} sx={{ color: "white", }} />
                                    </Box>

                                    </Box>
                                </Box>
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                    <Box>
                        <Box display={"flex"} justifyContent={"space-between"}> 
                            <Typography sx={webStyles.title}>{this.state.playlistInfo?.title}</Typography>
                            <Button
                                sx={{
                                ...buttonStyles,
                                color: "white",
                                border: "none",
                                background: "#FF0807",
                                "&:hover": {
                                    background: "#FF0807", 
                                }
                                }}
                                variant="contained"
                                startIcon={<PlaylistAddIcon />}
                                onClick={this.handleAllPlaylist}
                            >
                                View All Playlists
                            </Button>
                        </Box>
                        {
                            this.state.selectedPlaylistInfo.length > 0 ?
                        <Box data-test-id="songsContainer" height={"400px"} p={1} color={"white"} bgcolor={"#44403C"} overflow="scroll" sx={{overflowX:"auto"}}>
                            {this.state.selectedPlaylistInfo.map((data, index) => (
                                <Box
                                    data-test-id="selected-playlist"
                                    display={"flex"}
                                    key={index}
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    padding={2}
                                    borderBottom="1px solid #F5F5F5"
                                    sx={this.getRowStyles(index, this.state.selectedPlaylist)}
                                    onClick={() => this.handlePlaylistRowClick(index)}
                                >
                                    <Box display="flex" flex={5} gap={1}>
                                    <img
                                        alt={data.name}
                                        style={{ width: "40px", height: "40px", objectFit:"cover"}}
                                        src={data.cover_image}
                                    />
                                    <Box>
                                        <Typography
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"nowrap"}
                                        fontFamily={"Nexa-Heavy"}
                                        fontWeight={900}
                                        overflow={"hidden"}
                                        fontSize="16px"
                                        >
                                        {data.name}
                                        </Typography>
                                        <Typography
                                        fontSize="14px"
                                        textOverflow={"ellipsis"}
                                        overflow={"hidden"}
                                        whiteSpace={"wrap"}
                                        fontWeight={400}
                                        fontFamily={"Nexa-Regular"}
                                        >
                                        {data.description}
                                        </Typography>
                                    </Box>
                                    </Box>

                                    <Typography
                                    flex={1}
                                    fontWeight={400}
                                    fontSize="14px"
                                    fontFamily={"Nexa-Regular"}
                                    >
                                    {data.episode_time}
                                    </Typography>

                                    <Box
                                    bgcolor="#FF0807"
                                    data-test-id="deleteBtn"
                                    borderRadius={1}
                                    onClick={() => this.handleDeleteSong(data)}
                                    >
                                        {this.renderDeleteContent(index)}
                                    </Box>
                                </Box>
                            ))}                                
                        </Box>
                        : 
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"400px"} p={1} color={"white"} bgcolor={"#44403C"}>
                            <Typography data-test-id="nothingAdded" fontFamily={"Nexa-Regular"} fontSize={"14px"}> 
                            Nothing Added Yet
                            </Typography>
                        </Box>
                        }
                    </Box>
                    </Grid>
                </Grid>

                {/* second container  */}
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Box>
                            <Typography sx={webStyles.title}>Search</Typography>
                            <Box mt={1}>       
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "white", fontSize: "20px" }} />
                                        </InputAdornment>
                                        ),
                                    }}
                                    fullWidth 
                                    placeholder="Search a podcast"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                        fontFamily: "Nexa-Heavy",
                                        borderRadius: "98px", 
                                        color:"white",
                                        border:"1px solid white",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "8px 2px", 
                                    },
                                    }}
                                    data-test-id="search_podcast"
                                    value={this.state.podcastSearchValue}
                                    onChange={this.handleSearch}
                                    onKeyDown={this.handlePodcastSearchInput}
                                />
                            </Box>
                            {
                                this.state.isPodcastsLoading ? (
                                    <Box mt={2} width="97%" textAlign="center">
                                    <Typography sx={webStyles.title}>Loading...</Typography>
                                    </Box>
                                ) : (
                                    <>
                                    {this.state.podcastsListData.length ? (
                                        <Box mt={2} overflow="hidden">
                                        <StyledSearchSlider {...this.getSearchSlickSettings()}>
                                            {this.state.podcastsListData.map((data, index) => (
                                            <ImageCard
                                                key={index}
                                                data-test-id="carousel_card"
                                                imageUrl={data.cover_image}
                                                songName={data.title}
                                                artist={data.subtitle}
                                                following={data?.following}
                                                onClick={() => this.handleCarouselImgClick(data)}
                                                followingClick = {() => this.handleFollowClick(data)}
                                                unFollowingClick = {() => this.handleUnFollowClick(data)}
                                            />
                                            ))}
                                        </StyledSearchSlider>
                                        </Box>
                                    ) : (
                                        <Box mt={2} width="97%" textAlign="center">
                                        <Typography sx={webStyles.title}>No podcasts available</Typography>
                                        </Box>
                                    )}
                                    </>
                                )
                            }

                        </Box>
                    </Grid>
                    <Grid item md={9}>
                    <Box>
                        <Typography sx={webStyles.title}>From Main Playlist</Typography>
                        
                        <Box height={"400px"} p={1} color={"white"} bgcolor={"#44403C"} overflow="scroll" sx={{overflowX:"auto"}}>
                            {this.state.mainPlaylistInfo.map((data, index) => (
                                <Box
                                    data-test-id="playlist-item"
                                    key={index}
                                    display={"flex"}
                                    alignItems="center"
                                    justifyContent={"space-between"}
                                    borderBottom="1px solid #F5F5F5"
                                    padding={2}
                                >
                                    <Box display="flex" flex={5} gap={1}>
                                    <img
                                        src={data.cover_image}
                                        alt={data.name}
                                        style={{ width: "40px", height: "40px", objectFit:"cover" }}
                                    />
                                    <Box>
                                        <Typography
                                        textOverflow={"ellipsis"}
                                        fontFamily={"Nexa-Heavy"}
                                        overflow={"hidden"}
                                        whiteSpace={"nowrap"}
                                        fontSize="16px"
                                        fontWeight={900}
                                        >
                                        {data.name}
                                        </Typography>
                                        <Typography
                                        overflow={"hidden"}
                                        whiteSpace={"wrap"}
                                        textOverflow={"ellipsis"}
                                        fontWeight={400}
                                        fontFamily={"Nexa-Regular"}
                                        fontSize="14px"
                                        >
                                        {data.description}
                                        </Typography>
                                    </Box>
                                    </Box>

                                    <Typography
                                    fontFamily={"Nexa-Regular"}
                                    flex={1}
                                    fontSize="14px"
                                    fontWeight={400}
                                    >
                                    {data.episode_time}
                                    </Typography>

                                    {
                                        this.isSongAdded(data.id) ? 
                                        <Button
                                        data-test-id="AddShowsToMyPlaylist"
                                        sx={{
                                        ...buttonStyles,
                                        border: "none",
                                        color: "white",
                                        background: "#030303",
                                        width:"110px",
                                        height:"none",
                                        fontSize:"10px",
                                        "&:hover": {
                                            background: "#030303", 
                                        }
                                        }}
                                        variant="contained"
                                        startIcon={<PlaylistAddCheckIcon />}
                                        >
                                        Added
                                        </Button> 
                                        :
                                        <Button
                                        data-test-id="AddShowsToMyPlaylist"
                                        sx={{
                                        ...buttonStyles,
                                        border: "none",
                                        color: "white",
                                        background: "#FF0807",
                                        width:"110px",
                                        height:"none",
                                        fontSize:"10px",
                                        "&:hover": {
                                            background: "#FF0807", 
                                        }
                                        }}
                                        variant="contained"
                                        startIcon={<PlaylistAddIcon />}
                                        onClick={() => this.handleAddBtnClick(data.id, index)}
                                    >
                                        { this.state.addSongId === index ? "Adding..." : "Add"}
                                    </Button>
                                    }
                                </Box>
                            ))}                                
                        </Box>
                    </Box>
                    </Grid>
                </Grid>


                </Box>
                <Footer navigation={this.props.navigation} id={"1"} />
                </div>

                {
                this.state.openPlaylistModal &&
                    <PlayListModal 
                        open={this.state.openPlaylistModal} 
                        onClose={this.handleCloseModal} 
                        episodeDataLoading = {this.state.isEpisodesLoading} 
                        episodeData={this.state.episodeData}
                        selectedData={this.state.selectedSearchPodcast}  
                        handleAddEpisode={(id) => this.handleAdd(id)}
                        addEpisodeIsLoading = {this.state.isAddLoading}
                        addEpisodeId = {this.state.addEpisodeId}
                        handleDone = {this.handleDone}
                    />
                }     


                {
                this.state.showEditModal &&
                    <EditPlayList 
                        close={this.handleCloseEditPlaylist} 
                        open={this.state.showEditModal} 
                        handleInputChange={this.handleInputChange}
                        playlistInfo={this.state.editPlaylistData} 
                        handleCancelImage={this.handleCancelImage}
                        handleFileChange={this.handleFileChange}
                        isLoading={this.state.editPlaylistLoading}
                        handleSave={this.handleUpdatePlaylist}
                    />
                }   


                {
                this.state.deletePlaylistModal && 
                    <DeletePlayList 
                        close={this.handleCloseDeletePlaylistModal}
                        open={this.state.deletePlaylistModal} 
                        handleSave={this.handleConfirmDeletePlaylist}
                        isLoading={this.state.deletePlaylistLoading}
                    />
                } 

            </>
        )
    }
}


const webStyles = {
    container: {
      maxHeight: "100%", padding:"20px 50px", overflowY: "auto" as const},
    title : {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    }
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    height: "34px",
};

const StyledSearchSlider = styled(Slider)`
.slick-slide {
    width: none !important;
}
.slick-slider {
  display: block;
  position: relative;
  overflow: hidden; 
}
  .slick-list {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  overflow: hidden; /* Hides overflow */
}

.slick-track {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  transition: transform 0.5s ease-in-out; /* Smooth scrolling */
}
div.slick-slide.slick-active.slick-current {
  width: min-content !important;
}
`;