Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.searchApiContentType = 'application/json'
exports.getSearchApiEndPoint = 'search/users'
exports.errorTitle = 'Error'
exports.searchResults = 'bx_block_search/search'
exports.unLikePost = 'bx_block_like/likes'
exports.likePost = 'bx_block_like/likes'
exports.postMethod = "POST"
exports.deleteMethod = "DELETE"
exports.followPodcast = "bx_block_catalogue/followers?type=podcast"
exports.unFollowPodcast = "bx_block_catalogue/followers";
exports.saveEpisode = "bx_block_catalogue/saved_episodes";
exports.unSaveEpisode = "bx_block_catalogue/saved_episodes";
exports.allSearchResult='All Search Result';
exports.channel= "Channel";
exports.filterBy="Filter by:";
exports.episode="Episode";
exports.post="Post";
exports.following="Following";
exports.follow="Follow";
// Customizable Area End
