import React from "react";
// Customizable Area Start
import SearchAllResultController, { Props, PodcastDataObject, configJSON, PostDataObject } from "./SearchAllResultController";
import { goBack } from "../../email-account-login/src/assets";
import {
  Box,
  styled,
} from "@mui/material";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { addBtn, addedBtn, heart, selectedBtn } from "../../dashboard/src/assets";
import { like, thumbIcon } from "../../audiolibrary/src/assets";

// Customizable Area End

export default class SearchAllResult extends SearchAllResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    return (
      <>
        <StyledWrapperAllSearchList>
          <Wrapper>
            <Title className="mainTitle" id="sponsoredContent" >
              <img
                src={goBack}
                alt="goBack"
                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                data-test-id="navigate"
                onClick={()=>this.props.handleCommonPath('AllSearchList')}
              />
              {configJSON.allSearchResult}
            </Title>
            <ChipData>
              <div style={{ paddingRight: "3%" }}>{configJSON.filterBy}</div>
              <Stack direction="row" spacing={1} >
                <Chip label={configJSON.channel}
                  className={("filterCategory")}
                  data-test-id="filterCategory"
                  style={this.state.currentChip == configJSON.channel ? { backgroundColor: 'red', color: 'white' } : {}}
                  onClick={() => this.changeChip(configJSON.channel)}
                />
                <Chip label={configJSON.episode}
                  data-test-id="filterEpisode"
                  className={("filterCategory")}
                  style={this.state.currentChip == configJSON.episode ? { backgroundColor: 'red', color: 'white' } : {}}
                  onClick={() => this.changeChip(configJSON.episode)}
                />
                <Chip label={configJSON.post}
                  className={("filterCategory")}
                  data-test-id="filterPosts"
                  style={this.state.currentChip == configJSON.post ? { backgroundColor: 'red', color: 'white' } : {}}
                  onClick={() => this.changeChip(configJSON.post)}
                />
              </Stack>

            </ChipData>
          </Wrapper>

          <ListParent >
            <ListTitle>
              <ChannelTitle >{this.state.currentChip}</ChannelTitle>
            </ListTitle>
            <ListData >
              {this.state.loader ? <Box sx={{ display: 'flex' }} data-test-id="loader-test">
                <CircularProgress />
              </Box> :
                <>  {
                  this.state.currentChip != configJSON.post ?
                    this.selectionForLoop() && this.selectionForLoop()?.map((item: PodcastDataObject, index) => {
                      return (
                        <div key={index} id="podcasts">
                          {
                            <SuggestedImgBody>
                              <ImageCov src={this.state.currentChip == configJSON.channel ? item.attributes?.cover_image : item.attributes?.cover} />
                              {this.state.currentChip == configJSON.channel ? <LabelButton
                                onClick={() => { this.handleConditionFollow(item, item.id) }}
                                style={this.cardLabelColorCondition(item.attributes.follower.following)}
                                data-test-id="unfollowPodcast">

                                {this.cardLabelCondition(item.attributes.follower.following)}
                              </LabelButton> : <div>
                                <Heart data-test-id="testLiked">
                                  {item.attributes.liked_episode.liked ? <img src={selectedBtn} alt="unLikeBtn" id="episode" onClick={() => { this.handleUnLike(item.attributes.liked_episode.liked_id) }} /> : <img src={heart} alt="likeBtn" id="episode" data-test-id="saveClick" onClick={() => { this.handleLike(item.id) }} />}
                                </Heart>
                                <AddItem data-test-id="testSaved" >
                                  {
                                    item.attributes?.save_episode?.saved ? <img src={addedBtn} alt="addedBtn" onClick={() => { this.handleUnSave(item.attributes?.save_episode?.saved_id) }} /> : <img src={addBtn} alt="saveBtn" onClick={() => { this.handleSave(item.id) }} />
                                  }
                                </AddItem>



                              </div>}
                              <Description id="podcasts">
                                <CardTitle
                                  style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy" }} id="podcasts">
                                  {item.attributes?.name.toUpperCase()}
                                </CardTitle>
                                <CardTitle id="podcasts"
                                  style={{ textTransform: "capitalize" }}>
                                  {this.state.currentChip == configJSON.channel
                                    ? item.attributes?.hosts?.length > 0 ? `${item.attributes?.hosts[0]?.first_name} ${item.attributes?.hosts[0]?.last_name}` : "No hosts available"
                                    : item.attributes?.account?.length > 0 ? `${item.attributes?.account[0]?.first_name} ${item.attributes?.account[0]?.last_name}` : "No account info"}

                                </CardTitle>
                              </Description>
                            </SuggestedImgBody>}
                        </div>
                      )
                    }) :
                    this.state.searchPost && this.state.searchPost?.map((item: PostDataObject, index) => {
                      return (
                        <div key={index} id="podcasts">
                          {
                            <div style={{ color: 'white' }}>
                              <PostContainer style={{ height: 'auto' }}>
                                <ParentPost>
                                  <ProfileImageName >
                                    <div >
                                      <ProfileImage src={item.attributes.account.attributes.profile_picture}></ProfileImage>
                                    </div>
                                    <div style={{ marginTop: '15px', flexGrow: 1 }}>
                                      <NameBody style={{ fontWeight: 900 }}>{item.attributes.name}</NameBody>
                                      <NameBody style={{ fontWeight: 100 }}>{item.attributes.body}</NameBody>
                                    </div>
                                    <ThreeDots >
                                      <h2>...</h2>
                                    </ThreeDots>
                                  </ProfileImageName>
                                  {item.attributes.photos && <ProfileImagePare  >
                                    {item.attributes.photos && <PostProfileImage src={item.attributes?.photos[0]?.photot_link}></PostProfileImage>}
                                  </ProfileImagePare>}
                                  
                                  <BottomParenttexts >
                                    <DateText >{this.convertDate(item.attributes.created_at)}</DateText>
                                    <BottomMenuParent >
                                      <BottomImageIcon data-test-id="Test-thumbUp" onClick={() => { item.attributes.liked_post.liked ? this.clickedUnThumb(item.attributes.liked_post.liked_id) : this.clickedThumb(item.id) }} style={{ height: '20px', marginTop: '18px' }} src={item.attributes.liked_post.liked ? like : thumbIcon}></BottomImageIcon>
                                      <h4>{item.attributes.liked_post.count}</h4>
                                    </BottomMenuParent>
                                    <BottomMenuParent >
                                      <BottomImageIcon src="/static/media/comments.9951de85.svg"></BottomImageIcon>
                                      <h4>{item.attributes.comments.data.length}</h4>
                                    </BottomMenuParent>
                                    <BottomMenuParent >
                                      <BottomImageIcon src="/static/media/shares.b9cfae7f.svg"></BottomImageIcon>
                                      <h4>{item.attributes.share_post_count}</h4>
                                    </BottomMenuParent>
                                  </BottomParenttexts>
                                </ParentPost>
                              </PostContainer>
                            </div>}
                        </div>
                      )
                    })
                }
                </>

              }
            </ListData>
          </ListParent>
        </StyledWrapperAllSearchList>

      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapperAllSearchList = styled('div')({
  "& *": {
    boxSizing: "border-box",
  },
  "& .likedCount": {
    fontFamily: "Nexa-Heavy",
    fontSize: "14px",
    color: "red"
  },
  "& .count": {
    fontFamily: "Nexa-Heavy",
    fontSize: "14px"
  },
  "& .filterCategory": {
    background: "white",
    color: "black",
    fontWeight: 900,
    fontSize: "14px",
    fontFamily: "Nexa-Heavy",
    height: "32px",
    width: "91px",
    "&:hover": {
      background: "white",
      color: "black",
      cursor: "pointer"
    }
  },
  "& .filteredCategories": {
    background: "#FF0807",
    color: "white",
    "&:hover": {
      background: "#FF0807",
      color: "white",
    }
  }
})

const webStyles = {
  sponsoredImgBody: {
    height: "370px",
    width: '100%',
    position: 'relative' as const
  },
  sponsoredButton: {
    color: "#ffffff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  container: {
    padding: "20px 50px"
  } as const,
  imgBody: {
    position: 'relative' as const,
  },
  episode: {
    color: "#ffffff",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600
  },
  postBody: {
    width: "100%",
    height: "25%",
    backgroundColor: "#312F2D",
    position: 'relative' as const
  },
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#ffffff',
    marginBottom: "10px"
  },
};

const Description = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: "76px",
  backgroundColor: "#44403c",
  borderBottomLeftRadius: "16px",
  borderBottomRightRadius: "16px",
  padding: "16px"
});

const ImageCov = styled("img")({
  objectFit: "cover" as const,
  borderRadius: "8px",
  width: '100%',
  height: "223px",
  display: "flex",
  margin: "0 auto",
});

const SuggestedImgBody = styled("div")({
  width: '227px',
  height: "320px",
  position: 'relative' as const
});

const Wrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const Title = styled(Box)({
  color: "white", display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "3%", fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900
})

const ChipData = styled(Box)({
  display: "flex", alignItems: "center", color: "white", marginRight: "3%", width: "25%"
})

const ListParent = styled(Box)({
  width: "90%", margin: "auto", marginTop: "3%", height: "379px"
})

const ListTitle = styled(Box)({
  color: "white", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontSize: "20px", fontWeight: 900
})

const ListData = styled('div')({
  display: 'flex', justifyContent: "left", flexWrap: 'wrap', gap: '10px'
})

const LabelButton = styled('div')({
  textAlign: "center", position: "absolute", right: "10px", top: "15px", color: "white", height: "31px", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontStyle: "16px", fontWeight: 900, "&:hover": { cursor: 'pointer' }
})

const CardTitle = styled(Box)({
  ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy"
})

const ChannelTitle = styled('h2')({
  color: "white", marginBottom: "30px",  fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900
})

const Heart = styled('div')({
  position: "absolute", right: "10px", top: "10px", cursor: 'pointer'
})

const AddItem = styled('div')({
  position: "absolute", right: "10px", top: "50px", cursor: 'pointer'
})

const PostContainer = styled(Box)({
  backgroundColor: '#312F2D', height: '577px', width: '1474px', top: '353px', left: '267px', borderRadius: '20px'
})

const ProfileImage = styled('img')({
  width: '59.07px',
  height: '56px',
  marginTop: '35px',
  marginLeft: '55px',
  borderRadius: '100px'
})
let common1 = { fontFamily: "Nexa-Heavy", fontSize: '14px', lineHeight: '26px' }
const NameBody = styled('h3')({
  width: '904.28px',
  ...common1
})

const DateText = styled('h3')({
  ...common1,
  fontWeight: 900,
  height: '26px',
  width: '78px'
})

const BottomMenuParent = styled('div')({
  display: 'flex', gap: '10px'
})

const BottomImageIcon = styled('img')({
  width: '25.31px', cursor: 'pointer'
})

const BottomParenttexts = styled('div')({
  marginLeft: '155px', display: 'flex', gap: '40px'
})

const ParentPost = styled('div')({
  display: 'flex', flexDirection: 'column', gap: '20px'
})

const ProfileImageName = styled('div')({
  display: 'flex', justifyContent: 'space-around', gap: '40px'
})

const ThreeDots = styled('div')({
  color: 'white', fontSize: 'x-large', marginRight: '20px'
})

const ProfileImagePare = styled('div')({
  display: 'flex', gap: '20px', justifyContent: 'center'
})

const PostProfileImage = styled('img')
  ({
    height: '308px', width: '765.41px', borderRadius: '43px'
  })
// Customizable Area End
