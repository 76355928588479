import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    screenId: string;
    navigation: any;
    handleAddAudio : (playable_type: string, playable_id: string) => void;
    handleCommonPath: (path: string) => void;
}

interface SavedEpisode {
    selected: boolean;
    imageUrl: string;
    songName: string;
    added: boolean;
    episodeId: string;
    artist: string;
    saved_id: string;
}

interface S {
    savedEpisode: SavedEpisode[];
}

interface SS {
  screenId: string;
}
interface ValidResponse{
  data: [{
    attributes: {
      saveable: {
        data: {
          attributes: {
            save_episode: { saved: boolean, saved_id: string },
            cover: string,
            name: string,
            description: string,
            added: { song_added: boolean }
          }
        }
      }
    }
  }]
}

export default class SavedEpisodeController extends BlockComponent<
  Props,
  S,
  SS
> {
    unSaveEpisodeApiCallId: string = "";
    savedEpisodeApiCallId : string = "";
    removeFromMainPlayListAPICallId: string = "";
    addToMainPlayListAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
        savedEpisode: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId == this.savedEpisodeApiCallId) {
      this.savedEpisodeFetchSuccessCallBack(responseJson)
    }
    if(apiRequestCallId === this.unSaveEpisodeApiCallId) {
      this.unsaveEpisodeResponse(from, message);
    }
    if(apiRequestCallId === this.addToMainPlayListAPICallId) {
      this.handleResponseForAddToMainPlayList(from, message);
    }
    if(apiRequestCallId === this.removeFromMainPlayListAPICallId) {
      this.handleRemovePlaylistResponse(from, message)
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.savedEpisodeFetch()
  }

  savedEpisodeFetch = async () => {
    let getToken = await getStorageData("token");
    
    const header = {
      token: JSON.parse(getToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savedEpisodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savedEpisode
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  savedEpisodeFetchSuccessCallBack = (responseJson: ValidResponse) => {
    let data = responseJson.data;
    let savedData = data.map((element: any) => {
      let elementAttributes = element.attributes.saveable.data.attributes;
      return ({
        selected: elementAttributes.save_episode.saved,
        imageUrl: elementAttributes.cover,
        songName: elementAttributes.name,
        added: elementAttributes.added.song_added,
        artist: elementAttributes.description,
        saved_id: elementAttributes.save_episode.saved_id,
        episodeId: element.attributes.saveable.data.id
      })
    });
    this.setState({ savedEpisode: savedData });
  }

  async handleRemoveSaveLater(id: string) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );

    this.unSaveEpisodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unsaveEpisodeResponse = async(from: string, message: Message) => {
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response.message == "Successfully Unsaved") {
      this.savedEpisodeFetch()  
    }
  }

  handleAddToMainPlayList = async(id: string) => {

    let getToken = await getStorageData("token");
    let playListId = await getStorageData("main_playlist_id");

    const header = {
      token: JSON.parse(getToken),
    };

    let formdata = new FormData();
    formdata.append("song_id", `${id}`);
    formdata.append("id", playListId);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToMainPlayListAPICallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToPlayList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForAddToMainPlayList = async(from: string, message: Message) => {
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response.message == "Song added successfully") {
      this.savedEpisodeFetch();                  
    }
  }

  handleRemoveFromMainPlayList = async(id:string) => {
    let userToken = await getStorageData("token");
    let mainPlaylistId = await getStorageData("main_playlist_id");

    const header = {
      token: JSON.parse(userToken),
    };

    let formdata = new FormData();
    formdata.append("id", mainPlaylistId);
    formdata.append("song_id", `${id}`);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFromMainPlayListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromPlayList}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemovePlaylistResponse = async(from: string, message: Message) => {
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (response.message == "Song removed successfully") {
      this.savedEpisodeFetch(); 
    }
  }
}