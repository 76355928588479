Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.done = "Done";
exports.box2Image = "box";
exports.getActivityFeedApiEndPoint = "bx_block_activity_feed/activity_feeds";
exports.listening_stats = "bx_block_filter_items/listening_times?q=";
exports.recent_posts = "bx_block_filter_items/listening_times/recent_posts";
exports.recent_comments = "bx_block_filter_items/listening_times/recent_comments";
exports.top_comments = "bx_block_comments/comments/top_comments?per_page=10&time_filter=";
exports.top_posts = "bx_block_communityforum/posts/top_posts?time=1";
// Customizable Area End
