import React from "react";
// Customizable Area Start
import { Box, Typography, LinearProgress, styled } from "@mui/material";
import Slider from "react-slick";
import { addBtn, addedBtn, heart, selectedBtn } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";



// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class DashboardTwo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start    

    return (
      //Merge Engine DefaultContainer
      <>
        <div style={webStyles.container} ref={this.scrollRef}>

          {this.state.continueListeningPodcasts && this.state.continueListeningPodcasts.length > 0 &&
            <Box sx={{ height: "430px", width: "100%" }} data-test-id="continueListeningSection">
              <Typography style={webStyles.title}>Continue Listening</Typography>
              <Slider {...this.getContinueListeningSlickSettings()} data-test-id={"audioPlayBtnId"}>
              {this.state.continueListeningPodcasts.map((item:any) => (
                  <div key={item.id} onClick={() => this.props.handleAddAudio("BxBlockLogin::Episode", item.episode_id)}>
                    <div style={webStyles.imgBody}>
                      <img style={webStyles.image} src={item.cover} alt={item.alt} />
                      <div style={{ position: "absolute", bottom: "3%", left: "3%", width: '95%' }}>
                        <Typography style={{ ...webStyles.episode }}>
                          {item.podcast_name} - {item.episode_number}
                        </Typography>
                        <Typography style={{ ...webStyles.title }}>
                          {item.episode_name}
                        </Typography>
                        <LinearProgress
                          sx={{
                            backgroundColor: 'lightgray',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#ff0807',
                            },
                          }}
                          variant="determinate"
                          value={item.run_time}
                        />
                      </div>
                    </ div>
                  </div>
                ))}
              </Slider>
            </Box>
          }

          <div style={{ position: 'relative', height: "430px", width: "100%", paddingTop: "3%"}}>
            <Typography style={webStyles.title}>Sponsored</Typography>
            <StyledSponsoredSliderDiv {...this.getSponsoredSlick()}>
              {this.state.sponsoredPodcasts.map((datas: any) => (
                <Box key={datas.id} style={webStyles.sponsoredImgBody}>
                  <img style={webStyles.image} src={datas.src} alt={datas.alt} />
                  <Box style={{ position: "absolute", bottom: "17%", left: "1%" }}>
                    <Typography style={{ ...webStyles.episode }}>
                      {datas.episode.toUpperCase()}
                    </Typography>
                    <Typography style={{ ...webStyles.title }}>
                      {datas.name.toUpperCase()}
                    </Typography>
                    <button style={webStyles.sponsoredButton}>
                      Listen now
                    </button>
                  </Box>
                </Box>
              ))}
            </StyledSponsoredSliderDiv>
          </div>
                 
           <Box sx={{ position: 'relative', height: "430px", paddingTop: "3%", paddingBottom: "2%"}}>
          <Typography style={webStyles.title}>Suggested For You</Typography>
          {this.state.suggestedPodcasts && this.state.suggestedPodcasts.length > 0 ? 
        <StyledSlider {...this.getSuggestedSlickSettings()}>
              
        {this.state.suggestedPodcasts.map((item: any) => (                
                <div key={item.id}>
                <div style={webStyles.suggestedImgBody}>
                 <Box
                   sx={{...webStyles.followText,
                     backgroundColor: item.attributes.follower.following ? "#030303" : "#FF0807", cursor: "pointer"
                   }}>
                   <Typography style={{ justifySelf: "flex-end", fontWeight:"bold" }} data-test-id="followbtn" onClick={()=>this.handleFollow(item.id,item.attributes.follower.following,item.attributes.follower.follow_id)}>{item.attributes.follower.following ? "Following" : "Follow"}</Typography>
                 </Box>
                 <img data-test-id="imageClick" onClick={()=>this.props.handlePodcastId(item.id)} style={{ ...webStyles.image, height: "223px", cursor: "pointer"}} src={item.attributes.cover_image} alt={item.attributes.name} />
              
                 
                 <div style={webStyles.description}>
                   <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                     {item.attributes.name.toUpperCase()}
                   </Typography>
                   <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                     {`${item.attributes.hosts[0]?.first_name} ${item.attributes.hosts[0]?.last_name}`}
                   </Typography>
                 </div>
                 </div> 
             </div>
        ))}
        </StyledSlider>:
        <Box sx={{padding:"0 2%",textAlign:"center"}}>
        <Typography sx={{fontFamily:"Nexa",fontSize:"20px",fontWeight:400,color:"#FFFFFF"}}>To populate Suggested For You please go into Settings and select your favorite podcast categories</Typography>
       </Box>
          }
          </Box>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    padding:"20px 50px",
    maxHeight: "100%",
  } as const,
  image: {
    objectFit: "cover" as const,
    borderRadius: "8px",
    width: '100%',
    height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
    display: "flex",
    margin: "0 auto",
    border: 'none',
  },
  imgBody: {
    position: 'relative' as const,
    cursor: "pointer"
  },
  sponsoredButton: {
    color: "#fff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  episode: {
    color: "#fff",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600
  },
  sponsoredImgBody: {
    height: "370px",
    width: '100%',
    position: 'relative' as const,
    border: 'none',
  },
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#fff',
    marginBottom: "10px"
  },
  trendingPodcastsContainer: {
    display: "flex", 
   justifyContent: "flex-start", 
   alignItems: "center", 
   flexDirection: "row", 
   flexWrap: "wrap", 
   gap: "24px",
   maxWidth: "100%" },

  suggestedImgBody: {
    width: '227px',
    height: "223px",
    position: 'relative' as const,
    cursor: "pointer"
  },
  description: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "76px",
    backgroundColor: "#44403c",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "16px"
  } as React.CSSProperties,
  insideBoxContainer: {
    width: "227px",
    height: "223px",
    position: "relative",
    background: "green"
  },
  followText: {
    top: "15px",
    right: "15px",
    position: "absolute",
    color: "white",
    borderRadius: "8px",
    padding: "8px"
  }

};
const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 56px;
  }
  .slick-dots li {
    margin: 0px !important;
  }
`;
const StyledSponsoredSliderDiv = styled(Slider)`
  .slick-dots {
    bottom: 40px;
  }
  .slick-dots li {
    margin: 0px !important;
  }
  .slick-dots li button:before {
    color: #B2B3B3; 
    opacity: 1;   
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
    color: #B2B3B3 !important; 
    font-size: 14px; 
  }
`;
// Customizable Area End
