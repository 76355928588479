import React, { Component } from 'react';
import {
    Typography,
    Box,
    Card
    // Customizable Area Start
    // Customizable Area End
  } from "@mui/material";

interface ImageCardProps {
  id?: string;
  imageUrl: string;
  songName: string;
  artist: string;
  following: string | boolean
  onUnfollow: () => void;
  onNavigate: (id: string) => void;
}

class ImageCard extends Component<ImageCardProps> {
  constructor(props: ImageCardProps) {
    super(props);
  }

  render() {
    return (
      <Card sx={{...imageCardStyles.cardContainer}} key={this.props.id} >
        <Box sx={imageCardStyles.imageContainer}>
        {this.props.following? (
            <Box data-test-id="followingTesBtn" onClick={() => this.props.onUnfollow()} sx={{...imageCardStyles.followingText}}
            >
              <Box sx={{ justifySelf: "flex-end", fontWeight:"bold", fontSize: "16px", fontFamily:"Nexa-Heavy"}}>
                Following
              </Box>
            </Box>
          ) : (
            " "
          )}
        <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            data-test-id="navigateToCatalogueTestBox"
            onClick={() => this.props.onNavigate(this.props.id as string)}
            >
            <img
              height= {"224px"}
              width= {"236px"}
              src={`${this.props.imageUrl}`}
              alt={"card image"}
              style={{objectFit:"cover"}}
            />
        </div>
        </Box>
        <div            
        style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px 32px",
              height: "76px"
            }} >
            <Typography sx={{fontWeight:900, fontFamily:"Nexa-Heavy", fontSize:"14px", color:"white",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "200px"
            }}>
                {this.props.artist}
            </Typography>
            <Typography sx={{
              fontWeight:900, fontSize:"14px", fontFamily:"Nexa-Heavy", color:"white",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "200px",
              textOverflow: "ellipsis",
              }}>
            {this.props.songName}
            </Typography>
        </div>
      </Card>
    );
  }
}

export default ImageCard;


export const imageCardStyles = {
  cardContainer: {
      maxWidth: `238px`,
      height:"307px",
      display: "flex",
      flexDirection: "column",
      gap: "0.2rem",
      borderRadius:"16px",
      backgroundColor: "#44403C"
  },
  imageContainer: {
      height:"307px",
      maxWidth: `238px`,
      position: "relative",
  },
  followingText: {
      top: "15px",
      right: "15px",
      position: "absolute",
      background: "black",
      color: "white",
      borderRadius: "8px",
      padding: "8px",
  }
}