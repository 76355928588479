import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { carouselNext, carouselPrev, podcastImage1 } from "./assets";
import { AppContext } from "./context/AppContext";
import React, { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
import { leftArrow, rightArrow } from "../../../blocks/dashboard/src/assets";
export type MediaList = Media[]

export interface Media {
  itmid: string
  type: string
  attributes: { file_info: FileInfo[] }
}

export interface FileInfo {
  title: string
  description: string
  focus_areas: string[]
  urldoc: string
  content_type: string
  text_file_to_str: string
  file_content: string
}

interface Song {
  songTitle: string;
  songSubTitle: string;
  image: string; 
}

interface Episode {
  song_id: number;
  episode_name: string;
  episode_id: string;
  podcast_name: string;
  podcast_id: number;
  episode_number: number;
  cover: string;
  episode_content: string | null;
  run_time: string;
  currently_playing: boolean;
}

interface ContinueListenState {
  main_playlist_episodes: Episode[];
  saved_episodes: Episode[];
}

interface PodcastList {
  id?: string,
  imageUrl: string;
  songName: string;
  artist: string;
  following: string;
  podcastId: string;
}

interface SavedEpisode {
  imageUrl: string;
  selected: boolean;
  added: boolean;
  songName: string;
  artist: string;
  saveable_id: number;
  episodeId: string;
}
interface ArrowProps {
  onClick: () => void;
}

interface ChannelLibraryData {
  episodeId: string;
  name: string;
  description: string;
  cover: string | null;
  episode_content: string | null;
  added: boolean;
  saved: boolean;
  savedId: number;
}

interface ChannelLibraryApiResponse {
  data: {
      id: string;
      type: string;
      attributes: {
          name: string;
          description: string;
          cover: string | null;
          episode_content: string | null;
          save_episode: { saved: boolean, saved_id: number };
          added: { song_added: boolean };
          account: {first_name: string; last_name: string;}
      };
  }[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  handleAddAudio: (value: string, id: string) => void;
  handleCommonPath: (path:string) => void
  handlePodcastId: (id: string) => void
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  mediaList: MediaList;
  header: string;
  loading: boolean;
  documentModal: boolean;
  docUrl: string;
  videoModal: boolean;
  videoUrl: string;
  media: {
    nowPlaying: string;
    duration: number;
    progress: number;
  };
  mediaType: string;
  pageNo: number;
  limit: number;
  totalCount: number;
  currentPageNo: number;
  audioList: MediaList;
  continueListen: ContinueListenState;
  followingPodCasts: PodcastList[];
  savedEpisode: SavedEpisode[];
  channelLibrary: ChannelLibraryData[]
  followingPodcastCurrentPage: number;
  savedPodcastCurrentPage: number;
  refreshKey: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

export default class AudioLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaApiCallId: string = "";
  getSavedPoscastApiCallId: string = "";
  getChannelLibraryApiCallId: string = "";
  getSavedEpisodeApiCallId: string = "";
  followingPodcastitemsPerPage = 6;
  savedPodcastItemsPerPage = 6;
  unsaveEpisodeCallId: string = "";
  saveEpisodeCallId: string = "";
  unFollowPodcastCallId: string = "";
  addToPlayListAPICallId: string = "";
  removeFromPlayListAPICallId: string = "";
  unsaveEpisodeFromChannelCallId: string = "";
  getContinueListeningApiCallId: string = ""
  scrollRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ChannelLibraryResponse)
    ];
    // Customizable Area End
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      mediaList: [],
      audioList: [],
      header: "Lets listen to some audios to make yourself feel better.",
      loading: false,
      documentModal: false,
      docUrl: "",
      videoModal: false,
      videoUrl: "",
      media: {
        nowPlaying: "",
        duration: 0,
        progress: 0
      },
      mediaType: 'audio',
      pageNo: 0,
      limit: 7,
      totalCount: 0,
      currentPageNo: 0,
      continueListen: {
        main_playlist_episodes: [],
        saved_episodes: [],
      },
      followingPodCasts : [],
      savedEpisode: [],
      channelLibrary: [],
      followingPodcastCurrentPage: 0,
      savedPodcastCurrentPage: 0,
      refreshKey: ""
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      if (apiRequestCallId === this.getMediaApiCallId) {
        if (responseJson && responseJson?.data) {
          this.setState(prev => ({
            totalCount: responseJson?.data[0].attributes?.count,
            audioList: responseJson?.data,
            mediaList: (prev.pageNo === 0) ?
              responseJson?.data : [],
            loading: false
          }));
        }
      }

    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson: ChannelLibraryApiResponse  = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.getChannelLibraryApiCallId) {
        const episodes = responseJson.data.map((item) => {
          const attributes = item.attributes;
          return {
              name: attributes.name,
              description: `${attributes.account.first_name || ''} ${attributes.account.last_name || ''}`.trim(),
              cover: attributes.cover,
              episode_content: attributes.episode_content,
              added: attributes.added.song_added,
              saved: attributes.save_episode.saved,
              savedId: attributes.save_episode.saved_id,
              episodeId: item.id
          };
      });
      this.setState({channelLibrary: episodes})
      }
      
      if(apiRequestCallId === this.getSavedPoscastApiCallId) {
        const transformedData = responseJson.data.map((podcast:any) => {
          const id = podcast.id;
          const attributes = podcast.attributes.followable.data.attributes;
          const categoryImage = attributes.cover_image;
          const host = attributes.hosts[0];
          const following = attributes.follower.following;
          return {
            id: id,
            imageUrl: categoryImage,
            songName: `${host?.first_name} ${host?.last_name}`,
            artist: attributes.name,
            following: following,
            podcastId: podcast.attributes.followable.data.id
          };
        });

        this.setState({ followingPodCasts: transformedData });
      }

      if(apiRequestCallId === this.getSavedEpisodeApiCallId) {
        const formattedData = responseJson.data.map((item:any) => {
          const saveable = item.attributes?.saveable?.data?.attributes;
          return {
            saveable_id: item.id,
            imageUrl: saveable.cover,
            added: saveable.added?.song_added,
            songName: saveable.name,
            selected: saveable.save_episode?.saved,
            artist: `${saveable.account?.first_name || ''} ${saveable.account?.last_name || ''}`.trim(),
            episodeId: item.attributes?.saveable?.data?.id
          };
        });
        this.setState({ savedEpisode: formattedData });
      }

      if(this.unsaveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForUnSaveEpisode(message);
      }
      if(this.addToPlayListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForAddToPlayList(message);
      }
      if(this.unFollowPodcastCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForUnFollowPodcast(message)
      }
      if(this.saveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForSaveEpisodeFromChannelLibrary(message);
      }
      if(this.removeFromPlayListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForRemoveFromPlayList(message);
      }
      if(this.unsaveEpisodeFromChannelCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForUnSaveEpisodeInChannel(message);
      }

      if(this.getContinueListeningApiCallId === apiRequestCallId) {
        this.handleContinueListeningResonse(from, message)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType? = AppContext;

  handleSavedEpisode = () => {
    this.props.handleCommonPath("SavedEpisode")
  }

  handlePodCastFollowing = () => {
    this.props.handleCommonPath("PodCastFollowing")
  }

  handleChannelLibrary = () => {
    this.props.handleCommonPath("ChannelLibrary")
  }

  handleResForUnFollowPodcast  = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unfollowed") {
      this.getSavedPodCastList();                  
    }
  };

  handleResForUnSaveEpisode  = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unsaved") {
      this.getSavedEpisode();
      this.getPodCastList();                 
    }
  };

  handleResForUnSaveEpisodeInChannel = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unsaved") {
      this.getSavedEpisode();
      this.getPodCastList();                 
    }
  };

  handleResForSaveEpisodeFromChannelLibrary = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.meta.message == "Successfully Saved") {
      this.getPodCastList();          
      this.getSavedEpisode();
    }
  };

  handleResForRemoveFromPlayList = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Song removed successfully") {
      if(this.state.refreshKey === "SavedEpisode") {
      this.getSavedEpisode();
      this.setState({refreshKey: ""})
      }

      if(this.state.refreshKey === "ChannelLibrary") {
        this.setState({refreshKey: ""})
        this.getPodCastList();
      }
    }
  }

  handleResForAddToPlayList = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Song added successfully") {
      if(this.state.refreshKey === "SavedEpisode") {
        this.setState({refreshKey: ""})
        this.getSavedEpisode();                  
      }

      if(this.state.refreshKey === "ChannelLibrary") {
        this.setState({refreshKey: ""})
        this.getPodCastList();
      }
    }
  }

  navigateTo(screen:string){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
  }

  getSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    const { channelLibrary } = this.state;
  
    return(
       {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: Math.min(channelLibrary.length, 5), 
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
        appendDots: (dots:any) => (
          <div>
            <ul style={{ display: "flex", justifyContent: "center" }}>
              {dots.slice(0, 3)} 
            </ul>
          </div>
        ),
        responsive: [
          {
            breakpoint: 1920,
            settings: { slidesToShow: Math.min(channelLibrary.length, 6), slidesToScroll: 1 },
          },
          {
            breakpoint: 1700,
            settings: { slidesToShow: Math.min(channelLibrary.length, 5), slidesToScroll: 1 },
          },
          {
            breakpoint: 1440,
            settings: { slidesToShow: Math.min(channelLibrary.length, 4), slidesToScroll: Math.min(4, channelLibrary.length) },
          },
        ],
       }
    )
  }

  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px",
        borderRadius: "50%",
        background: "#FF0807", 
        position: 'absolute',
        right: "-2%",
        top:"40%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1
    }}>
    <img 
      src={rightArrow} 
      style={{
        width:"100%",
        height:"100%"
      }} 
      alt="Arrow"
    />
    </div>
    )
  }

  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top:"40%",
        left: "-2%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    )
  }

  getContinueListening = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContinueListeningApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.audioContinueListening
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleContinueListeningResonse = async (from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    console.log(apiResponse, "apiResponse")
    this.setState({continueListen: apiResponse})
  }


  async componentDidMount(): Promise<void> {
    this.getSavedPodCastList();
    this.getSavedEpisode();
    this.getMedia();
    this.getPodCastList();
    this.getContinueListening()


    const msag: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msag);
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  handlePagination = (event: Object, value: number) => {
    this.setState({ currentPageNo: value });
    this.getMedia(value);
  }

  networkRequest = ({
    endPoint,
    method,
    headers,
    body,
    newState
  }: {
    endPoint: string;
    headers?: Record<string, string>;
    body?: Record<string, string>;
    method: "GET" | "POST" | "PUT" | "DELETE";
    newState?: Partial<S>;
  }) => {
    if (typeof newState === "object") {
      this.setState(prev => ({ ...prev, ...newState }));
    }
    const defaultHeaders = {
      'Content-Type': configJSON.exampleApiContentType,
      token: (this.context as any).state?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers || defaultHeaders)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getMedia = (currentPageNo?: number) => {
    let strBody = {
      limit: `${this.state.limit}`,
      page: `${currentPageNo || this.state.pageNo}`
    }
    let urlParams = new URLSearchParams(strBody).toString();
    this.getMediaApiCallId = this.networkRequest({
      endPoint: `audio_list?${urlParams}`,
      method: "POST",
      newState: { loading: false }
    });
  }

  fetchMoreData = () => {
    if (
      (this.state.pageNo >= this.state.totalCount) ||
      this.state.loading
    ) {
      return;
    }
    this.setState(prevState => ({
      pageNo: prevState.pageNo + 1
    }), () => this.getMedia());
  }

  getPodCastList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChannelLibraryApiCallId = requestMessage?.messageId

    let podcast_id = await getStorageData("podcast_id");

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    // let podcast_id = 83;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/podcasts/followed_podcast_episodes`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyMediaURlPresent = (channelData: ChannelLibraryData[]): boolean => {
    return channelData.every((item: ChannelLibraryData) => item.episode_content !== null);
  };

  goBackcalling = () => {
    this.props.navigation.goBack()
  }

  handleUnFollowPodcast = async(id:string | undefined) =>{
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unFollowPodcastCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unFollowPodcast}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleAddToSaveLaterInChannelLibrary(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleRemoveFromSaveLaterInChannelLibrary(id: number) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsaveEpisodeFromChannelCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleRemoveSaveLater(id: number) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsaveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleAddToPlayList(id: string, refreshApi: string) {

    this.setState({refreshKey: refreshApi})

    let token = await getStorageData("token");
    let playListId = await getStorageData("main_playlist_id");

    const header = {
      token: JSON.parse(token),
    };

    let formdata = new FormData();
    formdata.append("id", playListId);
    formdata.append("song_id", `${id}`);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToPlayListAPICallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToPlayList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleRemoveFromPlayList(id: string, refreshApi: string) {
    this.setState({refreshKey: refreshApi})

    let token = await getStorageData("token");
    let playListId = await getStorageData("main_playlist_id");

    const header = {
      token: JSON.parse(token),
    };

    let formdata = new FormData();
    formdata.append("id", playListId);
    formdata.append("song_id", `${id}`);
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFromPlayListAPICallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromPlayList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getSavedPodCastList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedPoscastApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/followers?type=podcast`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSavedEpisode = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedEpisodeApiCallId = requestMessage?.messageId

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/saved_episodes?type=episode`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFollowingPodCastData = () => {
    const startIndex = this.state.followingPodcastCurrentPage * this.followingPodcastitemsPerPage;
    const followingPodCastData =  this.state.followingPodCasts.slice(startIndex, startIndex + this.followingPodcastitemsPerPage);
    return followingPodCastData;
  }

  handleDotClick = (index:any) => {
    this.setState({ followingPodcastCurrentPage: index });
  };

  handleSliderNextPodcastCardClick = () => {
    const { followingPodcastCurrentPage, followingPodCasts } = this.state;
    const totalPages = Math.ceil(followingPodCasts.length / this.followingPodcastitemsPerPage);
    if (followingPodcastCurrentPage < totalPages - 1) {
      this.setState({ followingPodcastCurrentPage: followingPodcastCurrentPage + 1 });
    }
  };

  handleSliderPreviousPodcastCardClick = () => {
    const { followingPodcastCurrentPage } = this.state;
    if (followingPodcastCurrentPage > 0) {
      this.setState({ followingPodcastCurrentPage: followingPodcastCurrentPage - 1 });
    }
  };

  handleSavedPostDotClick = (index:any) => {
    this.setState({ 
      savedPodcastCurrentPage: index 
    });
  };

  handleSliderNextSavedPostClick = () => {
    const { savedPodcastCurrentPage, savedEpisode } = this.state;
    const totalPages = Math.ceil(savedEpisode.length / this.savedPodcastItemsPerPage);
    if (savedPodcastCurrentPage < totalPages - 1) {
      this.setState({ savedPodcastCurrentPage: savedPodcastCurrentPage + 1 });
    }
  };

  handleSliderPreviousSavedPostClick = () => {
    const { savedPodcastCurrentPage } = this.state;
    if (savedPodcastCurrentPage > 0) {
      this.setState({ savedPodcastCurrentPage: savedPodcastCurrentPage - 1 });
    }
  };

  getSavedEpisodeData = () => {
    const startIndex = this.state.savedPodcastCurrentPage * this.savedPodcastItemsPerPage;
    const savedEpisode =  this.state.savedEpisode.slice(startIndex, startIndex + this.savedPodcastItemsPerPage);
    return savedEpisode;
  }

  totalPodcastPages = () => {
    const data = Math.ceil(this.state.followingPodCasts.length / this.followingPodcastitemsPerPage) - 1
    return this.state.followingPodcastCurrentPage === data;
  }

  totalSavedEpisodePages = () => {
    const data = Math.ceil(this.state.savedEpisode.length / this.savedPodcastItemsPerPage) - 1;
    return this.state.savedPodcastCurrentPage === data
  }

  getSavedEpiosdeSlickSettings() {
    const innerWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    const { savedEpisode } = this.state;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: Math.min(savedEpisode.length, 5), // Prevent overflow
      slidesToScroll: 1,
      swipeToSlide: true,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSavedArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSavedArrow, { onClick: () => {}, innerWidth }),
      appendDots: (dots:any) => (
        <div>
          <ul style={{ display: "flex", justifyContent: "center" }}>
            {dots.slice(0, 3)} 
          </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1920,
          settings: { slidesToShow: Math.min(savedEpisode.length, 6), slidesToScroll: 1 },
        },
        {
          breakpoint: 1700,
          settings: { slidesToShow: Math.min(savedEpisode.length, 5), slidesToScroll: 1 },
        },
        {
          breakpoint: 1440,
          settings: { slidesToShow: Math.min(savedEpisode.length, 4), slidesToScroll: Math.min(4, savedEpisode.length) },
        },
      ],
    };
  }
  
  
  prevSavedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top:"40%",
        left: "-2%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    )
  }

  nextSavedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px",
        borderRadius: "50%",
        background: "#FF0807", 
        position: 'absolute',
        right: "-2%",
        top:"40%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1
    }}>
    <img 
      src={rightArrow} 
      style={{
        width:"100%",
        height:"100%"
      }} 
      alt="Arrow"
    />
    </div>
    )
  }

  getPodcastsSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    const { followingPodCasts } = this.state;
  
    return {
      dots: true,
      infinite: false, 
      speed: 800,
      slidesToShow: Math.min(followingPodCasts.length, 5), 
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextPodcastArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevPodcastArrow, { onClick: () => {}, innerWidth }),
      appendDots: (dots:any) => (
        <div>
          <ul style={{ display: "flex", justifyContent: "center" }}>
            {dots.slice(0, 3)} 
          </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1920,
          settings: { slidesToShow: Math.min(followingPodCasts.length, 6), slidesToScroll: 1 },
        },
        {
          breakpoint: 1700,
          settings: { slidesToShow: Math.min(followingPodCasts.length, 5), slidesToScroll: 1 },
        },
        {
          breakpoint: 1440,
          settings: { slidesToShow: Math.min(followingPodCasts.length, 4), slidesToScroll: Math.min(4, followingPodCasts.length) },
        },
      ],
    };
  }

  prevPodcastArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top:"40%",
        left: "-2%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    )
  }

  nextPodcastArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", 
        width: "50px",
        borderRadius: "50%",
        background: "#FF0807", 
        position: 'absolute',
        right: "-2%",
        top:"40%",
        transform: 'translateY(-40%)',
        cursor: 'pointer',
        zIndex: 1
    }}>
    <img 
      src={rightArrow} 
      style={{
        width:"100%",
        height:"100%"
      }} 
      alt="Arrow"
    />
    </div>
    )
  }


  // Customizable Area End
}
