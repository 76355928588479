import React, { Component } from 'react';
import { Box, Typography, Button, Dialog, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import Divider from '@mui/material/Divider'
import { upload } from "../../../../blocks/portfoliomanagement/src/assets";

interface IplaylistInfo {
    title: string;
    image: string | null;
    id: string;
    selectedFile: File | null;
}
  

interface EditPlayListProps {
    open: boolean;
    close: () => void;
    playlistInfo: IplaylistInfo;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleCancelImage: () => void;
    handleSave: () => void;
    isLoading: boolean;
    disableEdit?: boolean;
}

class EditPlayList extends Component<EditPlayListProps> {
    constructor(props: EditPlayListProps) {
      super(props);
    }
  
    render() {
        console.log(this.props.playlistInfo, "playlistInfo")
        return (
            <>
            <Dialog
                open={true}
                onClose={() => {}}
                slotProps={{
                  backdrop: {
                    sx: {
                      backdropFilter: 'blur(5px)', // Apply blur effect
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  },
                }}
                sx={{
                  "& .MuiDialog-paper": {
                    maxWidth: "800px",
                    width: "600px",
                    background: "#44403C",
                    borderRadius: '8px 8px 32px 8px',
                  },
                }}
                data-test-id="PlaylistModal"
              >
                <Box onClick = {this.props.close} display={"flex"} justifyContent={"flex-end"} padding={3}>
                    <CloseIcon sx={{color:"white"}} />
                </Box>
                <Box padding={"16px 40px"}>

                <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"16px"}}> 
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                        <EditIcon /> Edit Playlist
                    </Box>
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography sx={{...webStyles.title, fontSize:"14px"}} component="label">
                        Name of playlist
                    </Typography>
                    <TextField
                        data-test-id="playlistName"
                        value={this.props.playlistInfo?.title}
                        onChange={this.props.handleInputChange} 
                        variant="outlined"
                        InputProps={{
                            readOnly: this.props.disableEdit, 
                          }}
                        sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: '#030303', 
                            borderRadius: "8px",
                            color: "white !important", 
                            fontFamily: "Nexa-Heavy",
                            fontWeight: 900,
                            fontSize: "14px",
                            opacity: 1 
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                      
                        }}
                    />
                </Box>

                <Typography sx={{...webStyles.title, fontSize:"14px",}}>Playlist photo (optional)</Typography>

                <Box display={"flex"} gap={2}>
                {this.props.playlistInfo.image && (
                    <Box padding={2} height={"160px"} width={"160px"} borderRadius={"6px"} bgcolor={"#312F2D"}>
                        <Box display={"flex"} justifyContent={"flex-end"} data-test-id="unSelectImage" onClick={this.props.handleCancelImage}> 
                            <CloseIcon sx={{color:"white"}} /> 
                        </Box>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                                <img style={{width:"89px", height:"89px", borderRadius:"6px", objectFit:"cover"}} src={this.props.playlistInfo.image} alt="selected image" />
                                {this.props.playlistInfo.selectedFile && (
                                    <>
                                    <Typography color={"white"} fontFamily={"Inter"} fontSize={"1rem"} fontWeight={400}>{this.props.playlistInfo.selectedFile?.name}</Typography>
                                    <Typography color={"white"} fontFamily={"Inter"} fontSize={"12px"} fontWeight={400}>{(this.props.playlistInfo.selectedFile?.size / 1024).toFixed(2)} KB</Typography>
                                    </>
                                )}
                            </Box>
                    </Box>
                )}
                    <Box
                        sx={{
                            width: this.props.playlistInfo?.image ? "340px" : "600px",
                            backgroundColor: '#312F2D',
                            padding: '16px',
                            color: 'white',
                            textTransform: 'none',
                            height: "160px",
                            border: "3px dashed white",
                            borderRadius: '8px',
                        }}
                        >
                        <Box
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                            gap={2}
                            height={"160px"}
                        >
                            <img src={upload} alt="upload" style={{ width: "24px", height: "24px" }} />
                            <Box
                            sx={{
                                textAlign: 'center',
                                fontWeight: 700,
                                fontFamily: 'Nexa-heavy',
                                fontSize: '14px',
                            }}
                            >
                            Drag & drop your file here
                            </Box>

                            <Button
                            component="label"
                            sx={{
                                color: "white",
                                border: "none",
                                background: "#FF0807",
                                fontFamily: 'Nexa-heavy',
                                "&:hover": {
                                background: "#FF0807",
                                },
                                textTransform: "none",
                                padding: "8px 16px",
                            }}
                            
                            >
                            Browse file
                            <input
                                data-test-id="file-input"
                                hidden
                                type="file"
                                onChange={this.props.handleFileChange}
                            />
                            </Button>
                        </Box>
                    </Box>
                    
                </Box>
                </Box>
                <Divider sx={{ my: 2, borderColor: 'white' }} />

                <Box m={2} display={"flex"} gap={2} padding={2} justifyContent={"flex-end"}>
                    <Button
                    data-test-id="cancel"
                    onClick={this.props.close}
                    sx={{
                    ...buttonStyles,
                    border: "none",
                    color: "white",
                    background: "black",
                    height:"56px",
                    "&:hover": {
                        background: "black", 
                    }
                    }}
                    variant="contained"
                    >
                        Cancel
                    </Button>

                    
                        <Button
                        data-test-id="saveBtn"
                        onClick={this.props.handleSave}
                        disabled={!this.props.playlistInfo?.image || !this.props.playlistInfo.title}
                        sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "#FF0807" ,
                        height:"56px",
                        "&:hover": {
                            background:  "#FF0807" ,
                        },
                        "&.Mui-disabled": {
                            backgroundColor: "#B2B3B3", 
                            color: "white",
                        },
                        }}
                        variant="contained"
                        >
                            { this.props.isLoading ? "Saving..." : "Save"}
                        </Button>
                       
                    
                </Box> 
                </Dialog>
            </>
        )
    }
}

export default EditPlayList;

const webStyles = {
    container: {
      padding:"20px 50px",
      overflowX: "clip",
      overflowY: "auto",
      maxWidth: "100%",
    } as const,
    title : {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    }
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    height: "34px",
};