// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import { createRef } from "react";
import { EpisodeDetails } from "./HostSetupRssFeedController";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  isLinkValid: boolean,
  podcastData: Podcast,
  openFirstDialog: boolean,
  openSecondDialog: boolean,
  selectedFile: File | null,
  imagePreviewUrl: string,
  tempLogoUrl: string,
  openFinishSetupDialog: boolean,
  storageData: Object,
  invalidFileMessage: string,
  loading: boolean
}

export interface SS {
  id: any;
}

interface Podcast {
  podcastName: string,
  podcastCover: string,
  subtitle: string;
  description: string,
  backgroundColor: string,
  secondaryColor: string,
  textColor: string,
  buttonColor: string
}

export default class TemplatePreviewWithLogoController extends BlockComponent<
  Props,
  S,
  SS
> {
  createPodcastCallId: string = "";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      podcastData: {
        podcastName: '',
        podcastCover: '',
        subtitle: '',
        description: '',
        backgroundColor: '',
        secondaryColor: '',
        textColor: '',
        buttonColor: '',
      } as Podcast,
      openFirstDialog: true,
      openSecondDialog: false,
      selectedFile: null,
      imagePreviewUrl: "",
      tempLogoUrl: "",
      openFinishSetupDialog: false,
      storageData: {},
      invalidFileMessage: "",
      loading: false
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    let feedName = await getStorageData('feedName');
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData = storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    let logoUrl = data['logoUrl'];
    this.setState({
      imagePreviewUrl: logoUrl || '',
      podcastData: {
        ...this.state.podcastData,
        podcastName: data['podcastName'] || 'The True Podcast',
        podcastCover: data['podcastCover'],
        subtitle: data['subtitle'] || 'The Podcast for how to win at business and other stuff all the time',
        description: data['description'] || 'COST OF WINNING',
        backgroundColor: data['backgroundColor'] || '#312F2D',
        secondaryColor: data['secondaryColor'] || '#44403C',
        textColor: data['textColor'] || '#fff',
        buttonColor: data['buttonColor'] || "#FF0807"
      }
    });
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    // Customizable Area End
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleCreatePodcastResponse(from, message);
    // Customizable Area End
  }

  handleCloseFirstDialog = () => {
    this.setState({ openFirstDialog: false });
  };

  handleOpenSecondDialog = () => {
    this.setState({
      openFirstDialog: false,
      openSecondDialog: true
    });
  };

  handleCloseSecondDialog = () => {
    this.setState({ openSecondDialog: false });
  };
  handleDoneClick = () => {
   let imageUrl = this.state.selectedFile ? URL.createObjectURL(this.state.selectedFile) : '';
   this.setState({imagePreviewUrl: imageUrl});
   this.setState({ openSecondDialog: false });
  }
  handleCancelClick = () => {
    this.setState({selectedFile: null, imagePreviewUrl: "", tempLogoUrl: ""})
    this.setState({ openSecondDialog: false });
  }
  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      const fileInputEvent = {
        target: {
            files: files,
        },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    this.handleFileChange(fileInputEvent);
    }
  };
  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    let validTypes = ['image/jpg', 'image/png', 'image/jpeg'];
    let maxFileSize = 200 * 1024 * 1024;
    if (file) {
      if (!validTypes.includes(file.type)) {
        this.setState({ invalidFileMessage: "File must be a jpg or png image." });
        return;
      }
      if (file.size > maxFileSize) {
        this.setState({ invalidFileMessage: "File must be 200MB or less." });
        return;
      }
      else {
        this.setState({ invalidFileMessage: "" });
      }
      let imageUrl = URL.createObjectURL(file);
      this.setState({ selectedFile: file, tempLogoUrl: imageUrl });
      const base64String = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
      });
      await this.updateFeedData(base64String);
    };
  };
  handleCloseFinishSetupDialog = () => {
    this.setState({  openFinishSetupDialog: false  });
  }
  updateFeedData = async (url: string) => {
    let feedName = await getStorageData('feedName');
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData = storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    data['logoUrl'] = url;
    storageKey && setStorageData(storageKey, JSON.stringify(data));

  }

  convertMillisecondsToTime = (millisecondsString: string) => {
    const totalSeconds = Math.floor(parseInt(millisecondsString, 10) / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  createPodcastApi = async () => {
    let token = await getStorageData("token");
    let feedName = await getStorageData("feedName");
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', 'noRssData');
    let storageData = storageKey && await getStorageData(storageKey);
    let data = await storageData && JSON.parse(storageData);
    this.setState({ storageData: data })
    const header = {
      token: JSON.parse(token),
    };
    let podcastCoverImage = await this.convertBlobUrlToFile(data?.podcastCover);
    let formData = new FormData();
    if (feedName == "noRss") {
      formData.append('podcast[name]', data.podcastName);
      formData.append('podcast[subtitle]', data.subtitle);
      formData.append('podcast[description]', data.description);
      formData.append('podcast[location]', data.podcastLocation);
      formData.append('podcast[explicit_lang]', ResolveCondition(data.explicitLanguage === 'N', "false", "true") || "false");
      formData.append('podcast[adult]', ResolveCondition(data.restriction === '1', "true", "false") || "false");
      formData.append('podcast[timezone]', JSON.stringify(data.timezone));
      formData.append('podcast[podcast_time]', JSON.stringify(data.publishTime));
      formData.append('podcast[host_type]', data.showHosting);
      formData.append('podcast[category_ids][]', data.podcastCategory);
      formData.append('podcast[sub_category_ids][]', data.podcastSubCategory);
      formData.append('podcast[hosts_attributes][0][first_name]', data.hostFirstName);
      formData.append('podcast[hosts_attributes][0][last_name]', data.hostLastName);
      formData.append('podcast[hosts_attributes][0][email]', data.hostEmail);
      formData.append('podcast[hosts_attributes][0][host_location]', data.hostLocation);
      
      data.hosts.forEach((data:any, index: number) => {
        formData.append(`podcast[hosts_attributes][${index + 1}][first_name]`, data.first_name);
        formData.append(`podcast[hosts_attributes][${index + 1}][last_name]`, data.last_name);
        formData.append(`podcast[hosts_attributes][${index + 1}][host_location]`, data.host_location);
        formData.append(`podcast[hosts_attributes][${index + 1}][email]`, data.email_address);
        formData.append(`podcast[hosts_attributes][${index+ 1}][bio]`, data.bio);
      });
      formData.append('podcast[color_scheme_attributes][bg_color]', data.backgroundColor);
      formData.append('podcast[color_scheme_attributes][secondry_color]', data.secondaryColor);
      formData.append('podcast[color_scheme_attributes][text_color]', data.textColor);
      formData.append('podcast[color_scheme_attributes][button_color]', data.buttonColor);
      formData.append('podcast[color_scheme_attributes][template_type]', data.selectedTemplate);
      this.state.selectedFile && formData.append('podcast[color_scheme_attributes][logo]', this.state.selectedFile);
      podcastCoverImage && formData.append('podcast[cover_image]', podcastCoverImage);
    } else if (feedName === "rss") {
      formData.append('podcast[cover_image]', data.podcastCover);
      formData.append('podcast[name]', data.podcastName);
      formData.append('podcast[hosts_attributes][0][first_name]', data.hostName.toString().split(" ")[0]);
      formData.append('podcast[hosts_attributes][0][last_name]', data.hostName.toString().split(" ")[1] || "");
      formData.append('podcast[description]', data.description);
      formData.append('podcast[channel_url]', data.website);
      formData.append('podcast[explicit_lang]', data.podcastLanguage);
      formData.append('podcast[category_ids][]', data.podcastCategory);
      formData.append('podcast[subtitle]', data.podcastSubtitle);
      formData.append('podcast[hosts_attributes][0][bio]', data.podcastOwnerInfo);
      formData.append('podcast[rss_url]', data.podcastFeedRedirection);

      data.episodes.forEach((episode: EpisodeDetails, index: number) => {
        formData.append(`episode[${index}].name`, episode.title);
        formData.append(`episode[${index}].episode_content`, episode.audio_track);
        formData.append(`episode[${index}].episode_type`, episode.episode_type.toString());
        formData.append(`episode[${index}].podcast_season`, episode.season_number.toString());
        formData.append(`episode[${index}].episode_number`, episode.episode_number.toString());
        formData.append(`episode[${index}].publish_date`, episode.publication_date);
        formData.append(`episode[${index}].episode_time`, this.convertMillisecondsToTime(episode.episode_length.toString()));
        formData.append(`episode[${index}].explicit_content`, episode.content_rating.toString().toLowerCase() === "explicit" ? "true" : "false");
        formData.append(`episode[${index}].description`, episode.description);
      });

      formData.append('podcast[color_scheme_attributes][bg_color]', data.backgroundColor);
      formData.append('podcast[color_scheme_attributes][secondry_color]', data.secondaryColor);
      formData.append('podcast[color_scheme_attributes][text_color]', data.textColor);
      formData.append('podcast[color_scheme_attributes][button_color]', data.buttonColor);
      formData.append('podcast[color_scheme_attributes][template_type]', data.selectedTemplate);
      this.state.selectedFile && formData.append('podcast[color_scheme_attributes][logo]', this.state.selectedFile);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createPodcastCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPodcast
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCreatePodcastResponse = async (from: string, message: Message) => {
    if (this.createPodcastCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.setState({ openFinishSetupDialog: true, loading: false });
        setStorageData("podcast_id", responseJson.data.id);
        let feedName = await getStorageData('feedName');
        let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')));
        await removeStorageData("feedName");
        storageKey && await removeStorageData(storageKey);
      }
    }
  };
  convertBlobUrlToFile = async (blobUrl: string, ): Promise<File> => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], "fileName", { type: blob.type }); 
  };
  
  handleFinishSetup = () => {
    this.setState({ loading: true })
    this.createPodcastApi();
  }
}
// Customizable Area End
