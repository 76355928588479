import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData  } from "framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
export interface EditCategories{
  title : string;
  id : string ; 
  children : React.ReactNode
}
interface Chapter {
  newChapterImage: object | null | string |undefined ;
  startTime: string;
  endTime: string;
}

interface ContentepisodeData
 { 
      name:string,
      description:string
      cover : string
}

 
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    name : string
    accordionIds: string[],
    contentepisodeData : ContentepisodeData,
    openSuccess : boolean,
    data: {
      episodeTitle: string,
      episodeShowNotes: string,
      episodeType: string,
      seasonNumber : number,
      episodeNumber: number,
      episodeExploration: boolean,
      setEpisodeExpiryDate: boolean,
      episodeExpiryDay: string,
      episodeExpiryMonth: string,
      episodeExpiryYear: string,
      episodePublishDay: string,
      episodePublishMonth: string,
      episodePublishYear: string,
      episodePublishHour: string,
      episodePublishMin: string,
      isPremiumEpisode: boolean,
      isEpisodeContainExplicit: boolean,
      isEpisodeAdultOnly: boolean,
      isAdsInEpisode: boolean,
      adsType: string,
      coverImageFile :File,
      adsCountInPreRoll: number,
      adsCountInPostRoll: number,
      additionalPhotos: boolean;
      chapters: Chapter[];
      invalidChapterMessage: string[];
      additionalImages: string[];
      fileerror : boolean;
      files: unknown[],
      imageUrl: string,
      selectedFile: any ,
      serverFileUrl:any,
      fileURL:any,
      invalidFileMessage : string
    },
  isPlaying: boolean;
  currentTime: number;
  duration: number;
  volume: number;
  audio_track : string;
   
  // Customizable Area End
}
interface SS {
  id: any;
}
  
export default class EditEpisodeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getfileurlApiCallId: string="";
    addUpdateEpisodeApiCallId : string = "";
    editEpisodesCallId : string = "";
    uploadChapterEditApiCallId: string = "";
    audioRefs: React.RefObject<HTMLAudioElement>
    // Customizable Area End
    
    constructor(props: Props) {
      super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.audioRefs = React.createRef();
    this.state = {
      name : "",
      accordionIds: [],
      contentepisodeData : {name:'', description:'' , cover : ""},
      openSuccess : false,
      data : {
        episodeTitle: "",
        episodeShowNotes: "",
        episodeType: "",
        seasonNumber : 0,
        episodeNumber: 0,
        episodeExploration: false,
        setEpisodeExpiryDate: false,
        episodeExpiryDay: "",
        episodeExpiryMonth: "",
        episodeExpiryYear: "",
        episodePublishDay: "",
        episodePublishMonth: "",
        episodePublishYear: "",
        episodePublishHour: "",
        episodePublishMin: "",
        isPremiumEpisode: false,
        isEpisodeContainExplicit: false,
        isEpisodeAdultOnly: false,
        isAdsInEpisode: true,
        adsType: "audio",
        coverImageFile: new File([""], ""),
        adsCountInPreRoll: 0,
        adsCountInPostRoll: 0,
        additionalPhotos: false,
        chapters: [{ newChapterImage: {}, startTime: "", endTime: "" }],
        invalidChapterMessage: Array(20).fill(""),
        additionalImages: [],
        files: [],
        imageUrl: "",
        selectedFile: null,
        serverFileUrl: null,
        fileURL:"",
        fileerror: false,
        invalidFileMessage : ""
      },
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      volume: 1,
      audio_track : "",
    };
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.editEpisodesCallId != null &&
      this.editEpisodesCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    
      if (responseJson && !responseJson.errors) {
        this.setState({ audio_track :responseJson.data.attributes.episode_content , contentepisodeData : responseJson.data.attributes, data: { ...this.state.data, 
          episodeTitle: responseJson.data.attributes.podcast_name, 
          episodeShowNotes: responseJson.data.attributes.description,
          episodeType : responseJson.data.attributes.episode_type, 
          episodeNumber : responseJson.data.attributes.episode_number,
          isPremiumEpisode: responseJson.data.attributes.premium,
          isEpisodeContainExplicit :responseJson.data.attributes.explicit_content, 
          isEpisodeAdultOnly : responseJson.data.attributes.adult,
          
        }})
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadChapterEditApiCallId != null &&
      this.uploadChapterEditApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    
      if (responseJson && !responseJson.errors) {
        this.setState({openSuccess : true})
      }
    }
    // Customizable Area End
  }

    // Customizable Area End
   

  async componentDidMount() {
    super.componentDidMount();
    if (this.audioRefs.current) {
      this.audioRefs.current.removeEventListener('timeupdate', this.handleTimeUpdate);
      this.audioRefs.current.removeEventListener('loadedmetadata', this.handleLoadedMetadata);
    }
    this.getPodcastEpisodes()
  }

  // Customizable Area Start

  toggleAccordionId = (currentAccordionId: string) => {
    const currentAccordionIdIndex = this.state.accordionIds.findIndex((accordionId: string) => accordionId === currentAccordionId)
    const accordionIds = [...this.state.accordionIds]
    if (currentAccordionIdIndex === -1) {
      accordionIds.push(currentAccordionId)
    } else {
      accordionIds.splice(currentAccordionIdIndex, 1)
    }
    this.setState({ ...this.state,accordionIds})
  }

  toggleAdditionalPhotos = () => this.setState((prevState) => ({
    data: {
      ...prevState.data,
      additionalPhotos : !this.state.data.additionalPhotos
    }
  }))  

  handleDataChange = (key: string, value: string | number | boolean) => {
    this.setState({data: {...this.state.data, [key]: value}})
  }

  handleChapterChange = (index : number , key : string ,value: string | number | boolean ) => {
    const updatedChapters = [...this.state.data.chapters];
    updatedChapters[index] = { ...updatedChapters[index], [key]: value };
    this.setState({data : {...this.state.data , chapters : updatedChapters}});
  }

 
  getMaxDaysInMonth = (month : number , year : number)  => {
    const dayInMonth = [31,(this.isLeapYear(year) ? 29 : 28), 31,30,31,30,31,31,30,31,30,31]
    return dayInMonth[month -1]
 }

 isLeapYear = (year : number) => {
   return( year % 4 === 0 && year % 100 !== 0 ) || (year % 400 === 0 );
 }


 getShortFileName = (fileName:any) => {
  const MAX_LENGTH = 30; 
  const fileExtension = fileName.slice(fileName.lastIndexOf('.')); 
  const fileBaseName = fileName.slice(0, fileName.lastIndexOf('.')); 

  if (fileBaseName.length > MAX_LENGTH) {
    return `${fileBaseName.slice(0, MAX_LENGTH)}...${fileExtension}`;
  }
  return fileName; 
};


 handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files ? Array.from(event.target.files) : [];
  event.target.value = "";
  if(files.length){
    const selectedFile = files[0];
    const allowedTypes = ['audio/mpeg', 'video/mp4'];
    if (!allowedTypes.includes(selectedFile.type)) {
      this.setState(prevState => ({
        data: { ...prevState.data, fileerror: true }
      }));
      return;
    }
    else{
      this.setState(prevState => ({
        data: { ...prevState.data, fileerror: false }
      }));
    }
    const fileUri = URL.createObjectURL(selectedFile);
    this.setState(prevState => ({
      data: { ...prevState.data,  fileURL: fileUri , selectedFile }
    }));
    this.handleFile(selectedFile);
    await this.getfileURL(selectedFile);
  }
};

handleFile = (file: File) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64String = reader.result as string;
    this.setState(prevState => ({
      data : {...prevState.data, imageUrl : base64String  , selectedFile : file}
    }));  
  };
  reader.readAsDataURL(file);
};


clearFileSelection = () => {
  this.setState(prevState => ({data : {...prevState.data , selectedFile : null , imageUrl : "" }}));
};


getfileURL = async (file: File) => {
  try {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body = {
      file_name: file.name
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getfileurlApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.geturl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } catch (error) {
    console.error("Failed to fetch presigned URL:", error);
  }
};

  handleAddAnotherChapter = () => {
    this.setState({
      data: {
        ...this.state.data,
        chapters: [...this.state.data.chapters, { newChapterImage: {}, startTime: "", endTime: "" }]
      }
    })
  }


  removeChapters = (id : number) => {
    const {chapters} = this.state.data
    const filterChapters = chapters.filter((item : any  , index:any) => index !== id)
    this.setState({data : {...this.state.data, chapters : filterChapters}})
  }


  handleFileChapter = (event: React.ChangeEvent<HTMLInputElement>,index: number | null = null) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      this.handleImageFile(files[0], index)
    }
  }


  handleImageFile(file : File , index : number | null){
    if (file) {
      this.setState(prevState => ({
        data: { ...prevState.data, coverImageFile: file }
      }));
    
      const img = new Image();
      img.onload = () => {
        const isValidSize = img.width === 3000 && img.height === 3000;
        const errorMessage = isValidSize ? "" : "Photo is not 3000x3000, please upload a new photo of that size to move forward";
        this.setState(prevState => {
          const updatedData = { ...prevState.data };
          if (index === null) {
            updatedData.invalidFileMessage = errorMessage;
            if (isValidSize) {
              updatedData.imageUrl = img.src;
            }
          } else {
            updatedData.invalidChapterMessage = [...(prevState.data.invalidChapterMessage || [])];
            updatedData.invalidChapterMessage[index] = errorMessage;
            if (isValidSize) {
              updatedData.additionalImages = [...(prevState.data.additionalImages || [])];
              updatedData.additionalImages[index]= img.src;
              updatedData.chapters = prevState.data.chapters.map((chapter, i) =>
                i === index ? { ...chapter, newChapterImage: file } : chapter
              );
            }
          }
          return { data: updatedData };
        });
      };
      img.src = URL.createObjectURL(file);
    }
  }

  handleRemoveChapterImg = (index : number) => {
    this.setState(prevState => {
      const updatedData = { ...prevState.data };
      if (updatedData.additionalImages) {
        updatedData.additionalImages = updatedData.additionalImages.filter((_, i) => i !== index);
      }
      updatedData.chapters = updatedData.chapters.map((chapter, i) =>
        i === index ? { ...chapter, newChapterImage: null } : chapter
      );
      if (updatedData.invalidChapterMessage) {
        updatedData.invalidChapterMessage = updatedData.invalidChapterMessage.filter((_, i) => i !== index);
      }
      return { data: updatedData };
    });
  }

  isImageValid = () => {
    return this.state.data.imageUrl !== "" && this.state.data.invalidFileMessage === "";
  }


  getPodcastEpisodes = async () => {
    let token = await getStorageData("token");
    let episode_id = await getStorageData("episode_id")
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editEpisodesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editEpisodeEndPoint}/${JSON.parse(episode_id)}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editEpisodeAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  resolveConditionForImage(){
    return (this.state.data.imageUrl && this.state.data.coverImageFile) ? true : false
  }


  handleUploadChapters = async() => {
    let token = await getStorageData("token", true);
    const header = {
      token: token,
    };
    let podcastId = await getStorageData("podcast_id");
    const episode_id = await getStorageData("episode_id")
    const {episodeTitle , episodeShowNotes , chapters , additionalPhotos , isPremiumEpisode ,isEpisodeAdultOnly , isEpisodeContainExplicit , coverImageFile , episodeNumber } = this.state.data
      let formdata = new FormData();
      formdata.append("podcast_id", podcastId);
      formdata.append("episode[name]", episodeTitle|| "");
      formdata.append("episode[description]", episodeShowNotes || "");
      formdata.append("episode[episode_number]", episodeNumber.toString() || "");
      formdata.append("episode[description]", episodeShowNotes || "");
      formdata.append("episode[adult]",isEpisodeAdultOnly.toString() );
      formdata.append("episode[premium]", isPremiumEpisode.toString());
      formdata.append("episode[explicit_content]", isEpisodeContainExplicit.toString());
    if (additionalPhotos){
    {chapters.forEach((item : Chapter , index : number) => {
      formdata.append(`episode[chapters_attributes][${index}][start_time]`, item?.startTime);
    })}
    {chapters.forEach((item : Chapter , index : number) => {
      formdata.append(`episode[chapters_attributes][${index}][end_time]`, item?.endTime);
    })}
    {chapters.forEach((item : any , index : number) => {
      formdata.append(`episode[chapters_attributes][${index}][image]`, item.newChapterImage);
    })}
    }
    if(this.resolveConditionForImage() === true) {
      formdata.append("episode[cover]", coverImageFile);
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadChapterEditApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editNewChapters}/${JSON.parse(episode_id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUpdateEpisodeAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};
  
    handleCloseFirstDialog = () => {
      this.setState({ openSuccess: false });
    };


  handlePlayPause = () => {
    if (this.audioRefs.current) {
      if (this.state.isPlaying) {
        this.audioRefs.current.pause();
      } else {
        this.audioRefs.current.play();
      }
      this.setState(prevState => ({ isPlaying: !prevState.isPlaying }));
    }
  }

    handleTimeUpdate = () => {
      if (this.audioRefs.current) {
        this.setState({
          currentTime: this.audioRefs.current.currentTime,
        });
      }
    };
  
    handleLoadedMetadata = () => {
      if (this.audioRefs.current) {
        this.setState({
          duration: this.audioRefs.current.duration,
        });
      }
    };

   
  handleVolumeChange = (_event: Event, value: number | number[]) => {
    const volume = value as number;
    if (this.audioRefs.current) {
      this.audioRefs.current.volume = volume;
      this.setState({ volume });
    }
  };

  handleSliderChange = (_event: Event, value: number | number[]) => {
    const time = value as number;
    if (this.audioRefs.current) {
      this.audioRefs.current.currentTime = time;
      this.setState({ currentTime: time });
    }
  };

  handleForward = () => {
    const audio = this.audioRefs.current; 
    if (audio) {
      const newTime = Math.min(this.state.duration, audio.currentTime + 10);
      audio.currentTime = newTime
    }
  }

  handleRewind = () => {
    const audio = this.audioRefs.current; 
    if (audio) {
      const newTime = Math.max(0, audio.currentTime - 10);
      audio.currentTime = newTime;
    }
  }
  // Customizable Area End
}
