// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

interface Host {
  first_name: string;
  last_name: string;
  email_address: string;
  location: string;
}


export interface S {
  hosts: Host[];
  countryDropdown: Array<string>,
}

export interface SS {
  id: any;
}
interface PodcastThirdScreenFormValues {
  hosts: {
    first_name: string,
    last_name: string,
    email_address: string,
    host_location: string
  }[];
}
export default class SetUpProfileThirdScreenControllerNoRss extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollReff: any = createRef();
  countryApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = { 
      hosts: [{ first_name: '', last_name: '', email_address: '', location: '' }],
      countryDropdown: [],
    };
  }

  async receive(from: string, message: Message) {
    this.handleResForCountryApi(from, message);
  }

  handleSubmitThirdScreen = async (values: PodcastThirdScreenFormValues) => {
    let storageData = await getStorageData('noRssData');
    let noRssData = storageData ? JSON.parse(storageData) : {};
    noRssData = {...noRssData, ...values};
    setStorageData("noRssData", JSON.stringify(noRssData));
    setStorageData("feedName", 'noRss');
    this.props.handleCommonPath("ChooseTemplate");
  }

  countryApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForCountryApi = async (from: string, message: Message) => {
    if (
      this.countryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ countryDropdown: responseJson })
      }
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.countryApi();
    setTimeout(() => {
      if (this.scrollReff.current) {
        this.scrollReff.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
}
// Customizable Area End