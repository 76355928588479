import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { activeHost, becomeHostActive, nonActiveBecomeHost, userProfile } from "./assets";
import { Linking } from "react-native";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface UserResponse {
  attributes: {
    first_name: string,
    last_name: string,
    bio: string,
    user_location: string,
    profile_picture: string
  }
}

interface SourceData {
  data: {
      playable_type: string, 
      playable_id: string,
      run_time: string,
      song_id: string
  }
}

interface AddAudioPlayer {
  data: {
    playable_type: string,
    playable_id: string,
    run_time: number,
    song_id: string
  }
}

export interface ApiCallInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: AddAudioPlayer
}
export interface VaildResponse {
  data: {
    attributes: {
      playable: {
        data: {
          attributes: {
            episode_content: string
          }
        }
      }
    }
  }
}

export interface VaildLastPlayResponse {
  data: [{
    id: string,
    attributes: {
      playable: {
        data: {
          id: string,
          type: string,
          attributes: {
            episode_content: string,
            name: string,
            status: string,
            cover: string,
            description: string,
            episode_number: string,
            adult: string,
            podcast_season: string,
            premium: string,
            episode_type: string,
            expiry_date: string,
            explicit_content: string,
            publish_date: string,
            episode_time: string,
            liked_episode: {
              liked: boolean,
              liked_id: string,
              count: string
            },
            account: {
              first_name: string;
              last_name: string;
              bio: string;
            }

          }
        }
      }
    }
  }]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  openModal?: any;
  newLogo?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isHost : boolean;
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  searchValue: string
  activeIcon: string,
  isMenuOpen: boolean,
  activeSidebarIcon: string,
  userStatus: string
  activeHostSidebarIcon: string,
  openDropdown: boolean,
  isClicked: boolean,
  userResponse: UserResponse,
  settingsSideBarActiveIcon: string;
  isModalOpen: boolean;
  isShowInMeddile: string;
  isShowInNav: string;
  playSource: SourceData;
  isShowAudio: boolean;
  isShowEditHostLogoModal: boolean;
  audioTracks: {
    podcast_id: string,
    title: string,
    artist: string,
    src: string,
    episode_number: string,
    liked_count: string,
    liked: boolean,
    cover: string,
    liked_id: string,
    episode_id: string,
    bio: string
  };
  isPlaying: boolean
  channelData: any;
  activeScreen : string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  userDetailsApiCallId: string = "";
  podcastCategoryApiCallId: string = "";
  podcastSubCategoryApiCallId: string = "";
  addAudioInPlayListApiCallId: string = "";
  lastAudioPlayApiCallId: string = "";
  getChannelDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isHost: false,
      webDrawer: false,
      token: "",
      drawerItems: [],
      searchValue: "",
      activeIcon: '',
      isMenuOpen: false,
      activeSidebarIcon: "",
      userStatus: "",
      activeHostSidebarIcon: "",
      openDropdown: false,
      isClicked: false,
      userResponse: {
        attributes: {
          first_name: "",
          last_name: "",
          bio: "",
          user_location: "",
          profile_picture: ""
        }
      },
      settingsSideBarActiveIcon: "",
      isModalOpen: false,
      isShowInMeddile: "Dashboard",
      isShowInNav: "Dashboard",
      playSource: {data:{
        playable_type: "",
        playable_id: "",
        run_time: "",
        song_id: ""
      }},
      isShowAudio: true,
      isShowEditHostLogoModal: false,
      audioTracks: {
        podcast_id: "",
        title: "",
        artist: "",
        src: "",
        episode_number: "",
        liked_count: "",
        liked: true,
        cover: "",
        liked_id: "",
        episode_id: "",
        bio: "",
      },
      isPlaying: false,
      channelData: [],
      activeScreen: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.handleResForuserSettingsApi(from, message);
    this.handleResForChannelData(from, message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    }
    if (this.isValidResponse(responseJson)) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  userDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForuserSettingsApi = async (from: string, message: Message) => {
    if (this.userDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson.data) {
        console.log("responseData.data", responseJson.data)
        this.setState({ userResponse: responseJson.data });
      }
    }
  };

  handleResForChannelData = async (from: string, message: Message) => {
    if (this.getChannelDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Channel found!") {
        this.setState({ channelData: responseJson.podcast.data });
      }
    }
  };

  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.categoryApi();
    this.subCategoryApi();
    this.categoryApiTwo();
    this.subCategoryApiTwo();
    this.subCategoryApiThree();
    this.checkUser();
    this.userDetails();
    this.getChannelData();
    this.handleLastAudioPlay()
    let settingsSideBarActiveIcon = await getStorageData('activeSettings');
    this.setState({settingsSideBarActiveIcon})
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
  // Customizable Area Start

  checkUser = async () => {
    const currentRoles = await getStorageData('currentRoles', true);
    const isShowInMeddile = await getStorageData('isShowInMeddile');
    const isShowInNav = await getStorageData('isShowInNav');
    const isAudioPlayerFullScreen = await getStorageData("AudioPlayerFullScreen")
    this.setState({ activeSidebarIcon: "dashboard",isShowInMeddile, isShowInNav },()=>{
      if(isShowInMeddile == null){
        this.setState({ isShowInMeddile: "Dashboard", isShowInNav: "Dashboard" });
      }else if(isShowInNav == null){
        this.setState({ isShowInMeddile: isShowInMeddile, isShowInNav: "Dashboard"});
      } else if (isShowInNav == "Build" || isShowInNav == "HostLogin") {
        this.setState({ isShowAudio: false });
      }
    })
    if(currentRoles.includes('host'))
      {this.setState({
        isHost : true,        
      })
    }

    if (isAudioPlayerFullScreen && isAudioPlayerFullScreen !== "{}" && isAudioPlayerFullScreen !== "false") {
      this.setState({ 
        isShowInMeddile: "AudioPlayerFullScreen", 
        activeScreen: "AudioPlayerFullScreen"
      });
    } 
  };    

  setActiveSidebarIconAndRedirect = (path: string) => {
    handleNavigation(path, this.props)
  };

  setActiveIconAndRedirect = (path: string) => {    
    handleNavigation(path, this.props);    
  };

  handleSetPath = (path: string) => {
    this.setState({ isShowInMeddile: path, isMenuOpen: false }, async() => {
        await setStorageData("isShowInMeddile",path)
    })
  };

  handleNavPath = async (path: string) => {
    if (path == "Dashboard") {
      this.setState({ isShowInNav: path, isShowInMeddile: "Dashboard", isShowAudio: true });
      await setStorageData("isShowInMeddile", "Dashboard");
      await setStorageData("isShowAudio", false);
    } else if (path == "Build") {
      this.setState({ isShowInNav: path, isShowInMeddile: "Build", isShowAudio: false });
      await setStorageData("isShowInMeddile", "Build");
      await setStorageData("isShowAudio", false);
    } else {
      this.setState({ isShowInNav: path, isShowInMeddile: path, isShowAudio: false });
      await setStorageData("isShowInMeddile", path);
      await setStorageData("isShowAudio", false);
    }
    await setStorageData("isShowInNav", path);
  };

  setActiveHostSidebarIconAndRedirect = (iconName: string, path: string) => {
    this.setState(
      { activeIcon: iconName },
      () => {
        handleNavigation(path,this.props);
      }
    );
  };

  handleProfilePhotoClick = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  onLogoClick = () => {
      this.setState({
        activeIcon: 'resident',
        activeSidebarIcon: 'dashboard',
        isShowInMeddile: "Dashboard",
        isShowInNav: "Dashboard",
        isShowAudio: true
      });
  }
 
  handleSettingsActiveIcon = async(activeSetings: string) => {
    this.setState({ settingsSideBarActiveIcon: activeSetings, isShowInMeddile: activeSetings, isMenuOpen: !this.state.isMenuOpen });
    setStorageData("activeSettings", activeSetings);
    await setStorageData("isShowInMeddile",activeSetings);
  }

  handleLogOut = () => {
    localStorage.clear()
    this.props.navigation.navigate("Home")
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleIsOpenMenuItem = async(path: string) => {
    this.setState({ settingsSideBarActiveIcon: path, isShowInMeddile: path, isMenuOpen: false ,isShowInNav: "Dashboard"});
    await setStorageData("activeSettings", path);
    await setStorageData("isShowInMeddile", path);
    await setStorageData("isShowInNav", "Dashboard");
  }

  handleCommonPath = async (path: any) => {    
    this.setState({ isShowInMeddile: path });
    await setStorageData("isShowInMeddile", path)
  }


  isShowEditLogoBtnForHost = () => {
    return this.state.isHost && this.state.isShowInMeddile === 'MyChannel'
  }

  handleIsShowEditHostLogoModal = () => {
    this.setState({isShowEditHostLogoModal: !this.state.isShowEditHostLogoModal})
  }


  hanldeParamState = async () => {
    this.setState({ isHost: true })
  }

  handleBuild = (path: string) => {
    this.setState({ isShowInNav: path })
  }

  handlePodcastId = (id:string) => {
    setStorageData("podcastId",id);
    this.setState({isShowInMeddile : "Catalogue"})
  }

  handleCommonPathNav = async (path: any) => {
    handleNavigation(path,this.props);
  }

  apiCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;

    let token = await getStorageData("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: JSON.parse(token),
        'Content-Type': contentType,
      }))
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  isValidResponse = (responseJson: VaildResponse) => {
    return responseJson && (responseJson.data);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: VaildResponse & VaildLastPlayResponse) => {
    if (apiRequestCallId === this.addAudioInPlayListApiCallId) {
      this.handleAddAudioResponseSuccess(responseJson);
    }

    if(apiRequestCallId === this.lastAudioPlayApiCallId){
      this.handleLastAudioPlayResponseSuccess(responseJson);
    }
  };

  handleAddAudio = async (playable_type: string, playable_id: string) => {

    let body = {
      data: {
        playable_type: playable_type,
        playable_id: playable_id,
        run_time: 0,
        song_id: playable_id
      }
    }

    this.addAudioInPlayListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.audioPlayer,
      body: body
    })
  }

  handleAddAudioResponseSuccess = (responseJson: VaildResponse) => {
    if (responseJson.data) {
      this.handleLastAudioPlay();
      this.setState({isPlaying:true})
    }
  }

  handleLastAudioPlay = async () => {
    this.lastAudioPlayApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.lastPlayed
    })
  }

  handleLastAudioPlayResponseSuccess = (responseJson: VaildLastPlayResponse) => {

    let data = {
      "episodes": responseJson.data[0].attributes.playable.data.attributes,
  }
  
  let tracks = {
      podcast_id: responseJson.data[0].attributes.playable.data.id,
      title: data.episodes.name,
      artist: `${data.episodes.account.first_name} ${data.episodes.account.last_name}`,
      src: data.episodes.episode_content,
      episode_number: data.episodes.episode_number,
      liked_count: data.episodes.liked_episode.count,
      liked: data.episodes.liked_episode.liked,
      cover: data.episodes.cover,
      liked_id: data.episodes.liked_episode.liked_id,
      episode_id: responseJson.data[0].id,
      bio: data.episodes.account.bio
    };

    this.setState({ audioTracks: tracks });
  }

  categoryApi = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  subCategoryApi = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getChannelData = async () => {

    this.getChannelDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getChannelDataEndPoint
    })
    
  }

  reRenderTheLogo = () => {
    this.getChannelData();
  }

  categoryApiTwo = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.podcastCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  subCategoryApiTwo = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  subCategoryApiThree = async () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.loginApiContentType,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFullScreenClick = async() => {
    this.setState({ 
      isShowInMeddile: "AudioPlayerFullScreen", 
      activeScreen: "AudioPlayerFullScreen"
    });
    await setStorageData("AudioPlayerFullScreen", "true")
  }

  exitFullScreen = async () => {
    await setStorageData("AudioPlayerFullScreen", "false")
  
    this.setState({
      activeScreen: "",
    });
    this.checkUser()
  };
  // Customizable Area End
}
